import React from 'react';
import Header from './Header';
import PariseduSection from './PariseduSection';
import Footer from './Footer';

function Parisedutour() {
  return (
    <>
        <Header/>
        <PariseduSection/>
        <Footer/>
    </>
  )
}

export default Parisedutour