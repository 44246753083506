import React from "react";
import Header from "../../../Header";
import BannerSection from "../../../BannerSection";
import Footer from "../../../Footer";
import image01 from "../../../assets/IMG_8587.JPG";
import image02 from "../../../assets/IMG_9747 (2).JPG";
import image03 from "../../../assets/IMG_8425.JPG";
import image04 from "../../../assets/IMG_8863.JPG";
import image05 from "../../../assets/IMG_9184.JPG";
import image06 from "../../../assets/IMG_8437.JPG";

const Dubai = () => {
  return (
    <>
      <Header />
      <BannerSection pageHeader="Our Dubai Tour" />

      <div className="container">
        <h1
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontFamily: "-moz-initial",
          }}
        >
          Abu Dhabi, Dubai
        </h1>
        <div className="row">
          <div className="col-md-9">
            <div className="container main">
              <div className="col-md-12 ms-5 mainImg">
                <div className="row">
                  <img
                    src={image01}
                    alt="Paris Tour"
                    style={{ width: "100%" }}
                  />
                  <h3 className="mt-3" style={{ textAlign: "center" }}>
                    Welcome to the Learning Adventure of a Lifetime!
                  </h3>
                  <p className="mt-3">
                    Embark on a journey of discovery with our Educational Tour
                    to Dubai. Immerse yourself in a unique blend of culture,
                    innovation, and history, as we explore the vibrant city of
                    Dubai. Our carefully curated educational experiences promise
                    to inspire and enlighten, providing students with an
                    unparalleled opportunity to learn and grow.
                    <br></br>
                    <br></br>
                    At Memories Maker, we believe that education extends beyond
                    the classroom. Our mission is to create transformative
                    learning experiences that broaden students' perspectives and
                    ignite their curiosity. With years of expertise in
                    organizing educational tours, we craft unforgettable
                    itineraries that combine academic enrichment with the
                    excitement of exploring one of the world's most dynamic
                    cities – Dubai.
                    <br></br>
                    <br></br>
                    <b>Destination Highlights:</b>
                    <br></br>
                    <br></br>
                    <b>1. Modern Marvels:</b>
                    <br></br>
                    a. Guided tour of the Burj Khalifa, the world's tallest
                    building<br></br>
                    b. Visit to the Dubai Mall and explore its innovative
                    attractions
                    <br></br>
                    <br></br>
                    <b>2. Business and Innovation:</b>
                    <br></br>
                    a. Educational session on Dubai's economic landscape
                    <br></br>
                    b. Interactive workshop on entrepreneurship and innovation
                    <br></br>
                    <br></br>
                    <b>3. Cultural Exchange:</b>
                    <br></br>
                    a. Visit to the Sheikh Zayed Grand Mosque<br></br>
                    b. Cultural exchange program with local students
                    <br></br>
                    <br></br>
                    <b>4. Desert Adventure:</b>
                    <br></br>
                    a. Desert safari experience with a focus on environmental
                    conservation<br></br>
                    b. Bedouin-style dinner under the stars
                    <br></br>
                    <br></br>
                    <b>Why Dubai ?</b>
                    <br></br>
                    <br></br>
                    <b>1. A Global Hub:</b> Dubai serves as a global hub for
                    business, innovation, and cultural exchange, providing
                    students with a unique learning environment.
                    <br></br>
                    <br></br>
                    <b>2. Cultural Diversity:</b> Explore the rich tapestry of
                    cultures that coexist in Dubai, offering students a global
                    perspective.
                    <br></br>
                    <br></br>
                    <b>3. Future-Focused:</b> Discover Dubai's commitment to
                    sustainable development, technological innovation, and the
                    future of smart cities.
                    <br></br>
                    <br></br>
                    Don't miss this incredible opportunity to combine education
                    with exploration in the captivating city of Dubai. Join us
                    on a journey that transcends textbooks and transforms
                    learning into a lifelong adventure!
                    <br></br>
                    <br></br>
                  </p>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="container">
              <div className="sidebarItem">
                <div className="sideImg">
                  <img src={image02} />
                </div>
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Where </span>
                    <span className="liteText">&nbsp;Was Our Trip</span>
                  </div>
                </div>
                <img
                  src="images/educational-tours/international/Dubai_tour/dubai.png"
                  style={{ width: "60%", marginLeft: "75px" }}
                />
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    fontFamily: "monospace",
                  }}
                >
                  Abu Dhabi, Dubai
                </p>
              </div>
              <div className="sidebarItem">
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Instagram</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzvyX26S89u/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic1.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzuzrFJSKuU/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic2.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzyP57kSsVq/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic3.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/Czuw0EkyN4-/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic4.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Experience</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <img
                      src={image03}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src={image04}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src={image05}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src={image06}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Dubai;
