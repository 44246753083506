import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";
import image01 from "../../../assets/01.jpg";
import image02 from "../../../assets/02.jpg";
import image03 from "../../../assets/03.jpg";
import image04 from "../../../assets/04.jpg";
import image05 from "../../../assets/05.webp";
import image06 from "../../../assets/06.jpg";
import image07 from "../../../assets/07.jpg";
import image08 from "../../../assets/08.jpg";
import image09 from "../../../assets/09.jpg";
import image10 from "../../../assets/10.jpg";

const Manali = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner_manali">
          <div className="headerWrapper">
            <div className="bannerBlogHeader_goa">
              <span>Manali Blog</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">08 Aug. 2024</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/Manali/manali_main.jpg"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                Exploring Manali: A Memorable Excursion for Students
              </h1>
              <p>
                Memories Maker recently hosted a group of eager students to
                Manali, the enchanting hill station nestled in the lap of the
                Himalayas on an unforgettable excursion. From spiritual sojourns
                to thrilling adventures, their journey through Manali and its
                neighbouring regions was filled with diverse experiences that
                left a lasting impression. Here’s a detailed account of their
                exciting adventure.
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>Hidimba Temple: A Sacred Start</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img src={image01} alt="Image" style={{ width: "100%" }} />
                  </div>
                  <div className="col-md-8 paraBlog">
                    The excursion began with a visit to the historic Hidimba
                    Temple, a revered site dedicated to the goddess Hidimba from
                    the Mahabharata. Surrounded by the dense, ancient deodar
                    forests, the temple’s unique pagoda-style architecture and
                    serene environment provided the students with a fascinating
                    glimpse into the region’s rich cultural and spiritual
                    heritage. The peaceful atmosphere allowed them to reflect
                    and absorb the temple’s historical significance.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Mall Road: The Hub of Activity</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-5">
                    Next, the students ventured to Mall Road, the vibrant heart
                    of Manali. Here, they immersed themselves in the local
                    culture, browsing through shops filled with traditional
                    handicrafts, jewellery, and souvenirs. The lively streets
                    offered a range of dining options where they sampled local
                    cuisine, making the visit to Mall Road a delightful blend of
                    shopping, dining, and experiencing the local vibe.
                  </div>
                  <div className="col-md-4">
                    <img
                      src={image02}
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Van Vihar: A Nature Retreat</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4">
                    <img
                      src={image03}
                      alt="dharamshalaImage"
                      style={{ width: "100%", height: "70%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Van Vihar, a tranquil park located in the centre of Manali,
                    provided a refreshing break from the bustling town. With its
                    lush green landscapes, serene lake, and well-maintained
                    walking paths, the park offered a peaceful setting for
                    leisurely strolls and boat rides. The students enjoyed the
                    natural beauty and took time to relax and rejuvenate amidst
                    the serene surroundings.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Monastery Visit: A Cultural Exploration</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    The excursion continued with a visit to a local monastery,
                    where the students were introduced to the calming practices
                    of Tibetan Buddhism. The monastery’s colourful murals,
                    intricate statues, and the rhythmic chanting of monks
                    created an immersive cultural experience. The visit provided
                    insights into the spiritual traditions and cultural richness
                    of the region.
                  </div>
                  <div className="col-md-4">
                    <img
                      src={image04}
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Atal Tunnel: Engineering Marvel</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src={image05}
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    The journey then took the students through the Atal Tunnel,
                    an engineering marvel that connects Manali to the
                    Lahaul-Spiti Valley. As they traversed the tunnel, they
                    marvelled at the impressive infrastructure and the
                    significance of this feat of engineering in improving
                    connectivity and fostering regional development.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Sissu and Khoksar: Himalayan Beauty</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    The scenic drive to Sissu and Khoksar showcased the raw
                    beauty of the Himalayan landscape. In Sissu, the students
                    admired the stunning waterfall and the lush green
                    surroundings, while Khoksar offered panoramic views and
                    rugged terrain that highlighted the majestic grandeur of the
                    high-altitude region.
                  </div>
                  <div className="col-md-4">
                    <img
                      src={image06}
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Zip Line Adventure: Adrenaline Rush</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src={image07}
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Adding an element of thrill to their journey, the students
                    participated in a zip line activity. Soaring above the lush
                    valleys, they experienced an exhilarating rush of adrenaline
                    and enjoyed breath taking views from a unique vantage point.
                    The zip line adventure was a thrilling highlight, offering
                    an unforgettable experience of flying through the scenic
                    landscape.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>
                        Vaishno Mata Devi Temple: A Spiritual Excursion in Kullu
                      </u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    The trip also included a visit to the Vaishno Mata Devi
                    Temple in Kullu. This revered temple, dedicated to the
                    goddess Vaishno Mata, provided the students with an
                    opportunity to witness the spiritual fervour and devotion of
                    the local devotees. The serene ambiance and spiritual
                    significance of the temple added a meaningful dimension to
                    their excursion.
                  </div>
                  <div className="col-md-4">
                    <img
                      src={image08}
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Shawl Factory Visit: Craftsmanship and Tradition</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src={image09}
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Concluding their journey, the students visited a local shawl
                    factory, where they learned about the intricate process of
                    shawl weaving. Observing the craftsmanship and traditional
                    techniques used in creating these beautiful garments offered
                    them a deeper appreciation for the region’s textile heritage
                    and the skill involved in producing high-quality woollen
                    products.
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <h3 className="dharamBlogHead">
                    <u>
                      <strong>Reflections on a Remarkable Journey</strong>
                    </u>
                  </h3>
                  <br></br>
                  <br></br>
                  <img
                    src={image10}
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <br></br>
                  <br></br>
                  <p>
                    The excursion to Manali was more than just a sightseeing
                    trip; it was an enriching experience that combined spiritual
                    exploration, cultural immersion, natural beauty, and
                    adventure. The students returned with a deeper appreciation
                    for the diverse landscapes and cultures of India, along with
                    cherished memories of their Himalayan adventure.
                    <br></br>
                    <br></br>
                    This journey not only provided them with fun and excitement
                    but also offered valuable lessons in history, culture, and
                    the importance of connecting with nature. The excursion to
                    Manali was truly a celebration of exploration, learning, and
                    the joy of discovery.
                  </p>
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Manali;
