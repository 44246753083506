import React, { useState } from "react";
import Header from "../../Header";
import BannerSection from "../../BannerSection";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";
import { Link } from "react-router-dom";
import Footer from "../../Footer";

const Blog = () => {
  const blogs = [
    {
      src: "images/places/blog-paris.webp",
      blogHead: "All the way to Paris Live Virtually!",
      blogContent:
        "Ever wondered about the possibilities of travelling in 2020? Well, nobody did! But what nobody imagined, Memories Maker have done it When most of us were affected by the Global Pandemic...",
      date: "20 Oct. 2021",
      url: "/blog_1",
    },
    {
      src: "images/places/travel.jpg",
      blogHead: "Eduvacation",
      blogContent:
        "Nothing teaches you more than exploring the world and accumulating experiences. Travelling plays an important role in our education to make it balanced and innovative at the same time...",
      date: "01 Sept. 2022",
      url: "/blog_2",
    },
    {
      src: "images/places/dharamshala/McLeod_Ganj.jpg",
      blogHead: "Dharamshala and McLeod Ganj",
      blogContent:
        "Nestled in the majestic Dhauladhar range of the Himalayas, Dharamshala and McLeod Ganj are twin gems that captivate the hearts of travelers with their breathtaking landscapes, rich...",
      date: "01 Jul. 2023",
      url: "/blog/dharamshala",
    },
    {
      src: "images/places/goa/goa-main.jpg",
      blogHead: "Discovering Goa",
      blogContent:
        "Welcome to the paradise of Goa, India’s smallest but most vibrant state, nestled along the country’s western coastline Renowned for its pristine beaches,...",
      date: "25 Jul. 2023",
      url: "/blog/goa",
    },
    {
      src: "images/places/dubai/main-dubai.jpg",
      blogHead: "Exploring the Marvels of Dubai",
      blogContent:
        "Dubai, a city that rose from the sand of the Arabian Desert, has captivated the world with its grandeur, opulence, and ambition. Nestled along...",
      date: "28 Jul. 2023",
      url: "/blog/dubai",
    },
    {
      src: "images/places/ahmedabad/ahmedabad-main.jpg",
      blogHead: "Ahmedabad, the Architectural Arena",
      blogContent:
        "An educational tour is a vital aspect of a student’s academic journey, as it not only provides a much needed break from the routine but also opens up a world of knowledge and experiences....",
      date: "02 Aug. 2023",
      url: "/blog/ahmedabad",
    },
    {
      src: "images/places/banahills/bana-main.jpg",
      blogHead: "Ba Na Hills: Da Nang's Enchanted Escapade to the Sky",
      blogContent:
        "erched like a mystical haven atop the Truong Son Mountains, Ba Na Hills in Da Nang emerges as a captivating escape that transcends the ordinary...",
      date: "09 Nov. 2023",
      url: "/blog/banahills",
    },
    {
      src: "images/places/vietnam/viet_main.webp",
      blogHead:
        "Unveiling the Charms of Vietnam: A Trifecta of Wonders in Ho Chi Minh, Da Nang, and Ha Noi",
      blogContent:
        "Vietnam, a country that dances gracefully between tradition and modernity, boasts an array of enchanting destinations Among them, the cities of Ho Chi Minh, Da Nang, and Ha Noi...",
      date: "14 Nov. 2023",
      url: "/blog/vietnam",
    },
    {
      src: "images/places/halongbay/Ha-Long-Bay-main.webp",
      blogHead: "A Magical Escape: Ha Long Bay Cruises - A 2-Day Journey",
      blogContent:
        "Nestled in the Gulf of Tonkin, off the coast of Northern Vietnam, lies a UNESCO World Heritage Site that captivates the hearts of all who visit – Ha Long Bay. This natural wonder is renowned...",
      date: "20 Nov. 2023",
      url: "/blog/halongbay",
    },
    {
      src: "images/places/shimla/shimla-main.jpg",
      blogHead: "Discovering Shimla: India's Enchanting Hill Station",
      blogContent:
        "Nestled in the picturesque state of Himachal Pradesh, Shimla stands as a testament to India's colonial past and natural beauty. This charming hill station, once the summer capital of British India...",
      date: "28 Dec. 2023",
      url: "/blog/shimla",
    },
    {
      src: "images/places/Manali/manali_main.jpg",
      blogHead: "Exploring Manali: A Memorable Excursion for Students",
      blogContent:
        "Memories Maker recently hosted a group of eager students to Manali, the enchanting hill station nestled in the lap of the Himalayas on an unforgettable excursion. From spiritual sojourns to thrilling adventures...",
      date: "08 Aug. 2024",
      url: "/blog/manali",
    },
  ];

  return (
    <>
      <Header />
      <BannerSection pageHeader="OUR BLOGS" />

      <div className="container">
        <div className="row">
          {blogs.map((val, i) => (
            <div className="col-md-4" style={{ marginTop: "30px" }}>
              <div
                className="cardBlog cardBlog1"
                style={{ backgroundImage: `url(${val.src})` }}
              >
                <div
                  className="shareBtns pr-1 pl-1"
                  style={{ padding: "10px" }}
                >
                  <div>
                    <b className="pt-1 d-block">{val.date}</b>
                  </div>
                  <div>
                    <span className="copyIconBox">
                      <a className="copyIcon" href="javascript:void(0)">
                        <MdContentCopy />
                      </a>
                    </span>
                    <a
                      target="blank"
                      href="https://www.linkedin.com/sharing/share-offsite/?url=memoriesmakeredu.com/all-the-way-to-paris-live-virtually.php"
                    >
                      <FaLinkedinIn />
                    </a>

                    <a
                      target="_blank"
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmemoriesmakeredu.com/all-the-way-to-paris-live-virtually.php%2F&amp;src=sdkpreparse"
                      className="btn facebook fb-xfbml-parse-ignore"
                    >
                      <CgFacebook />
                    </a>

                    <a
                      target="_blank"
                      href="https://web.whatsapp.com/send?text=https://memoriesmakeredu.com/all-the-way-to-paris-live-virtually.php"
                      data-action="share/whatsapp/share"
                      className="btn whatsapp webWhatsapp"
                    >
                      <FaWhatsapp />
                    </a>
                  </div>
                </div>
                <div className="blogTxt">
                  <div className="blogHead">
                    <h3>{val.blogHead}</h3>
                  </div>
                  <div className="postCardDesc">{val.blogContent}</div>
                  <div className="readmorelink">
                    <Link to={val.url}>Read Full Blog</Link>
                    {/* <BsArrowRight className='rightIcon'/> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blog;
