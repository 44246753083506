import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const HalongBay = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner_halong">
          <div className="headerWrapper">
            <div className="bannerBlogHeader_goa">
              <span>Ha Long Bay Cruises</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">20 Nov. 2023</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/halongbay/Ha-Long-Bay-main.webp"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                A Magical Escape: Ha Long Bay Cruises - A 2-Day Journey
              </h1>
              <p>
                Nestled in the Gulf of Tonkin, off the coast of Northern
                Vietnam, lies a UNESCO World Heritage Site that captivates the
                hearts of all who visit – Ha Long Bay. This natural wonder is
                renowned for its emerald waters and thousands of limestone
                karsts that seem to emerge from the depths like mythical
                dragons. Embarking on a 2-day cruise in Ha Long Bay promises an
                enchanting experience, where every moment unveils the beauty and
                mystique of this breath taking destination.
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>Day 1: Embarking on the Adventure</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/halongbay/01.jpg"
                      alt="Image"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    As the sun rises over Ha Long Bay, embark on your cruise
                    adventure from the bustling port. Greeted by the friendly
                    crew, settle into your cosy cabin and let the soothing sound
                    of the waves set the tone for your journey. Before setting
                    sail, take a moment to admire the panoramic view of the bay
                    from the ship's deck.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Afternoon:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-5">
                    The first stop on your cruise might be the awe-inspiring
                    Sung Sot Cave, also known as the Surprise Cave. Explore the
                    intricate chambers and marvel at the stunning stalactite
                    formations that make this cave a natural wonder. Afterward,
                    enjoy a delectable lunch onboard, featuring a variety of
                    fresh seafood and Vietnamese delicacies.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/halongbay/02.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Cruise Activities:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/halongbay/03.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    The afternoon is perfect for kayaking or taking a
                    traditional bamboo boat ride to get up close and personal
                    with the towering limestone karsts. Paddle through hidden
                    lagoons and marvel at the tranquillity that envelopes you.
                    Alternatively, you can choose to relax on the sundeck,
                    basking in the sun and taking in the stunning scenery.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Evening:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    As the day winds down, savour a mouth-watering dinner on
                    deck, with the bay as your backdrop. Enjoy the night squid
                    fishing or simply stargaze from the comfort of your cruise
                    ship. Retire to your cabin, falling asleep to the gentle
                    rocking of the boat and the tranquil sounds of the bay.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/halongbay/04.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Day 2: Sunrise and Explorations</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/halongbay/05.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    <b>Morning:</b>
                    <br></br>
                    <br></br>
                    Wake up early to catch the mesmerizing sunrise over Ha Long
                    Bay. The soft hues of pink and orange cast a magical glow on
                    the karsts, creating a picturesque scene that is sure to be
                    etched in your memory. After a hearty breakfast, the cruise
                    might take you to less-explored areas, offering a more
                    intimate experience of the bay.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Farewell with Brunch on the board:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    Enjoy the scrumptious brunch after coming back to the cruise
                    with a variety of food to fulfil your desires amidst water
                    bay. As your 2-day Ha Long Bay cruise comes to an end, bid
                    farewell to the crew and the enchanting landscape. Disembark
                    with a heart full of memories and a camera full of stunning
                    snapshots that will serve as a lasting reminder of this
                    extraordinary journey.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/halongbay/06.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <img
                    src="/images/places/halongbay/main_last.jpg"
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <br></br>
                  <br></br>
                  <p>
                    Ha Long Bay, with its ethereal beauty and captivating charm,
                    offers a truly unique cruise experience. A 2-day adventure
                    allows you to immerse yourself in the magic of the bay,
                    explore hidden gems, and create memories that will last a
                    lifetime. Whether you're an adventure seeker, a nature
                    enthusiast, or simply in search of tranquillity, a Ha Long
                    Bay cruise is an unmissable experience that will leave you
                    longing to return to its mystical embrace.
                  </p>
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default HalongBay;
