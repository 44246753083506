import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const VitenamBlog = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner_viet">
          <div className="headerWrapper">
            <div className="bannerBlogHeader_goa">
              <span>Vietnam Blog</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">14 Nov. 2023</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/vietnam/viet_main.webp"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                Unveiling the Charms of Vietnam: A Trifecta of Wonders in Ho Chi
                Minh, Da Nang, and Ha Noi
              </h1>
              <p>
                Vietnam, a country that dances gracefully between tradition and
                modernity, boasts an array of enchanting destinations. Among
                them, the cities of Ho Chi Minh, Da Nang, and Ha Noi stand out
                as vibrant hubs that showcase Vietnam's unique blend of history,
                culture, and contemporary life. Let's embark on a journey to
                explore the distinct charms of these three captivating cities.
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>Ho Chi Minh City: A Dynamic Metropolis</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/vietnam/Ho-Chi-Minh-City.jpg"
                      alt="Image"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Known as the economic powerhouse of Vietnam, Ho Chi Minh
                    City, formerly Saigon, is a dynamic metropolis that
                    seamlessly fuses the old and the new. Skyscrapers rise
                    alongside French colonial architecture, and bustling markets
                    buzz with energy. The War Remnants Museum provides a
                    poignant insight into Vietnam's tumultuous past, while the
                    Notre-Dame Cathedral Basilica of Saigon and the historic Ben
                    Thanh Market add a touch of charm to the city's landscape.
                    Culinary adventures await at street-side stalls, offering a
                    taste of authentic Vietnamese flavors amidst the lively
                    atmosphere.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Da Nang: Where Nature and Urbanity Coalesce</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-5">
                    Nestled along the central coastline, Da Nang is a city that
                    effortlessly blends natural beauty with urban vitality. The
                    Marble Mountains, a cluster of limestone hills with caves
                    and pagodas, offer panoramic views of the city and the
                    coastline. My Khe Beach, with its golden sands, invites
                    relaxation and water sports enthusiasts. Da Nang is also a
                    gateway to the ancient town of Hoi An and the imperial city
                    of Hue, making it a strategic stop for those seeking to
                    explore Vietnam's historical treasures.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/vietnam/da_nang.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Ha Noi: Vietnam's Timeless Capital</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/vietnam/hanoi.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    As the capital of Vietnam, Ha Noi stands as a testament to
                    the country's rich history and cultural heritage. The Old
                    Quarter, a maze of narrow streets, exudes charm with its
                    ancient temples, traditional shops, and vibrant street life.
                    The Ho Chi Minh Mausoleum and the One Pillar Pagoda are
                    iconic landmarks that narrate Vietnam's history. Tranquil
                    lakes such as Hoan Kiem offer a respite from the urban
                    hustle, while water puppetry performances bring folklore to
                    life. Ha Noi's street food scene is legendary, offering an
                    array of delectable treats that showcase the city's culinary
                    prowess.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Exploring the Connections</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    Despite their unique attributes, these three cities are
                    connected by a shared thread of warmth and hospitality.
                    Whether it's the chaotic energy of Ho Chi Minh, the coastal
                    allure of Da Nang, or the timeless grace of Ha Noi, each
                    city contributes to the kaleidoscope that is Vietnam.
                    Exploring these destinations provides a nuanced
                    understanding of the country's past, present, and future,
                    offering travelers a multifaceted experience that lingers
                    long after the journey concludes.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/vietnam/3_cities.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <img
                    src="/images/places/vietnam/main_last.jpg"
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <br></br>
                  <br></br>
                  <p>
                    Ho Chi Minh, Da Nang, and Ha Noi — three cities that
                    encapsulate the essence of Vietnam. From the historical
                    narratives etched in the streets of Ho Chi Minh to the
                    natural splendor of Da Nang and the timeless charm of Ha
                    Noi, each city is a chapter in Vietnam's captivating story.
                    Embarking on a journey through these urban landscapes is not
                    merely a tour; it's an immersion into the heart and soul of
                    a nation that continues to captivate the world with its
                    unique allure.
                  </p>
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default VitenamBlog;
