import React from 'react';
import Header from './Header';
import Footer from './Footer';
import BannerSection from './BannerSection';
import ContactSection from './ContactSection';

function Contact() {
  return (
    <>
      <Header/>
      <BannerSection pageHeader="Contact Us" />
      <ContactSection/>      
      <Footer/>
    </>
  )
}

export default Contact;