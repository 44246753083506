import React from "react";
import OwlCarousel from "react-owl-carousel";
import SectionHeader from "../SectionHeader";
import img01 from "../assets/Insta_pics/01.jpg";
import img02 from "../assets/Insta_pics/02.jpg";
import img03 from "../assets/Insta_pics/03.jpg";
import img04 from "../assets/Insta_pics/04.jpg";
import img05 from "../assets/Insta_pics/05.jpg";
import img06 from "../assets/Insta_pics/06.jpg";
import img07 from "../assets/Insta_pics/07.jpg";
import img08 from "../assets/Insta_pics/08.jpg";
import img09 from "../assets/Insta_pics/09.jpg";
import img010 from "../assets/Insta_pics/010.jpg";
import img011 from "../assets/Insta_pics/011.jpg";
import img012 from "../assets/Insta_pics/012.jpg";
import img013 from "../assets/Insta_pics/013.jpg";
import img014 from "../assets/Insta_pics/014.jpg";
import img015 from "../assets/Insta_pics/015.jpg";
import img016 from "../assets/Insta_pics/016.jpg";
import { Link } from "react-router-dom";

const gallerycarouselOptions = {
  margin: 10,
  nav: false,
  dots: false,
  loop: true,
  rtl: false,
  autoplay: true,
  slideTransition: 14000,
  animateIn: "linear",
  animateOut: "linear",
  autoplayTimeout: 5000,
  autoplaySpeed: 5000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    400: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    600: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    700: {
      items: 4,
      slideTransition: "linear",
      rtl: false,
    },
    1000: {
      items: 5,
      slideTransition: "linear",
      rtl: false,
    },
  },
};

const gallerycarouselOptions2 = {
  margin: 10,
  nav: false,
  dots: false,
  loop: true,
  rtl: false,
  autoplay: true,
  slideTransition: 14000,
  animateIn: "linear",
  animateOut: "linear",
  autoplayTimeout: 5000,
  autoplaySpeed: 5000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    400: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    600: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    700: {
      items: 4,
      slideTransition: "linear",
      rtl: false,
    },
    1000: {
      items: 5,
      slideTransition: "linear",
      rtl: true,
    },
  },
};

const InstaCarousel = () => {
  const firstCarousel = [
    {
      source: img01,
      title: "insta pic",
    },
    {
      source: img02,
      title: "The Bastille Day - La Fête Nationale",
    },
    {
      source: img03,
      title: "चलते चलते French",
    },
    {
      source: img04,
      title: "Eiffel Tower Comes Home",
    },
    {
      source: img05,
      title: "Dubai Comes Home",
    },
    {
      source: img06,
      title: "Paris Comes Home",
    },
    {
      source: img07,
      title: "Paris Comes Home",
    },
    {
      source: img08,
      title: "Paris Comes Home",
    },
  ];
  const secondCarousel = [
    {
      source: img09,
      title: "insta pic",
    },
    {
      source: img010,
      title: "The Bastille Day - La Fête Nationale",
    },
    {
      source: img011,
      title: "चलते चलते French",
    },
    {
      source: img012,
      title: "Eiffel Tower Comes Home",
    },
    {
      source: img013,
      title: "Dubai Comes Home",
    },
    {
      source: img014,
      title: "Paris Comes Home",
    },
    {
      source: img015,
      title: "Paris Comes Home",
    },
    {
      source: img016,
      title: "Paris Comes Home",
    },
  ];
  return (
    <>
      <div className="headerBox mt-5">
        <div className="innerBox">
          <span className="boldText">Insta-Live </span>
          <span className="liteText">&nbsp;Highlights</span>
        </div>
      </div>
      <div className="container-fluid">
        <OwlCarousel className="owl-theme" {...gallerycarouselOptions}>
          {firstCarousel.map((val, index) => (
            <div className="item">
              <div className="galleryCarouselBox">
                <div className="instaImgBox">
                  <Link
                    to={"//www.instagram.com/memoriesmaker2016"}
                    target="_blank"
                  >
                    <img
                      src={val.source}
                      style={{ cursor: "pointer" }}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
        <br></br>
        <OwlCarousel className="owl-theme" {...gallerycarouselOptions2}>
          {secondCarousel.map((val, index) => (
            <div className="item">
              <div className="galleryCarouselBox">
                <div className="instaImgBox">
                  <Link
                    to={"//www.instagram.com/memoriesmaker2016"}
                    target="_blank"
                  >
                    <img
                      src={val.source}
                      style={{ cursor: "pointer" }}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </>
  );
};

export default InstaCarousel;
