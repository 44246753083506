import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const Goa = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner_goa">
          <div className="headerWrapper">
            <div className="bannerBlogHeader_goa">
              <span>Discovering Goa</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">25 July 2023</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/goa/goa-main.jpg"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                A Paradise of Sun, Sand, Cuisine and Culture
              </h1>
              <p>
                Welcome to the paradise of Goa, India’s smallest but most
                vibrant state, nestled along the country’s western coastline.
                Renowned for its pristine beaches, diverse culture heritage, and
                an enthralling blend of Indian and Portuguese influences, Goa is
                a dream destination of travelers seeking a perfect blend of
                relaxation, adventure, and culture. This tiny state, known as
                the “Pearl of the Orient” has captured the hearts of travelers
                from around the world with its unique blend of colonial charm
                and contemporary allure. Join us as we embark on a virtual
                journey through its enchanting paradise!
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>The Beaches of Bliss:</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/goa/beach-bliss.jpg"
                      alt="Image"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Goa is synonymous with its palm-fringed beaches, where
                    golden sands meet the azure waters of the Arabian Sea. From
                    the lively and busting Baga Beach to the serene and secluded
                    Agonda Beach, each shore has its unique charm. Embrace the
                    laid-back vibes of Anjuna, indulge in water sports at
                    Calangute, or simply bask in the sun’s warmth at Palolem.
                    The Beaches of Goa cater to every traveler’s taste, offering
                    unforgettable experiences. When people dream of Goa, it's
                    the pristine beaches that often come to mind first. From the
                    most popular stretches to more secluded shores of Morjim,
                    Goa offers a beach experience for every kind of traveler.
                    With golden sands, turquoise waters, and swaying palm trees,
                    these beaches provide the perfect setting for breathtaking
                    sunsets.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>The Kaleidoscope of Culture:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-5">
                    Goa’s rich history is a tapestry woven with a colorful blend
                    of Indian and Portuguese influences. Formerly a Portuguese
                    colony, the state’s architecture, cuisine, and festivities
                    reflect this unique fusion. Explore the magnificent
                    churches, such as Basilica of Bom Jesus and Sé Cathedral,
                    showcasing exemplary Baroque architecture. Stroll through
                    the vibrant streets of Fontainhas, the Latin Quarter, with
                    its vibrant pastel-colored houses. Participate in the
                    vibrant local festivals like Carnival and Shimgo, where the
                    state comes alive with music, dance, and elaborate parades.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/goa/Kaleidoscope-goa.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "80%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Gastronomic Delights:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/goa/food.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Goan cuisine is a tantalizing fusion of flavors. No visit to
                    Goa is complete without savoring its delectable cuisine.
                    Influenced by its Portuguese heritage, the Goan culinary
                    journey is a treat for food enthusiasts, as it boasts a
                    delectable array of seafood, spices, and flavors. Indulge in
                    spicy and tangy vindaloo, relish the succulent Goan fish
                    curry, and savor the aromatic seafood balchão. Don’t forget
                    to try feni, a locally brewed cashew or coconut liquor, to
                    truly embrace the Goan spirit. The bustling local markets,
                    like the Anjuna Flea Market and Mapusa Market, offer an
                    excellent opportunity to savor these culinary delights while
                    exploring the region’s vibrant culture.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Adventure and Wildlife:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    Beyond the beaches and vibrant streets, Goa offers a world
                    of adventure and natural beauty. Explore the lush Western
                    Ghats, a UNESCO World Heritage Site, and trek through its
                    dense forests to discover hidden waterfalls and breathtaking
                    vistas. Wildlife enthusiasts will find solace in the Bhagwan
                    Mahavir Wldlife Sanctuary and Cotigao Wildlife Sanctuary,
                    home to diverse flora and fauna, including leopards,
                    elephants, and a variety of bird species.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/goa/wildlife.jpg"
                      alt="monastery"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Serenity in the South:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/goa/serenity.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    While the northern part of Goa bustles with activities and
                    party scenes, the southern region offers a tranquil escape
                    for those seeking solitude. Visit the Capo De Rama Fort,
                    perched atop a cliff, and enjoy a mesmerizing sunsets. Head
                    to the Butterfly Beach, accessible only by boat, and witness
                    the beauty of nature undisturbed. Palácio do Deão, a
                    colonial mansion, offers a glimpse into the past and
                    stunning views of the Kushavati River.
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <img
                    src="/images/places/goa/goa-beach.jpg"
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <br></br>
                  <br></br>
                  <p>
                    While Goa is widely known for its lively beach scene, there
                    are pockets of tranquility waiting to be discovered. Head
                    inland to explore the verdant spice plantations, where you
                    can take a guided tour, learn about the cultivation process,
                    and indulge in a soothing spice-infused massage. The
                    Dudhsagar Waterfalls, cascading gracefully through the dense
                    jungle, present an awe-inspiring sight, especially during
                    the monsoon season.
                    <br></br>
                    <br></br>
                    All in all, Goa is a place where time slows down, and
                    worries fade away. It’s a melting pot of cultures, where
                    East meets West and traditions blends seamlessly. Whether
                    you seek adventure, relaxation, culture exploration, or a
                    little bit of everything, Goa will leave you enchanted and
                    longing for more. Goa’s allure lies in its ability to cater
                    to all types of travelers – from sun-seekers and adventure
                    enthusiasts to history buffs and culture aficionados. Goa
                    promises to leave an indelible mark on your heart and soul.
                    So, pack your bags, embark on an unforgettable journey, and
                    let the charm of Goa sweep you off your feet with Memories
                    Maker. We have a fun-filled and thrilling Goa itinerary
                    waiting to tick your bucket list.
                  </p>
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Goa;
