import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import HomeBanner from "./Homepage/HomeBanner";
import Priorities from "./Homepage/Priorities";
import RecentTours from "./Homepage/RecentTours";
import EducationalTours from "./Homepage/EducationalTours";
import LiveTours from "./Homepage/LiveTours";
import GalleryCarousel from "./Homepage/GalleryCarousel";
import BlogsCarousel from "./Homepage/BlogsCarousel";
import Testimonials from "./Homepage/Testimonials";
import SchoolCollaborations from "./Homepage/SchoolCollaborations";
import InstaCarousel from "./Homepage/InstaCarousel";
function Homepage() {
  return (
    <>
      <Header />
      <HomeBanner />
      <Priorities />
      {/* <RecentTours/> */}
      <EducationalTours />
      {/* <LiveTours/> */}
      <GalleryCarousel />
      {/* <BlogsCarousel/> */}
      <Testimonials />
      <SchoolCollaborations />
      <InstaCarousel />
      <Footer />
    </>
  );
}

export default Homepage;
