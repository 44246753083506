import React from 'react';
import {Container, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import SectionHeader from './SectionHeader';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';

import {RiFacebookLine, RiTwitterFill, RiInstagramLine} from "react-icons/ri";

import {MdContentCopy} from "react-icons/md";
import {FaLinkedinIn, FaWhatsapp} from "react-icons/fa";
import {CgFacebook} from "react-icons/cg";
import OwlCarousel from 'react-owl-carousel';



const blogmobcarouselOptions = {
  margin: 10,
  responsiveClass: true,
  nav: false,
  dots: false,
  loop:true,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
          stagePadding:40
      },
      400: {
          items: 1,
          stagePadding:80
      },
      600: {
          items: 3,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
}

const blogs = [{head:'header', title:'title', img:'images/blogs/blog-paris.webp'},
{head:'header', title:'title', img:'images/blogs/blog-paris.webp'},
{head:'header', title:'title', img:'images/blogs/blog-paris.webp'},
{head:'header', title:'title', img:'images/blogs/blog-paris.webp'},
{head:'header', title:'title', img:'images/blogs/blog-paris.webp'}];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const actions = [
  { icon: <RiFacebookLine />, name: 'Facebook' },
  { icon: <RiTwitterFill />, name: 'Twitter' },
  { icon: <RiInstagramLine />, name: 'Instagram' },
];


function BlogSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Container maxWidth="xxl" style={{marginTop:'50px'}}>
      <Grid container>
        <Grid item xl={9}>
          <div className='blogHeaderBox'>
            <div className='mainHeader'>
              <span>All the way to Paris Live Virtually!</span>
            </div>
            <div className='subHeader'>
              <span>Students went LIVE to Paris with the help of a first-ever, one of a kind initiative by Memories Maker in India.</span>
            </div>
            <div style={{display:'flex', justifyContent:'space-between'}}>
              <div className='blogUploadedDate'>
                20 Oct. 2020
              </div>
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <Button sx={{borderRadius:'50%', height:'30px', width:'30px', minWidth:'auto'}}><MdContentCopy/></Button>
                <Button sx={{borderRadius:'50%', height:'30px', width:'30px', minWidth:'auto'}}><FaLinkedinIn/></Button>
                <Button sx={{borderRadius:'50%', height:'30px', width:'30px', minWidth:'auto'}}><FaWhatsapp/></Button>
                <Button sx={{borderRadius:'50%', height:'30px', width:'30px', minWidth:'auto'}}><CgFacebook/></Button>
              </div>
            </div>
          </div>

          <div className='blogImg'>
            <img src="images/blogs/blog-paris.webp" style={{width:'100%'}} alt="" />
          </div>

          <div className="blogContent">
              <p>
              Ever wondered about the possibilities of travelling in 2020? Well, nobody did! But what nobody imagined, Memories Maker have done it.<br/><br/>

              When most of us were affected by the Global Pandemic in 2020, there was a team that was continuously working towards making 2020 memorable for kids. Memories Maker had come up with an idea of organizing a three-day Live tour to Paris. The theme to which was PARIS COMES HOME - India's first Live Virtual Educational Tour to Paris. The scheduled event on 10th, 11th and 12th October had left the school students mesmerized with the beauty of Paris. Nitin Popli, the Founder added, "We believe 2020 is tough for everyone but even tougher for kids where they missed out on going to school, meeting friends, playing in the playground and most of all, their vacations. Be it domestic or international! Our kids have suffered the most. We kept the struggle and misses in our mind and designed a mind-boggling itinerary for our kids to get entertained with a sac full of knowledge."<br/><br/>

              The three-day event included Parc Astérix on the first day, Sacré-Cœur on the second day, and the Eiffel Tower on the last and the final day. The students were able to know about Park Asterix, stories about Asterix and Obelix, a glimpse of Halloween, Sacré-Cœur church, the recipe of mouth-watering macarons and crêpes, history of the river Seine, facts about the construction of the Eiffel Tower and much more. To test the knowledge of the students, Memories Maker also conducted a questionnaire at the end of Day 1 and Day 2. There were three lucky draws given at the end of Day 3 to add up to the excitement of the students. It happened for the first time in India that a company gave SAMSUNG TABLETS in a lucky draw in a Live Educational Tour. Last but not least, the students also received a participation certificate and score list of the questionnaire at the end of the three-day event.<br/><br/>

              It feels right to see a change in the culture of education. Memories Maker has set a benchmark for everyone in the same domain by making this initiative. It is indeed a new way of learning while sitting at home. Memories Maker has started an era of Live Virtual Educational Tours in the Indian market which will leave an imprint on the students’ minds and hearts.
              </p>
          </div>
        </Grid>
        <Grid item xl={3} xs={12} sx={{display:{md:'none', xs:'block'}, marginTop:'30px'}}>
        <SectionHeader firstTxt='RECENT' lastTxt="BLOGS" />
        <OwlCarousel className='owl-theme' {...blogmobcarouselOptions}>
        {
                blogs.map((properties)=>{
                  return(
                    <div className='item'>
                      <Card sx={{ maxWidth: 345, margin:'auto', marginBottom:'15px' }} className='cardImgBox'>
                        <div className="shareBox">
                          <SpeedDial ariaLabel="SpeedDial playground example" direction="left" className='shareButtonSpeedDial' icon={<ShareIcon/>}>
                              {
                                actions.map((action) => ( <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} /> ))
                              }
                          </SpeedDial>
                        </div>
                        <CardMedia component="img" height="120px" image={properties.img} alt="Paella dish" />
                        <CardContent style={{backgroundColor:'recd'}}>
                          <Typography variant="body2" color="text.secondary" className='blogCardHeader'>
                            {properties.head}
                            <ExpandMore expand={expanded} onClick={()=>{handleExpandClick()}} aria-expanded={expanded} aria-label="show more">
                              <ExpandMoreIcon style={{padding:'0'}} />
                            </ExpandMore>
                          </Typography>
                        </CardContent>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                          <CardContent style={{padding:'0'}}>
                            <Typography paragraph>
                            {properties.title}
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                   </div>
                  )
                })
              }
        </OwlCarousel>
        </Grid>
        <Grid item xl={3} sx={{display:{md:'block', xs:'none'}}}>
            <div className='resentBlogs'>
              <Grid spacing={2} container>
              {
                blogs.map((properties)=>{
                  return(
                    <Grid item xl={12} md={3}>
                      <Card sx={{ maxWidth: 345, margin:'auto', marginBottom:'15px' }} className='cardImgBox'>
                        <div className="shareBox">
                          <SpeedDial ariaLabel="SpeedDial playground example" direction="left" className='shareButtonSpeedDial' icon={<ShareIcon/>}>
                              {
                                actions.map((action) => ( <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} /> ))
                              }
                          </SpeedDial>
                        </div>
                        <CardMedia component="img" height="180" image={properties.img} alt="Paella dish" />
                        <CardContent style={{backgroundColor:'recd'}}>
                          <Typography variant="body2" color="text.secondary" className='blogCardHeader'>
                            {properties.head}
                            <ExpandMore expand={expanded} onClick={()=>{handleExpandClick()}} aria-expanded={expanded} aria-label="show more">
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </Typography>
                        </CardContent>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                          <CardContent style={{padding:'0'}}>
                            <Typography paragraph>
                            {properties.title}
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                   </Grid>
                  )
                })
              }
              </Grid>
            </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default BlogSection;