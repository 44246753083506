import React from 'react'
import BannerSection from './BannerSection'
import Header from './Header'
import {Link} from 'react-router-dom';
function Termsandconditions() {
  return (
    <>
    <Header/>
    <BannerSection pageHeader="Terms &amp; Conditions" />

    <div style={{display: 'flex', justifyContent:'space-between'}}>
    <div className="documentsTabsBox" style={{minWidth:'20%'}}>
    <ul className="documentsTabs" style={{position:'sticky', top:'150px'}}>
            <li><Link style={{color:'#2c3e50'}} to="/terms_and_conditions">Terms &amp; Conditions</Link></li>
            <li><Link to="/privacy_policy" style={{color:'#2c3e50'}}>Privacy Policy</Link></li>
        </ul>
    </div>

    <div className="conditionsListContainer">
    <div className="innerDiv">
        <ul className="conditionsList">
            <li>
                <p style={{color:'rgba(0, 0, 0, .4)', fontWeight:'bold', textAlign:'right', marginBottom:'15px', fontSize: '14px'}}>Last Updated Date: 29-12-2021</p>

                <p>
                    Please read the following terms and conditions carefully. You must not make and accept any booking unless you understand and agree with the following terms and conditions ('Terms and Conditions'). References to "us", "we", "our", 'Company' and/or "Memories Maker" in these booking terms and conditions shall mean Memories Maker Edu Pvt Ltd. References to 'You', 'Client' and/or 'Customer' means the person/s in whose name and/or on whose behalf the booking is made and/or whose name is on the Checklist and invoice. References to 'supplier' and/or 'third party providers' means supplier or provider of any travel related products and services from visa counsellors, passport officers and other relevant governmental agencies (such as DGCA), airlines, restaurants, theme parks, cruises, foreign exchange dealers, caterers, railways, car rental operators, tour operators, hotels, courier companies, transportation, and logistics companies etc. We will rely on the authority of the person making the booking to act on behalf of any other travelers/persons on the booking and that person will bind all such other travelers/ persons to these Terms and Conditions.
                </p>
            </li>

            <li id="Price_And_Tax_Policy">
                <h3>Price and Tax Policy</h3>
                <p>We reserve the right to amend the price of unsold tours at any time and correct errors in the prices of confirmed tours. We also reserve the right to increase the price of confirmed tours solely to allow for increases which are a direct consequence of changes in:
                </p>
                <ol type="a">
                    <li>The price of the carriage of passengers resulting from the cost of fuel or other power sources.</li>
                    <li>The level of taxes or fees chargeable for services applicable to the holiday imposed by third parties not directly involved in the performance of the holiday, including tourist taxes, landing taxes or embarkation or disembarkation fees at ports and airports and the exchange rates relevant to the package.</li>
                </ol>
                <p>
                    From time-to-time the Tour Operator may offer reduced pricing on certain products or services. Reduced pricing applies only to new bookings. Bookings where payment of at least a deposit has been received by the Tour Operator are not entitled to reduced pricing.
                </p>                    
            </li>
            <li id="Cancellation_By_The_Customer">
                <h3>Cancellation by the Customer</h3>
                
                        <p>
                            You may cancel your holiday at any time providing you notify the Company in writing. The following charges will be levied on any cancellation:
                        </p>
                        <table border="1" className="chargesTable">
                            <tr>
                                <td>30 days or more</td>
                                <td>50% of Deposit</td>
                            </tr>
                            <tr>
                                <td>15 – 30 days</td>
                                <td>100% of Deposit</td>
                            </tr>
                            <tr>
                                <td>8 – 15 days</td>
                                <td>80% of Tour Cost</td>
                            </tr>
                            <tr>
                                <td>1 – 7 days</td>
                                <td>100% of Tour Cost</td>
                            </tr>
                        </table>
                    
                        <p>
                            After the itinerary is confirmed, the client is permitted free of administration charges one set of amendments, though will be liable for any added cost this may add to the tour, including airline charges, hotels etc. Any further modifications, which are instigated by the client, will incur a charge of 25 Omani Rials due to communication and administration expenses that may have incurred.
                        </p>
                                      
            </li>

            <li id="Cancellation_By_The_Tour_Operator">
                <h3>Cancellation by the Tour Operator</h3>
                <p>
                    We aim to operate all tours as advertised but as we plan the tours many months in advance, we may occasionally have to make changes or cancel your booking and we reserve the right to do so at any time. Occasionally we may have to make a significant change to your confirmed arrangements. Examples of “significant changes” include the following, when made before departure:
                </p>
                <ol type="a">
                    <li>
                        A change of accommodation area for the whole or a significant part of your time away.
                    </li>
                    <li>
                        A change of accommodation to that of a lower standard or classification for the whole or a significant part of your time away.
                    </li>
                    <li>
                        A change of outward departure time or overall length of your arrangements by more than 12 hours.
                    </li>
                    <li>A significant change to your tour itinerary, missing out one or more destinations entirely.</li>
                </ol>
                <p>
                    If a Tour is cancelled by the Tour Operator before the date of departure for reasons other than Force Majeure and the cancellation is not caused by your fault or negligence, you will have the choice of accepting from the Tour Operator:
                </p>
                <ol type="a">
                    <li>A substitute Tour of equivalent or superior value; or</li>
                    <li>A substitute Tour of lesser value if no Tour of equivalent or superior value is reasonably available and to recover from the Tour Operator the difference in price between the price of the Tour originally purchased and the substitute Tour; or</li>
                    <li>A full refund of all monies paid for the cancelled Tour.</li>
                </ol>
                <p>
                    The Tour Operator is not responsible for any incidental expenses or consequential losses that you incur as a result of the cancelled booking including visas, vaccinations, non-refundable flights or rail, non-refundable car parking or other fees, loss of earnings, or loss of enjoyment, the Tour Operator reserves the right to issue a full refund in lieu of the choices above, in its sole discretion. Where a significant element of a Tour as described cannot be provided after departure, the Tour Operator will make suitable alternative arrangements where possible. If it is not possible to provide a suitable alternative or if you reasonably reject any suitable alternatives, the Tour Operator may provide you with a refund for unused products or services as determined in its discretion.
                </p>
            </li>

            <li id="Passport_And_Visas">
                <h3>Passport and Visas</h3>
                <p>
                    All individuals departing from India must be in possession of a valid passport and relevant visas. When assisting with international travel, we assume that all travelers have valid passports. It is your responsibility to ensure that you have valid documentation, including but not limited to passports, visas and re-entry permits which meet the requirements of immigration and other government authorities. Any fines, penalties, payments, or expenditures incurred because of such documents not meeting the requirements of those authorities will be your sole responsibility. All the expense incurred for the visa/passport interview shall be borne by you and is not a part of the package cost. The Client is personally responsible for ensuring that they have a valid passport, relevant visa(s).
                </p>
                <p>
                    Issuance of visas depends on the sole discretion of the visa counsellor and we shall only act as a representative on behalf of the Client for the purposes of submitting the visa applications and related documents. All the visa related application(s) and document(s) in respect of the visa processing shall be duly submitted to Memories Maker as per the relevant visa guidelines and within the timelines, as advised by us from time to time. In the event, the visa application made by the Client or by Memories Maker (as the case may be) on his/her behalf is rejected by the visa counsellor/consulate due to either inadequate supporting documents or for whatever reason or where the visa could not be processed due to late submission of application by the Client, Memories Maker shall not be liable for such rejection under any circumstances. Rejection of visa shall lead to forfeiture of booking amount paid and no claim whatsoever shall be entertained for the same. The decision of visa grant, duration of the visa validity, number of entries permitted in respect of each such visa as well as the timelines for communicating visa decisions is at the sole and absolute discretion of the visa counsellor/consulate and Memories Maker shall have no liability whatsoever.
                </p>
            </li>

            <li id="Payments">
                <h3>Payments</h3>
                <p>
                    For the services contracted, 50% advance payment should be made to hold the booking on a confirmed basis and the balance amount can be paid at least 7 days prior to your date of departure from your country. We hold the right to decide upon the amount to be paid as an advance payment, based on the nature of services and the time left for the commencement of the services. Apart from the above in some cases like special train journeys, hotel, or resort bookings during the peak season (X-Mas, New Year) full payment is required to be sent in advance
                </p>
            </li>

            <li id="Health_And_Travel_Insurance">
                <h3>Health and Travel Insurance</h3>
                <p>
                    Client must ensure that he/she is aware of any health requirements and recommended precautions relevant to the travel and ensure that the Client carries all necessary vaccination documentation. In some cases, failure to present required vaccination documentation may deny you entry into a country. We recommend that the Client consult with their local doctor or travel medical service before commencing your travel. Advice on health requirements may be obtained from the Department of Health leaflet Advice on Health for Travelers, which may be obtained from the Department of Health of the country that will be visited.</p>
                <p>
                    In case the Client decides to obtain travel insurance, it should be noted that that Memories Maker acts as a mere facilitator and that the contract of travel insurance is directly between the Client and the insurer. Client should check all the details of the travel insurance and in case of any error or lapse, report the same to be communicated to the Insurer directly and get the same rectified by them, as Memories Maker would not be responsible for the same. If the Client declines travel insurance, Client may be required to sign a disclosure. All Clients not possessing valid travel insurance are travelling at their own risk.
                </p>
            </li>

            <li id="Surcharge">
                <h3>Surcharge</h3>
                <p>
                    The Price of your travel arrangement is subject to surcharges in case of sudden hike in fuel prices, schedule airfare and any taxes levied by the government. Even in this case, we will try to absorb an amount of 2%, amount exceedingly over 2% will be surcharged.
                </p>
            </li>

            <li id="Our_Liability">
                <h3>Our Liability</h3>
                <p>
                    We accept the liability for those arrangements for your holiday, which are wholly within our control and therefore, accept liability to clients for the negligence of our employees. However, we would like to draw attention to the following circumstances, which fall outside our direct control or where for some reason we are not prepared to accept liability.
                </p>
                <p>
                    The Company shall, in no circumstances whatsoever be liable to the client or any person travelling with him or her for: Any death, personal injury, sickness accidents, loss delay, discomfort, increased expenses, consequential loss and / or damage or theft howsoever caused.
                </p>
                <p>
                    Any act, omission, default of any independent contractor or other person or by any servant or agents employed by them who may be engaged or concerned in the provision of accommodation, refreshment, carriage facility or service for the client or for any person travelling with him howsoever caused. We are unable to take liability for commercial arrangements out of our control such as natural disasters, industrial disputes, reason of threat or war, riots, civil strife, terrorist activities, technical problems to the transport, extreme weather conditions, closure of the airports and seaports and similar event out of our control or are due to unusual and unforeseeable circumstances beyond our control.
                </p>
            </li>

            <li id="Travel_Documents">
                <h3>Travel Documents</h3>
                <p>
                    Travel documents include (without limitation) airline tickets, hotel vouchers, tour vouchers or any other document (whether in electronic form or otherwise) used to confirm a travel and accommodation related arrangement with a service provider. Travel documents may be subject to certain conditions and/or restrictions including (without limitation) being non-refundable, non-date-changeable and subject to cancellation and/or amendment fees. All travel documents are non-transferable. All airline tickets must be issued in the name of the passport/photo identity holder, some carriers will deny carriage if the name varies between the travel documents and the airline tickets, and the booking may be cancelled subject to cancellation charges. Please review your travel documentation carefully and advise us immediately of any errors in the details as set out in the Checklist. Any errors in names on your travel documentation will be your responsibility if not advised at the time of booking. It is your responsibility to collect all travel documents from us prior to travel.
                </p>
            </li>
            <li id="Privacy_Policy">
                <h3>Privacy Policy</h3>
                <p>
                    Memories Maker is committed to protecting the privacy and confidentiality of personal information. Click <Link to="/privacy_policy">here</Link> to view our Privacy Policy.
                </p>
            </li>
        </ul>
    </div>
</div>
    <div style={{minWidth: '20%', backgroundColor:'white'}} className="contentListBox">
        <div style={{ position:'sticky', top:'150px'}}>
            <h4 className="ml-3">Content List</h4>
        <ul>
            <li>
                <a href="#Price_And_Tax_Policy">Price And Tax Policy</a>
            </li>
            <li>
                <a href="#Cancellation_By_The_Customer">Cancellation By The Customer</a>
            </li>
            <li>
                <a href="#Cancellation_By_The_Tour_Operator">Cancellation By The Tour Operator</a>
            </li>
            <li>
                <a href="#Passport_And_Visas">Passport And Visas</a>
            </li>
            <li>
                <a href="#Payments">Payments</a>
            </li>
            <li>
                <a href="#Health_And_Travel_Insurance">Health And Travel Insurance</a>
            </li>
            <li>
                <a href="#Surcharge">Surcharge</a>
            </li>
            <li>
                <a href="#Our_Liability">Our Liability</a>
            </li>
            <li>
                <a href="#Travel_Documents">Travel Documents</a>
            </li>
            <li>
                <a href="#Privacy_Policy">Privacy Policy</a>
            </li>
        </ul>
        </div>
    </div>
</div>
    </>
  )
}

export default Termsandconditions