import React, { useState } from "react";
import SectionHeader from "../SectionHeader";
import OwlCarousel from "react-owl-carousel";
import GalleryCarouselCard from "./GalleryCarouselCard";
import GalleryCarouselImgModal from "./GalleryCarouselImgModal";

const gallerycarouselOptions = {
  margin: 10,
  nav: false,
  dots: false,
  loop: true,
  rtl: false,
  autoplay: true,
  slideTransition: 14000,
  animateIn: "linear",
  animateOut: "linear",
  autoplayTimeout: 14000,
  autoplaySpeed: 14000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    400: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    600: {
      items: 3,
      slideTransition: "linear",
      rtl: false,
    },
    700: {
      items: 4,
      slideTransition: "linear",
      rtl: false,
    },
    1000: {
      items: 5,
      slideTransition: "linear",
      rtl: false,
    },
  },
};

const gallerycarouselOptions2 = {
  margin: 10,
  nav: false,
  dots: false,
  loop: true,
  rtl: true,
  autoplay: true,
  autoplayTimeout: 14000,
  autoplaySpeed: 14000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 3,
    },
    400: {
      items: 3,
    },
    600: {
      items: 3,
    },
    700: {
      items: 4,
    },
    1000: {
      items: 5,
      slideTransition: "linear",
      rtl: true,
    },
  },
};

function GalleryCarousel(props) {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (val, index) => {
    setCurrentIndex(index);
    setClickedImg(val.source);
  };

  const handleRotationRight = () => {
    const totalLength = firstCarousel.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = firstCarousel[0].source;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = firstCarousel.filter((val) => {
      return firstCarousel.indexOf(val) === newIndex;
    });
    const newItem = newUrl[0].source;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const firstCarousel = [
    {
      source:
        "images/cultural-exchange-program-paris-carousel/cultural-exchange-program-paris-1.jpg",
      title: "Singapore Comes Home",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/cultural-exchange-program-paris-2.jpg",
      title: "The Bastille Day - La Fête Nationale",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/cultural-exchange-program-paris-3.jpg",
      title: "चलते चलते French",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/cultural-exchange-program-paris-4.jpg",
      title: "Eiffel Tower Comes Home",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/cultural-exchange-program-paris-5.jpg",
      title: "Dubai Comes Home",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/cultural-exchange-program-paris-6.jpg",
      title: "Paris Comes Home",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/cultural-exchange-program-paris-7.jpg",
      title: "Paris Comes Home",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/cultural-exchange-program-paris-8.jpg",
      title: "Paris Comes Home",
    },
  ];
  const secondCarousel = [
    {
      source:
        "images/gallery/cultural-exchange-program-paris-carousel/paris-tour-15.webp",
      title: "The Secrets of French Macarons",
    },
    {
      source:
        "images/gallery/cultural-exchange-program-paris-carousel/dubai-tour-02-01062017.webp",
      title: "The Chocolate Story",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/dubai-tour-03-31052017.jpg",
      title: "Discover Space Reality",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/dubai-tour-12-29052017.jpg",
      title: "Bake Cheese Souffle",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/paris-tour-01.jpg",
      title: "Flaky, Fluffy Croissants at Home",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/paris-tour-04.jpg",
      title: "Student Virtual Exchange",
    },
    {
      source:
        "images/cultural-exchange-program-paris-carousel/paris-tour-16.jpg",
      title: "Student Virtual Exchange",
    },
  ];
  return (
    <div className="customContainer">
      <div className="headerBox">
        <div className="innerBox">
          <span className="boldText">Experience </span>
          <span className="liteText">&nbsp;Corner</span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-md-6 mb-3">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/DB_urocLLvQ?si=aDg140IB3kU27n6X"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
              style={{ width: "100%" }}
            ></iframe>
          </div>
          <div className="col-md-6">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ZBUOjeOKIUs?si=zL2S1wVSeGFfZjkF"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
              style={{ width: "100%" }}
            ></iframe>
          </div>
        </div>
      </div>
      <div>
        <OwlCarousel className="owl-theme" {...gallerycarouselOptions}>
          {firstCarousel.map((val, index) => (
            <div className="item">
              <div className="galleryCarouselBox">
                <div className="imgBox">
                  <img
                    src={val.source}
                    style={{ cursor: "pointer" }}
                    alt=""
                    onClick={() => handleClick(val, index)}
                  />
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>

      <div style={{ marginTop: "10px" }}>
        <OwlCarousel className="owl-theme" {...gallerycarouselOptions2}>
          {secondCarousel.map((val, index) => (
            <div className="item">
              <div className="galleryCarouselBox">
                <div className="imgBox">
                  <img
                    src={val.source}
                    style={{ cursor: "pointer" }}
                    alt=""
                    onClick={() => handleClick(val, index)}
                  />
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
        {clickedImg && (
          <GalleryCarouselImgModal
            clickedImg={clickedImg}
            handleRotationRight={handleRotationRight}
            setClickedImg={setClickedImg}
          />
        )}
      </div>
    </div>
  );
}

export default GalleryCarousel;
