import { React } from "react";

function SectionHeader(props) {
  return (
    <>
      <div className="headerBox">
        <div className="innerBox">
          <span className="boldText">{props.firstTxt} </span>
          <span className="liteText">&nbsp;{props.lastTxt}</span>
        </div>
      </div>
    </>
  );
}

export default SectionHeader;
