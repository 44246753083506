import React from 'react';
import Header from './Header';
import BlogSection from './BlogSection';
import Footer from './Footer';

function Blogs() {
  return (
    <>
        <Header/>
        <BlogSection/>
        <Footer/>
    </>
  )
}

export default Blogs