import React from 'react';
import Header from './Header';
import Footer from './Footer';
import BannerSection from './BannerSection';
import FounderSection from './FounderSection';
function Founder() {
  return (
    <>
        <Header/>
        <BannerSection pageHeader="MEET OUR FOUNDER" />
        <FounderSection/>
        <Footer/>
    </>
  )
}

export default Founder;