import React from 'react';

function BannerSection(props) {
  return (
    <div>
        <div className='pageBanner'>
            <div className='headerWrapper'>
                <div className='bannerHeader'>
                    <span>{props.pageHeader}</span>
                </div>
                <div className='bannerSubHeader'>
                    <span>M</span>
                    <span>E</span>
                    <span>M</span>
                    <span>O</span>
                    <span>R</span>
                    <span>I</span>
                    <span>E</span>
                    <span>S</span>
                    <span></span>
                    <span>M</span>
                    <span>A</span>
                    <span>K</span>
                    <span>E</span>
                    <span>R</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BannerSection;