import React from 'react';
import Header from './Header';
import BannerSection from './BannerSection';
import SectionHeader from './SectionHeader';
import Footer from './Footer';
import Container from '@mui/material/Container';
function About() {
  return (
    <>
        <Header/>
        <BannerSection pageHeader="ABOUT US" />
        <Container sx={{marginTop:{md:'150px', xs:'40px'}}}>
            <div>
                <SectionHeader firstTxt="HOW WE MAKE A DIFFERENCE ?"/>
                <div>
                    <p className='aboutContent'>
                        Memories Maker Is an Indian Edu Travel Company, having headquarters in Sonipat, Haryana. Founded in 2016 by Nitin Popli, Memories Maker came into existence with the idea of giving travel a different meaning altogether. Our niche is creating personalised and experiential learning for students while travelling to different places. We bring students, knowledge, experience, and travel under the same roof to exchange the best they can. To date, we have connected lakhs of students to the outside world. We proudly say that we are the pioneer in the Indian Edu Travel Industry by providing unique and dynamic ideas of education along with travel. We believe in an 'ecosystem' approach to learning rather than isolated training.
                    </p>
                </div>
            </div>

            <div>
                <SectionHeader firstTxt="WHAT WE DO ?"/>
                <div>
                <p className='aboutContent'>
                    Memories Maker organises Edu tours around the globe for students The Educational trips to domestic and international places are well equipped with activities, luxury, fun, education, language, and culture of different people and countries. Our Tours are well planned and executed by keeping the students, their interests, and their expenses in mind. Our affordable tours outstand us in the field. Along with Edu tours, we organise workshops, cultural exchange programs, and field trips for Indian students. Our programs are specially curated to take education beyond the classroom and help students explore and experience different cultures.<br/>
                    In 2020, when the whole world was dealing with the pandemic of Covid19, we were constantly working towards providing international exposure and learning to our students. We always believe in the holistic development of a student and the fact that the learning should not stop just because the schools and colleges are closed. This emerged the idea of Live Virtual Educational Tours (LVET) in the Indian Travel Industry. By organising the first Live Virtual Educational Tour to Paris in October 2020, Memories Maker became the inventor of LVETs in India.
                    <br/>
                    After the success story of LVETs, we are all set to hold the grip on the Indian market with other segments such as the Teachers Training Program, Quizzes, and many more on the list.
                    </p>
                </div>
            </div>

            <div>
                <SectionHeader firstTxt="MISSION &amp; VISION"/>
                <div>
                <p className='aboutContent'>
                        We at Memories Maker, constantly work towards making our tours and itineraries more friendly and student-centric. After capturing the Indian market, our mission is to become the world&#8217;s number one Edu Travel Company. Having a family of lakhs of students travelling the world with us gives us the confidence of exploring other areas of travel and education. After the absolute success of Edu Tours and Live Virtual Educational Tours, our vision is to put the feet down to other areas such as Teacher&#8217;s exchange Program, international quizzes and many more.
                    </p>
                </div>
            </div>

            <div>
                <p className='quoteSection'>
                    <i className='quote001'>
                    Books are infinite in number and time is short; therefore, the secret of knowledge is to take what is essential. Take that and try to live up to it.
                    </i>
                    <span className='quote001Writter'> – Swami Vivekananda</span>
                </p>
            </div>
        </Container>
        <Footer/>
    </>
  )
}

export default About