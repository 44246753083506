import React from 'react';
import {Box, Container, Button} from '@mui/material';


function FounderSection() {
  return (
    <Container style={{backgroundColor:'white', paddingBottom:'30px', marginTop:'30px'}}>
        <div style={{fontSize:'23px', textAlign:'justify'}}>
            <Box sx={{float:'left', margin:{md:'5px 20px', xs:'0 0 15px 0'}}}>
              <div className="chairmanPhoto">
                <img src="images/faces/nitin-popli.jpg" alt="Founder" />
              </div>
              <div className="chairmanNameBox">
                <span className="chairmanName">Nitin Popli (Founder &amp; MD)</span><br/>
                <span className="chairMancompanyName">Memories Maker Edu Pvt. Ltd.</span>
            </div>
            </Box>
            <p className='aboutChairman'>
            I am not a mere persona but a passionate teacher who always loved being around the students. I believe in the holistic development of a child and the fact that education is not limited to the classroom. With this belief, I started Memories Maker in 2016 to give students International exposure to education, leisure, luxury, people, and culture. Starting from Dubai to Paris to Singapore to London to New York and much more, our way of giving an extraordinary educational tour has left the Students, Teachers and Principals speechless. Our Educational Tours not only make the students smarter but also better and responsible citizens of the nation.
            </p>

            <p className='aboutChairman'>
            History is testimony to the fact that great leaders always try to do things differently. Hence, our Edu Tours are specially curated for the students where they can exchange Indian language and culture on an international land. We not only show places but give a chance to the students to push boundaries and be limitless with their dreams.
            </p>

            <p className='aboutChairman'>
              We believe 2020 is tough for everyone but even tougher for kids where they missed out on going to school, meeting friends, playing in the playground and most of all, their vacations. Be it national or international! Our kids have suffered the most. We have kept the struggle and misses in our mind and launched the concept of Live Virtual Educational Tour in the Indian market.</p>

            <p className='aboutChairman'>
            To the students, I have nothing but one message to give. Love what you do and do what you love. Take charge of your life and make the best of it. Everything teaches a lesson and every lesson is a learning.
            </p>

            <p className='aboutChairman'>
            I would like to reiterate my belief with this quote by Jim Stovall: Education is a lifelong journey whose destination expands as you travel.</p>
            
        </div>
    </Container>    
  )
}

export default FounderSection;