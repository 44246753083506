import React from 'react';
import Header from './Header';
import Footer from './Footer';
import BannerSection from './BannerSection';
import HTMLFlipBook from 'react-pageflip';
import Company_Profile from './assets/Company_Profile.pdf';
import {AiOutlineDownload} from 'react-icons/ai';

const CompanyProfile = () => {
  return (
    <>
    <Header/>
    <BannerSection pageHeader="COMPANY PROFILE" />
    <div className='blogContenT'>
        <iframe src="https://memoriesmakeredu.com/Company_Profile.pdf" name="exam_frame"></iframe>
    </div>

    <div className='flipBook'>
    <HTMLFlipBook width={300} height={550} size="stretch">
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0001.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0002.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0003.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0004.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0005.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0006.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0007.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0008.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0009.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0010.jpg' style={{width:'100%', height:'90%'}} /></div>
        <div className="pdfPage"><img src='images/pdfImages/Company_Profile_page-0011.jpg' style={{width:'100%', height:'90%'}} /></div>
    </HTMLFlipBook>
    </div>
      <div className='cta'>
        <p>You can also Download the PDF of our Company Profile here</p>
        <a href={Company_Profile} download className='BTN'>Download <span><AiOutlineDownload/></span></a>
      </div>
      <Footer/>
    </>
  )
}

export default CompanyProfile
