import React from 'react';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import Box from '@mui/material/Box';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';

import {RiFacebookLine, RiTwitterFill, RiInstagramLine} from "react-icons/ri";


  
  const actions = [
    { icon: <RiFacebookLine />, name: 'Facebook' },
    { icon: <RiTwitterFill />, name: 'Twitter' },
    { icon: <RiInstagramLine />, name: 'Instagram' },
  ];


const cardValues = [{date:'April 20, 2022', img:'images/news/temp-culinaryArtNews.jpg'}];


function NewsSection(props) {
    return (
        
            <Card sx={{ maxWidth: 345 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: 'white', border:'1px solid grey' }} aria-label="recipe">
                <img src="images/logo/MM-Color.png" style={{width:'25px'}} alt="" />
              </Avatar>
            }
            action={
            //   <IconButton aria-label="settings">
            //     <MoreVertIcon />
            //   </IconButton>
            // <IconButton aria-label="share">
            //   <ShareIcon />
            // </IconButton>

            <div style={{position:'relative'}}>
                <SpeedDial style={{position: 'absolute', top: '0px', right: '0'}}
                ariaLabel="SpeedDial playground example"
                direction="left"
                className='shareButtonSpeedDial'
                icon={<ShareIcon />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
              />
            ))}
          </SpeedDial>
            </div>

            }
            title={props.newstitle}
            subheader={props.date}
          />
          <CardMedia
            component="img"
            height="180"
            image={props.img}
            alt="Paella dish"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
            {props.newscontent}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            {/* <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton> */}
            
          </CardActions>
          
        </Card>
      );
}

export default NewsSection