import React from "react";
import Header from "../../../Header";
import BannerSection from "../../../BannerSection";
import Footer from "../../../Footer";

const Vietnam = () => {
  return (
    <>
      <Header />
      <BannerSection pageHeader="Our Vietnam Tour" />

      <div className="container">
        <h1
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontFamily: "-moz-initial",
          }}
        >
          Vietnam
        </h1>
        <div className="row">
          <div className="col-md-9">
            <div className="container main">
              <div className="col-md-12 ms-5 mainImg">
                <div className="row">
                  <img
                    src="images/educational-tours/international/Vietnam_tour/photo_2023-11-23_15-29-33.jpg"
                    alt="Paris Tour"
                    style={{ width: "100%" }}
                  />
                  <h3 className="mt-3" style={{ textAlign: "center" }}>
                    Vietnam Tour
                  </h3>
                  <p className="mt-3">
                    Embark on a journey of discovery and learning as we explore
                    the rich history, vibrant culture, and breath-taking
                    landscapes of Vietnam. Our educational tour is designed to
                    provide students with a unique and immersive experience,
                    fostering a deeper understanding of this fascinating
                    country.
                    <br></br>
                    <br></br>
                    <b>Tour Highlights:</b>
                    <br></br>
                    <br></br>1 – Cultural immersion
                    <br></br>2 – Educational Workshops
                    <br></br>3 – Language Experience
                    <br></br>4 – Local Cuisine
                    <br></br>5 – Educational Partnerships
                    <br></br>
                    <br></br>
                    <b>Destination Highlights:</b>
                    <br></br>
                    <br></br>
                    <b>
                      1 - Ho Chi Minh Mausoleum and Temple of Literature -
                    </b>{" "}
                    Explore the historic Ho Chi Minh Mausoleum, dedicated to the
                    founding father of Vietnam. Visit the Temple of Literature,
                    Vietnam's first university.
                    <br></br>
                    <br></br>
                    <b>2 - Halong Bay Cruise -</b> Embark on a scenic cruise
                    through the breath-taking landscapes of Halong Bay, a UNESCO
                    World Heritage Site. Learn about the geography and history
                    of this stunning natural wonder.
                    <br></br>
                    <br></br>
                    <b>3 - Hoi An Ancient Town -</b> Explore the charming
                    streets of Hoi An Ancient Town, known for its well-preserved
                    architecture. Discover the cultural melting pot that has
                    shaped this UNESCO-listed city.
                    <br></br>
                    <br></br>
                    <b>4 - Ho Chi Minh City -</b> Fly to Ho Chi Minh City,
                    formerly known as Saigon. Explore the War Remnants Museum
                    and gain a deeper understanding of Vietnam's modern history.
                    <br></br>
                    <br></br>
                    <b>5 – Cu Chi Tunnels -</b> Discover the intricate network
                    of Cu Chi Tunnels, used by the Viet Cong during the Vietnam
                    War. Gain first-hand knowledge of guerrilla warfare tactics.
                    <br></br>
                    <br></br>
                    <b>6 - Mekong Delta -</b> Cruise through the Mekong Delta,
                    exploring its vibrant floating markets and traditional
                    villages. Learn about the importance of the Mekong River in
                    Vietnamese culture and economy.
                    <br></br>
                    <br></br>
                    <b>Why Choose Our Educational Tour?</b>
                    <br></br>
                    <br></br>
                    <b>1 - Curriculum Integration:</b> Our tour is designed to
                    complement classroom learning, providing students with
                    real-world experiences that enhance their academic
                    knowledge.
                    <br></br>
                    <br></br>
                    <b>2 - Expert Guides:</b> Our experienced guides are
                    passionate about Vietnam's history, culture, and geography,
                    ensuring an informative and engaging experience for
                    students.
                    <br></br>
                    <br></br>
                    <b>3 - Cultural Immersion:</b> Immerse yourself in the local
                    culture through interactions with communities, traditional
                    performances, and hands-on activities.
                    <br></br>
                    <br></br>
                    <b>4 - Safety and Comfort:</b> We prioritize the safety and
                    comfort of our participants, providing well-planned
                    itineraries, comfortable accommodations, and reliable
                    transportation.
                    <br></br>
                    <br></br>
                    <b>
                      Join us on this educational adventure, where history comes
                      to life, cultures intertwine, and lifelong memories are
                      created.
                    </b>
                  </p>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="container">
              <div className="sidebarItem">
                <div className="sideImg">
                  <img src="images/educational-tours/international/Vietnam_tour/photo_2023-11-23_15-29-28.jpg" />
                </div>
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Where </span>
                    <span className="liteText">&nbsp;Was Our Trip</span>
                  </div>
                </div>
                <img
                  src="images/educational-tours/international/Vietnam_tour/vietnam.png"
                  style={{ width: "60%", marginLeft: "75px" }}
                />
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    fontFamily: "monospace",
                  }}
                >
                  Vietnam
                </p>
              </div>
              <div className="sidebarItem">
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Instagram</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzvyX26S89u/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic1.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzuzrFJSKuU/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic2.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzyP57kSsVq/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic3.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/Czuw0EkyN4-/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic4.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Experience</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Vietnam_tour/photo_2023-11-23_17-16-04.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Vietnam_tour/photo_2023-11-23_17-16-16.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Vietnam_tour/photo_2023-11-23_17-16-13.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Vietnam;
