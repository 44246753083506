import { React } from "react";
import SectionHeader from "../SectionHeader";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const priorityOptions = {
  margin: 10,
  responsiveClass: true,
  nav: false,
  dots: false,
  loop: false,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 2,
    },
    425: {
      items: 2,
    },
    600: {
      items: 3,
    },
    768: {
      items: 3,
    },
    1024: {
      items: 4,
    },
    1440: {
      items: 4,
    },
    1920: {
      items: 5,
    },
  },
};

function Priorities() {
  return (
    <>
      <div className="customContainer" id="prioritySection">
        <SectionHeader firstTxt="OUR" lastTxt="PRIORITIES" />
        <Box className="webview_priorityCarousel">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md">
                <div className="priorityBox">
                  <div className="priorityImg">
                    <img
                      src="images/icons/Student-safety.png"
                      alt=""
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                  <div className="priorityTitle">
                    <h3>Students Safety</h3>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="priorityBox">
                  <div className="priorityImg">
                    <img
                      src="images/icons/Best-and-healthy-food.png"
                      alt=""
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                  <div className="priorityTitle">
                    <h3>Best and Hygienic Food</h3>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="priorityBox">
                  <div className="priorityImg">
                    <img
                      src="images/icons/comfortable-stay.png"
                      alt=""
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                  <div className="priorityTitle">
                    <h3>Comfortable Stay</h3>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="priorityBox">
                  <div className="priorityImg">
                    <img
                      src="images/icons/Taitor-made-itinerary.png"
                      alt=""
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                  <div className="priorityTitle">
                    <h3>Tailor-Made Itinerary</h3>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="priorityBox">
                  <div className="priorityImg">
                    <img
                      src="images/icons/Hassel-free-booking.png"
                      alt=""
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                  <div className="priorityTitle">
                    <h3>Hassle-Free Booking</h3>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="priorityBox">
                  <div className="priorityImg">
                    <img
                      src="images/icons/comfortable-transportation.png"
                      alt=""
                      style={{ width: "120%", display: "block" }}
                    />
                  </div>
                  <div className="priorityTitle">
                    <h3>Comfortable-Transportation</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>

        <Box className="phoneview_priorityCarousel">
          <Grid container>
            {[
              [
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="healthIcon"
                  enable-background="new 0 0 24 24"
                  height="24px"
                  width="24px"
                  viewBox="0 0 24 24"
                >
                  <rect fill="none" height="24" width="24" />
                  <path d="M10.5,13H8v-3h2.5V7.5h3V10H16v3h-2.5v2.5h-3V13z M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2 z M18,11.09c0,4-2.55,7.7-6,8.83c-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25l6,2.25V11.09z" />
                </svg>,
                "Students Safety",
              ],
              [
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="foodIcon"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M8.1 13.34l2.83-2.83L3.91 3.5c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12 14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z" />
                </svg>,
                "Best and Hygienic Food",
              ],
              [
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="comfortableIcon"
                  enable-background="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                >
                  <rect fill="none" height="24" width="24" />
                  <path d="M12,5.5l6,4.5v9H6v-9L12,5.5 M12,3L4,9v12h16V9L12,3L12,3z M15,12h-3.5v3.5H8V11H7v7h1v-1.5h8V18h1v-4 C17,12.9,16.1,12,15,12z M9.75,12.5c-0.69,0-1.25,0.56-1.25,1.25C8.5,14.44,9.06,15,9.75,15S11,14.44,11,13.75 C11,13.06,10.44,12.5,9.75,12.5z" />
                </svg>,
                "Comfortable Accommodation",
              ],
              [
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="itineraryIcon"
                  enable-background="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                >
                  <g>
                    <rect fill="none" height="24" width="24" />
                  </g>
                  <g>
                    <g />
                    <g>
                      <path d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                      <g>
                        <path d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                        <path d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                        <path d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                      </g>
                    </g>
                  </g>
                </svg>,
                "Tailor-Made Itinerary",
              ],
              [
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mobfriendlyIcon"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M19 1H9c-1.1 0-2 .9-2 2v3h2V4h10v16H9v-2H7v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zM7.01 13.47l-2.55-2.55-1.27 1.27L7 16l7.19-7.19-1.27-1.27-5.91 5.93z" />
                </svg>,
                "Hassle-Free Booking",
              ],
              [
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ANOTHERIcon"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5l1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
                </svg>,
                "Comfortable Transportation",
              ],
            ].map((value) => {
              return (
                <Grid item xs={4}>
                  <div className="phoneViewPriorityTxt">
                    <div className="iconBox mt-1">
                      <div className="svgIconBox">{value[0]}</div>
                    </div>
                    <div className="priorityTextBox">
                      <span>{value[1]}</span>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </div>
    </>
  );
}
export default Priorities;
