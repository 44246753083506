import React from 'react';
import './ClockStyle.css';
import $ from 'jquery';

import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


function Clocks() {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));
  
//   function dialLoop(){
//     var lines = $('.diallines');
//         for (var i = 1; i < 60; i++) {
//        lines[i] = $(lines[i-1]).clone().insertAfter($(lines[i-1]));
//        $(lines).css('transform', 'rotate(6deg)');
//           }
//         } 
//  dialLoop();
    
    
    function tick(clockPlace) {
      var offset;
      var localdate = new Date();
      if(clockPlace=='clockDelhi'){
        var offset = 5.5;
      }
      else if(clockPlace=='clockParis'){
        var offset = 2;
      } else if(clockPlace=='clockDubai'){
        var offset = 4;
      } else if(clockPlace=='clockSingapore'){
        var offset = 8;
      } else if(clockPlace=='clockNewyork'){
        var offset = -4;
      }
      var utc = localdate.getTime() + (localdate.getTimezoneOffset() * 60000);  
      var date = new Date(utc + (3600000*offset));
     var seconds = date.getSeconds();
     var minutes = date.getMinutes();
     var hours = date.getHours();
     var day = date.getDate();
     var secAngle = seconds * 6;
     var minAngle = minutes * 6 + seconds * (360/3600);
     var hourAngle = hours * 30 + minutes * (360/720);
    
    function realTimeView(){
        var newRealDate = new Date();
        var offset = 5.5;
        var newDate = new Date(utc + offset);
    
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
    
        $('.digiTimeDelhi').html(seconds);
    }
    
     // console.log(seconds);
     // $('.digiTimeDelhi').html(hours+" : "+seconds);
     
     $('.'+clockPlace+' .sec-hand').css('transform', 'rotate(' + secAngle + 'deg)');
     $('.'+clockPlace+' .min-hand').css('transform', 'rotate(' + minAngle + 'deg)');
     $('.'+clockPlace+' .hour-hand').css('transform', 'rotate(' + hourAngle + 'deg)');
      //  $('.date').text(day);
    }
    
    var funarra =
      [
      function showsClockInd(){tick('clockDelhi');},
      function showsClockLondon(){tick('clockParis');},
      function showsClockCalifornia(){tick('clockDubai');},
      function showsClockSingapore(){tick('clockSingapore');},
      function showsClockDubai(){tick('clockNewyork');}
      ];
      funarra.map((getvalue)=>{ setInterval(getvalue,1000); });

      let clockArray = [
        ['clockDelhi', 'NEW DELHI'], ['clockParis', 'PARIS'], ['clockDubai', 'DUBAI'], ['clockSingapore', 'SINGAPORE'], ['clockNewyork', 'NEW YORK']
      ];
  
  return (
      <>
        
        <Grid container>
          <Grid item xs={8}>
            <Grid container justifyContent="space-between">

              {
                clockArray.map(function(val, i) {
                  return (
                    <Grid item>
                      <div className={`${val[0]} dial dial1`}>
                          <div className="dot"></div>
                          <div className="sec-hand"></div>
                          <div className="min-hand"></div>
                          <div className="hour-hand"></div>
                          <span className="twelve">12</span>
                          <span className="three">3</span>
                          <span className="six">6</span>
                          <span className="nine">9</span>
                          <span className='diallines diallines1'></span>
                          <div className="date"></div>
                      </div> 
                      <div style={{textAlign:'center', color:'white', marginTop:'5px', letterSpacing:'2px'}}>{val[1]}</div>
                    </Grid>
                    )
                })
              }
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <div className='footerSubscription'>
              <h4 style={{textAlign: 'right', color: 'white'}}>Subscribe For More Info</h4>
              <form action="" method="post" id="subscriptionForm" style={{textAlign:'right'}}>
                <input type="email" style={{marginLeft:'auto'}} className="email_box" placeholder="Enter Your Email" id="subscriptionMail" name="subscriptionMail" />
                <button className="subscribeBtn ml-auto" type="button">Subscribe</button>
              </form>
            </div>
          </Grid>
        </Grid>
      </>
  )
}

export default Clocks;