import React from 'react';
import {Container, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import SectionHeader from './SectionHeader';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';

import {RiFacebookLine, RiTwitterFill, RiInstagramLine} from "react-icons/ri";

import {MdContentCopy} from "react-icons/md";
import {FaLinkedinIn, FaWhatsapp} from "react-icons/fa";
import {CgFacebook} from "react-icons/cg";
import OwlCarousel from 'react-owl-carousel';



const blogmobcarouselOptions = {
  margin: 10,
  responsiveClass: true,
  nav: false,
  dots: false,
  loop:true,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
          stagePadding:40
      },
      400: {
          items: 1,
          stagePadding:80
      },
      600: {
          items: 3,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
}

const blogs = [{head:'header', title:'title', img:'images/blogs/blog-paris.webp'},
{head:'header', title:'title', img:'images/blogs/blog-paris.webp'},
{head:'header', title:'title', img:'images/blogs/blog-paris.webp'},
{head:'header', title:'title', img:'images/blogs/blog-paris.webp'},
{head:'header', title:'title', img:'images/blogs/blog-paris.webp'}];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const actions = [
  { icon: <RiFacebookLine />, name: 'Facebook' },
  { icon: <RiTwitterFill />, name: 'Twitter' },
  { icon: <RiInstagramLine />, name: 'Instagram' },
];


function PariseduSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Container maxWidth="xxl" style={{marginTop:'50px'}}>
      <Grid container>
        <Grid item xl={9} style={{margin:'auto'}}>
          <div className='blogHeaderBox'>
            <div className='mainHeader' style={{textAlign:'center'}}>
              <span>All the way to Paris</span>
            </div>
            <div className='subHeader' style={{textAlign:'center'}}>
              <span>Educational Tour: DISCOVER PARIS AND BRUSSELS</span>
            </div>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
              {/*<div className='blogUploadedDate'>
                October, November, December
              </div>*/}
              <div style={{display:'flex', alignItems:'flex-end'}}>
                <Button  onClick={()=>{const elem = document.createElement('textarea'); elem.value = 'https://memoriesmakeredu.com/PARIS_AND_BRUSSELS_10_DAYS.pdf'; document.body.appendChild(elem);elem.select(); document.execCommand('copy'); document.body.removeChild(elem); alert('link copied');}} sx={{borderRadius:'50%', height:'30px', width:'30px', minWidth:'auto'}}><MdContentCopy/></Button>
                <Button sx={{borderRadius:'50%', height:'30px', width:'30px', minWidth:'auto'}}><a href="https://web.whatsapp.com/send?text=https://memoriesmakeredu.com/PARIS_AND_BRUSSELS_10_DAYS.pdf"><FaWhatsapp/></a></Button>
              </div>
            </div>
          </div>
          <div className="blogContent">
              <iframe src="https://memoriesmakeredu.com/PARIS_AND_BRUSSELS_10_DAYS.pdf" width="100%" height="1000" name="exam_frame"></iframe>
          </div>
        </Grid>
        
        
      </Grid>
    </Container>
  )
}

export default PariseduSection;