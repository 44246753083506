import React from "react";
import Header from "../../../Header";
import BannerSection from "../../../BannerSection";
import Footer from "../../../Footer";
import image01 from "../../../assets/IMG_1922.JPG";
import image02 from "../../../assets/IMG_1727.JPG";
import image03 from "../../../assets/IMG_2480.JPG";
import image04 from "../../../assets/E9872333-BFFD-4C6F-A5EE-0BA5D6A1F276.JPG";

const Paris = () => {
  return (
    <>
      <Header />
      <BannerSection pageHeader="Our Paris Tour" />

      <div className="container">
        <h1
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontFamily: "-moz-initial",
          }}
        >
          Paris, France
        </h1>
        <div className="row">
          <div className="col-md-9">
            <div className="container main">
              <div className="col-md-12 ms-5 mainImg">
                <div className="row">
                  <img
                    src="images/gallery/Paris-Tour-01-Nov-2023/IMG_1832.jpg"
                    alt="Paris Tour"
                    style={{ width: "100%" }}
                  />
                  <h3 className="mt-3" style={{ textAlign: "center" }}>
                    Educational Tour to Paris
                  </h3>
                  <p className="mt-3">
                    We believe in enriching the learning experience by providing
                    students with hands-on exposure to different cultures and
                    historical landmarks. Our carefully crafted itinerary
                    ensures an immersive and educational journey through the
                    beautiful country of France.
                    <br></br>
                    <br></br>
                    <b>Tour Highlights:</b>
                    <br></br>
                    <br></br>1 – Cultural immersion
                    <br></br>2 – Educational Workshops
                    <br></br>3 – Language Experience
                    <br></br>4 – Local Cuisine
                    <br></br>5 – Educational Partnerships
                    <br></br>
                    <br></br>
                    <b>Why Choose Our Educational Tours?</b>
                    <br></br>
                    <br></br>
                    <b>1 - Safety First:</b> Our top priority is the safety and
                    well-being of our students. We work with experienced guides
                    and ensure secure accommodations.
                    <br></br>
                    <br></br>
                    <b>2 - Customizable Itineraries:</b> Tailor the program to
                    suit your curriculum and specific educational goals. We work
                    closely with educators to create a meaningful and relevant
                    experience for students.
                    <br></br>
                    <br></br>
                    <b>3 - Expert Guides:</b> Knowledgeable and experienced
                    guides accompany the group, providing valuable insights and
                    context to enhance the educational experience.
                    <br></br>
                    <br></br>
                    <b>4 - 24/7 Support:</b> Our dedicated team is available
                    around the clock to address any concerns and ensure a smooth
                    and enjoyable experience for both students and educators.
                    <br></br>
                    <br></br>
                    <b>5 – Campus Exploration:</b> Immerse your students in the
                    vibrant atmosphere of renowned universities and institutions
                    in Paris. From cutting-edge facilities to historic
                    libraries, this tour offers a first-hand look at the
                    academic environment.
                    <br></br>
                    <br></br>
                    <b>6 - Interactive Workshops:</b> Engage in interactive
                    sessions and workshops led by esteemed faculty members.
                    Students will have the chance to delve into specific fields
                    of study, gaining insights into the latest research and
                    advancements.
                    <br></br>
                    <br></br>
                    <b>7 - Networking Opportunities:</b> Foster connections with
                    faculty, students, and professionals in the field.
                    Networking events provide a platform for students to expand
                    their social and professional circles.
                    <br></br>
                    <br></br>
                    <b>8 - Cultural and Historical Exploration:</b> Beyond
                    academic pursuits, the tour includes visits to local
                    cultural and historical landmarks. This ensures a holistic
                    experience, combining education with a broader understanding
                    of the destination.
                  </p>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="container">
              <div className="sidebarItem">
                <div className="sideImg">
                  <img src="images/gallery/Paris-Tour-01-Nov-2023/IMG_1672.jpg" />
                </div>
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Where </span>
                    <span className="liteText">&nbsp;Was Our Trip</span>
                  </div>
                </div>
                <img
                  src="images/educational-tours/international/Paris_tour/paris-world-map.png"
                  style={{ width: "60%", marginLeft: "75px" }}
                />
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    fontFamily: "monospace",
                  }}
                >
                  Paris, France
                </p>
              </div>
              <div className="sidebarItem">
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Instagram</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzvyX26S89u/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic1.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzuzrFJSKuU/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic2.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzyP57kSsVq/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic3.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/Czuw0EkyN4-/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic4.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Experience</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <img
                      src={image01}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src={image02}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src={image03}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Paris_tour/IMG_2494.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src={image04}
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Paris;
