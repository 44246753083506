import React from 'react';
import SectionHeader from '../SectionHeader';
import {MdContentCopy} from "react-icons/md";
import {FaLinkedinIn, FaWhatsapp} from "react-icons/fa";
import {CgFacebook} from "react-icons/cg";
import {Link} from 'react-router-dom';
import {BsArrowRight} from 'react-icons/bs';

import OwlCarousel from 'react-owl-carousel';
import BlogsCarouselCard from './BlogsCarouselCard';


const educarouselOptions = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    loop:true,
    autoplay:false,
    autoplayTimeout:12000, autoplaySpeed:6000,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        425: {
            items: 1,
            stagePadding:30
        },
        600: {
            items: 3,
        },
        768: {
            items: 1,
            margin:30,
            stagePadding:180
        },
        1024: {
            items: 3,

        },
        1200: {
            items: 4,

        },
        1920: {
            items: 5,

        }
    }
};

function BlogsCarousel() {
    // const imgsource = [
    //     {
    //         src:"images/places/blog-paris.webp",
    //         bloghead:"All the way to Paris Live Virtually!",
    //         blogcontent:"Ever wondered about the possibilities of travelling in 2020? Well, nobody did! But what nobody imagined, Memories Maker have done it When most of us were affected by the Global Pandemic...",
    //         date: "20 Oct. 2021",
    //         link:"/lourve"
    //     },
    //     {
    //         src:"images/places/blog-eiffel.jpg",
    //         bloghead:"Why Travelling is an essential part of a Balanced Education?",
    //         blogcontent:"Nothing teaches you more than exploring the world and accumulating experiences. Travelling plays an important role in our education to make it balanced and innovative at the same time...",
    //         date: "1 Sept. 2022",
    //         link:"/singaporeblog"
    //     }
    // ];

  return (
      <>
        
        <div className='customContainer' id='ourBlogs'>
        <SectionHeader firstTxt='OUR' lastTxt="BLOGS" />
           <div>
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-6">
                                <div className="cardBlog cardBlog1" style={{backgroundImage:`url(${'images/places/blog-paris.webp'})`}}>
                                    <div className="shareBtns pr-1 pl-1" style={{padding:'10px'}}>
                                        <div>
                                        <b className="pt-1 d-block">20 Oct. 2021</b>
                                        </div>
                                        <div>
                                        
                                        <span className="copyIconBox">
                                            <a className="copyIcon" href="javascript:void(0)">
                                                <MdContentCopy/>
                                            </a>
                                            </span>
                                                <a target="blank" href="https://www.linkedin.com/sharing/share-offsite/?url=memoriesmakeredu.com/all-the-way-to-paris-live-virtually.php">
                                                    <FaLinkedinIn/>
                                                </a>
                                                
                                                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmemoriesmakeredu.com/all-the-way-to-paris-live-virtually.php%2F&amp;src=sdkpreparse" className="btn facebook fb-xfbml-parse-ignore">
                                                    <CgFacebook/>
                                                </a>
                                                
                                                <a target="_blank" href="https://web.whatsapp.com/send?text=https://memoriesmakeredu.com/all-the-way-to-paris-live-virtually.php" data-action="share/whatsapp/share" className="btn whatsapp webWhatsapp">
                                                    <FaWhatsapp/>
                                                </a>
                                        </div>
                                    </div>
                                    <div className="blogTxt">                
                                        <div className="blogHead">
                                        <h3>All the way to Paris Live Virtually!</h3>
                                        </div>
                                        <div className="postCardDesc">
                                        Ever wondered about the possibilities of travelling in 2020? Well, nobody did! But what nobody imagined, Memories Maker have done it When most of us were affected by the Global Pandemic...
                                        </div>
                                        <div className="readmorelink">
                                        <Link to="/blog_1">Read Full Blog</Link>
                                       {/* <BsArrowRight className='rightIcon'/> */}
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                
                                <div className="col-md-6">
                                <div className="cardBlog cardBlog1" style={{backgroundImage:`url(${'images/places/travel.jpg'})`}}>
                                    <div className="shareBtns pr-1 pl-1" style={{padding:'10px'}}>
                                        <div>
                                        <b className="pt-1 d-block">1 Sept. 2022</b>
                                        </div>
                                        <div>
                                        
                                        <span className="copyIconBox">
                                            <a className="copyIcon" href="javascript:void(0)">
                                                <MdContentCopy/>
                                            </a>
                                            </span>
                                                <a target="blank" href="https://www.linkedin.com/sharing/share-offsite/?url=memoriesmakeredu.com/all-the-way-to-paris-live-virtually.php">
                                                    <FaLinkedinIn/>
                                                </a>
                                                
                                                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmemoriesmakeredu.com/all-the-way-to-paris-live-virtually.php%2F&amp;src=sdkpreparse" className="btn facebook fb-xfbml-parse-ignore">
                                                    <CgFacebook/>
                                                </a>
                                                
                                                <a target="_blank" href="https://web.whatsapp.com/send?text=https://memoriesmakeredu.com/all-the-way-to-paris-live-virtually.php" data-action="share/whatsapp/share" className="btn whatsapp webWhatsapp">
                                                    <FaWhatsapp/>
                                                </a>
                                        </div>
                                    </div>
                                    <div className="blogTxt">                
                                        <div className="blogHead">
                                        <h3>Why Travelling is an essential part of a Balanced Education?</h3>
                                        </div>
                                        <div className="postCardDesc">
                                        Nothing teaches you more than exploring the world and accumulating experiences. Travelling plays an important role in our education to make it balanced and innovative at the same time...
                                        </div>
                                        <div className="readmorelink">
                                        <Link to="/blog_2">Read Full Blog</Link>
                                       {/* <BsArrowRight className='rightIcon'/> */}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                    </div>
                </div>
           </div>
        </div>
      </>
  )
}

export default BlogsCarousel;