import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const BanaHills = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner_bana">
          <div className="headerWrapper">
            <div className="bannerBlogHeader_goa">
              <span>Ba Na Hills: Da Nang's</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">09 Nov. 2023</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/banahills/bana-main.jpg"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                Ba Na Hills: Da Nang's Enchanted Escapade to the Sky
              </h1>
              <p>
                Perched like a mystical haven atop the Truong Son Mountains, Ba
                Na Hills in Da Nang emerges as a captivating escape that
                transcends the ordinary. A realm where fantasy meets reality,
                this mountain retreat promises an otherworldly experience that
                leaves visitors spellbound. Join us on a journey to explore the
                enchanting allure of Ba Na Hills, a place where nature, culture,
                and amusement converge in perfect harmony.
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>Ascending to the Heavens: The Iconic Golden Bridge</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/banahills/golden-bridge.jpg"
                      alt="Image"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    As you ascend the lush mountainside, a surreal landscape
                    unveils itself, and the iconic Golden Bridge appears like a
                    thread suspended between heaven and earth. Held aloft by
                    colossal stone hands, this architectural marvel not only
                    provides breath taking panoramic views of the surrounding
                    mountains but also invites visitors into a whimsical realm.
                    Stroll along the walkway, which seemingly floats among the
                    clouds, and capture moments that defy imagination against
                    the backdrop of the lush greenery.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>French Village: A Nostalgic Journey through Time</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-5">
                    Nestled within the misty peaks of Ba Na Hills lies the
                    charming French Village, a nostalgic homage to the bygone
                    era of colonial Indochina. Cobblestone streets, quaint
                    European-style architecture, and vibrant flower gardens
                    transport visitors to the heart of a French countryside
                    retreat. Cafés and boutiques exude a European charm,
                    offering a delightful blend of Vietnamese warmth and French
                    sophistication.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/banahills/French-Village.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Fantasy Park: Amusement Amidst Nature's Splendor</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/banahills/fantasy-park.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Ba Na Hills' allure extends beyond its natural beauty and
                    cultural echoes. The Fantasy Park, nestled within the
                    mountainous embrace, beckons adventure-seekers with a myriad
                    of thrilling rides and entertainment. From roller coasters
                    to arcade games, the park adds a touch of excitement to the
                    serene surroundings, making Ba Na Hills a destination for
                    both nature enthusiasts and thrill-seekers alike.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Deities and Devotion: Linh Ung Pagoda</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    The spiritual ambiance of Ba Na Hills is encapsulated in the
                    Linh Ung Pagoda, a sanctuary of serenity overlooking the
                    mountainous landscape. The towering statue of the Goddess of
                    Mercy (Quan Am) stands as a symbol of compassion, blessing
                    the land and those who visit. The pagoda's architecture,
                    adorned with intricate details and vibrant colors, adds a
                    spiritual dimension to the scenic beauty that surrounds it.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/banahills/Linh-Ung-Pagoda.webp"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>The Funicular Journey and Culinary Delights:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/banahills/culinary-delights.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Reaching Ba Na Hills involves a journey via the world's
                    longest non-stop single track cable car, an adventure in
                    itself offering breath taking views of the landscape. Once
                    at the summit, a diverse culinary experience awaits, ranging
                    from local delicacies to international cuisines, allowing
                    visitors to savour the flavours of both the region and the
                    world.
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <img
                    src="/images/places/banahills/bana-lable.jpg"
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <br></br>
                  <br></br>
                  <p>
                    Ba Na Hills in Da Nang is not just a destination; it's an
                    ethereal journey through landscapes that blur the lines
                    between reality and fantasy. From the iconic Golden Bridge
                    to the nostalgic French Village, the spiritual sanctity of
                    Linh Ung Pagoda to the exhilarating Fantasy Park, every
                    facet of this mountain retreat contributes to an experience
                    that lingers in the heart and mind. Ba Na Hills stands as a
                    testament to Da Nang's ability to seamlessly blend nature's
                    wonders with cultural richness, offering a transcendent
                    escape for those who seek the extraordinary.
                  </p>
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default BanaHills;
