const GalleryImgModal = ({clickedImg, handleRotationRight, setClickedImg}) => {
    const handleClick = (e) => {
        if(e.target.classList.contains('dismiss')){
            setClickedImg(null);
        }
    }

    return<>
        <div className="OverLay dismiss" onClick={handleClick}>
            <img src={clickedImg} alt='bigger picture'/>
            {/* <span className="dismiss" onClick={handleClick}>X</span>
            <div onClick={handleRotationRight} className='overLay-arrow__left'></div> */}
        </div>
    </>
};

export default GalleryImgModal;