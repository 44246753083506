import React from 'react'
import BannerSection from './BannerSection'
import Header from './Header'
import {Link} from 'react-router-dom';
function Privacypolicy() {
  return (
    <>
    <Header/>
    <BannerSection pageHeader="Privacy Policy" />

    <div style={{display: 'flex', justifyContent:'space-between'}}>
    <div className="documentsTabsBox" style={{minWidth:'20%'}}>
        <ul className="documentsTabs" style={{position:'sticky', top:'150px'}}>
            <li><Link style={{color:'#2c3e50'}} to="/terms_and_conditions">Terms &amp; Conditions</Link></li>
            <li><Link to="/privacy_policy" style={{color:'#2c3e50'}}>Privacy Policy</Link></li>
        </ul>
    </div>

    <div className="conditionsListContainer">
    <div className="innerDiv">
    <ul className="conditionsList">
            <li>
            <p style={{color:'rgba(0, 0, 0, .4)', fontWeight:'bold', textAlign:'right', marginBottom:'15px', fontSize: '14px'}}>Last Updated Date: 29-12-2021</p>
                <p>
                    Memories Maker Edu Pvt. Ltd. respects your privacy and recognizes the need to safeguard all information which is of personal nature. Hence, we request all our users to read the ‘privacy policy’ to understand how your personal and business information shared for travel services will be treated. Appropriate standards are followed to protect privacy of the customers’ information shared on the website.
                </p>
                <p>
                    Some of our web pages use "cookies" so that we can better serve you with customized information when you return to our site. Cookies are identifiers that a website can send to your browser to keep on your computer to facilitate your next visit to our site. You can set your browser to notify you when you are sent a cookie, giving you the option to decide whether to accept it. The information we collect and analyze is used to improve our service to you.
                </p>
                <p>
                    We automatically collect IP addresses and Web site usage information when you visit our website. This information helps us evaluate how our visitors and customers use and navigate our website on an aggregate basis, including the number and frequency of visitors and customers to each Web page, and the length of their visits.
                </p>
                <p>
                    We may use the collected information in the following ways:
                </p>
                <ul type="disc">
                    <li>For the purposes for which you specifically provided the information.</li>
                    <li>
                        To send you e-mail notifications about our new or existing products and services, special offers, or to otherwise contact you.
                    </li>
                    <li>
                        To enhance existing features or develop new features, products, and services.
                    </li>
                    <li>
                        To allow us to personalize the content and advertising that you and others see based on personal characteristics or preferences.
                    </li>
                    <li>
                        We may disclose and use personally identifiable information in special circumstances where it is necessary to enforce our Terms of Use. We may also disclose or use your personal information when we, in good faith, believe that the law requires us to do so.
                    </li>
                </ul>
                <p>
                    Our website may contain links to other sites. Memories Maker is not responsible for the privacy practices or the content of such web sites. We strongly encourage you to read the privacy statements of any linked sites as their Privacy Policy may differ from ours.
                </p>
                <p>
                    We will take appropriate steps to protect the information you share with us. We have implemented technology and security features and strict policy guidelines to safeguard the privacy of your personally identifiable information from unauthorized access and improper use or disclosure. Memories Maker will continue to enhance our security procedures as new technology becomes available.
                </p>
                <p>
                    Should you send us questions via the contact form, we will collect the data entered on the form, including the contact details you provide, to answer your question and any follow-up questions. We do not share this information without your permission. We will, therefore, process any data you enter onto the contact form only with your consent.
                </p>
                <p>
                    If our privacy policy changes in the future, it will be posted here, and a new effective date will be shown. You should access our privacy policy regularly to ensure you understand our current policies. If you have any questions, comments, or concerns about our Privacy Policy, please <a href="https://memoriesmakeredu.com/contact">contact us</a> and we shall get back to you in the soonest time possible.
                </p>
            </li>

            <li>
                <p>Thank you for using <a href="https://memoriesmakeredu.com">memoriesmakeredu.com</a></p>
            </li>
        </ul>
    </div>
</div>
   
</div>
    </>
  )
}

export default Privacypolicy