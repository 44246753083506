import React from 'react';
import {Container, Grid} from '@mui/material';
import OwlCarousel from 'react-owl-carousel';
import {IoCalendar, IoFastFoodOutline, IoCheckmarkCircleSharp} from 'react-icons/io5';
import {IoIosIceCream} from 'react-icons/io';
import {MdOutlineMapsHomeWork, MdDinnerDining, MdLunchDining, MdTrain, MdOutlineTram, MdHiking, MdOutlineFlight} from 'react-icons/md';
import {FaIdCard, FaBed, FaBus, FaUserTie} from 'react-icons/fa';
import {GiHotMeal} from 'react-icons/gi';
import {RiShieldCrossFill} from 'react-icons/ri';
import {useParams} from 'react-router-dom';

const carouselOptions = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,

        }
    },
};

function ItinerarySection(props) {
    const {place} = useParams();
    let itineraryData;
    if(place=="paris"){
        itineraryData = {
            carousel:[
             "https://memoriesmakeredu.com/images/gallery/parisPhotos/paris-tour-15.jpg",
             "https://memoriesmakeredu.com/images/gallery/parisPhotos/paris-tour-016.jpg",
             "https://memoriesmakeredu.com/images/gallery/parisPhotos/paris-tour-04.jpg"
            ],
            
            tourDescription : "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
              "https://memoriesmakeredu.com/images/itinerary/paris/eiffil-tower-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris/arc-de-triomphe-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris/louvre-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris/concorde-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris/versailles-paris.jpg",
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Eiffel Tower', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Arc De Triomphe', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Louvre Tour', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Concorde Tour', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Versailles Tour', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }else if(place=="singapore"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/singapore/universal studios.jpg",
              "https://memoriesmakeredu.com/images/itinerary/singapore/singapore-bg.jpg",
              "https://memoriesmakeredu.com/images/itinerary/singapore/mosque.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/singapore/garden-by-the-bay.jpg",
                "https://memoriesmakeredu.com/images/itinerary/singapore/singapore-university.jpg",
                "https://memoriesmakeredu.com/images/itinerary/singapore/universal studios.jpg",
                "https://memoriesmakeredu.com/images/itinerary/singapore/santosa-island.jpg",
                "https://memoriesmakeredu.com/images/itinerary/singapore/cloud-forest.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Singapore City Tour', 'Lunch At the Resturant', 'Garden By the Bay', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Singapore University', 'Lunch At the Resturant', 'Walking Evening Tour With Dinner'],
                ['Breakfast at the Hotel', 'Full Day at Universal Studios', 'Lunch in US', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Full Day at Santosa Island', 'Lunch in SI', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Singapore Zoo+Night Safari', 'Lunch At the Resturant', 'Dinner At the Resturant'],
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="dubai"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/dubai/burj-al-arab.jpg",
              "https://memoriesmakeredu.com/images/itinerary/dubai/palm-jumeirah.jpg",
              "https://memoriesmakeredu.com/images/itinerary/dubai/atlantis.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/dubai/burj-khalifa.jpg",
                "https://memoriesmakeredu.com/images/itinerary/dubai/marina.jpg",
                "https://memoriesmakeredu.com/images/itinerary/dubai/palm-jumeirah.jpg",
                "https://memoriesmakeredu.com/images/itinerary/dubai/atlantis.jpg",
                "https://memoriesmakeredu.com/images/itinerary/dubai/burj-al-arab.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Burj Khalifa', 'Lunch At the Resturant', 'Garden By the Bay', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Marina', 'Lunch At the Resturant', 'City Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Palm Jumeirah', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Atlantis', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Burj Al Arab', 'Lunch At the Resturant', 'Dinner At the Resturant'],
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }
    else if(place=="paris-italy-spain"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/paris-italy-spain/Eiffil-Tower.webp",
              "https://memoriesmakeredu.com/images/itinerary/paris-italy-spain/colosseum-rome-itely.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris-italy-spain/royal-palace-madrid-spain.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/paris-italy-spain/eiffil-tower-paris.jpg",
                "https://memoriesmakeredu.com/images/itinerary/paris-italy-spain/arc-de-triomphe-paris.jpg",
                "https://memoriesmakeredu.com/images/itinerary/paris-italy-spain/leining-tower-itely.jpg",
                "https://memoriesmakeredu.com/images/itinerary/paris-italy-spain/colosseum-rome-itely.jpg",
                "https://memoriesmakeredu.com/images/itinerary/paris-italy-spain/royal-palace-madrid-spain.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Eiffil Tower', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Arc de Triomphe', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Leining Tower', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Colosseum', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Royal Palace', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant']
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="belgium-paris-amsterdam"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/belgium-paris-amsterdam/belgium.jpg",
              "https://memoriesmakeredu.com/images/itinerary/belgium-paris-amsterdam/Eiffil-Tower.webp",
              "https://memoriesmakeredu.com/images/itinerary/belgium-paris-amsterdam/amsterdam.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/belgium-paris-amsterdam/eiffil-tower-paris.jpg",
                "https://memoriesmakeredu.com/images/itinerary/belgium-paris-amsterdam/arc-de-triomphe-paris.jpg",
                "https://memoriesmakeredu.com/images/itinerary/belgium-paris-amsterdam/belgium.jpg",
                "https://memoriesmakeredu.com/images/itinerary/belgium-paris-amsterdam/Atomium.jpg",
                "https://memoriesmakeredu.com/images/itinerary/belgium-paris-amsterdam/amsterdam.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Eiffil Tower', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Arc de Triomphe', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Ghent', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Atomium Tour', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'City Tour', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="switzerland-paris-germany"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/switzerland-snow.jpg",
              "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/concorde-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/switzerland-hills.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/eiffil-tower-paris.jpg",
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/switzerland-hill.jpg",
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/switzerland-snow.jpg",
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/cologue-germany.jpg",
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/lubeck-germany.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Eiffil Tower', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'City Tour', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Switzerland Snow', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Cologue', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Lubeck', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="delhi-darshan"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/delhi-darshan/red-fort.jpg",
              "https://memoriesmakeredu.com/images/itinerary/delhi-darshan/jantar-mantar.jpg",
              "https://memoriesmakeredu.com/images/itinerary/delhi-darshan/india-gate.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/delhi-darshan/qutub-minar.jpg",
                "https://memoriesmakeredu.com/images/itinerary/delhi-darshan/red-fort.jpg",
                "https://memoriesmakeredu.com/images/itinerary/delhi-darshan/lotus-temple.jpg",
                "https://memoriesmakeredu.com/images/itinerary/delhi-darshan/india-gate.jpg",
                "https://memoriesmakeredu.com/images/itinerary/delhi-darshan/jantar-mantar.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Qutub Minar', 'Lunch At the Resturant', 'City Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Red Fort', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Lotus Temple', 'Lunch At the Resturant', 'City Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'India Gate', 'Lunch At the Resturant', 'Rashtrapati Bhavan', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Jantar Mantar', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="dehradun-mussoorie"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/dehradun-mussoorie/mindrolling-monestery.jpg",
              "https://memoriesmakeredu.com/images/itinerary/dehradun-mussoorie/lal-tibba-scenic-mussoori.jpg",
              "https://memoriesmakeredu.com/images/itinerary/dehradun-mussoorie/tiger-fall.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/dehradun-mussoorie/lal-tibba-scenic-mussoori.jpg",
                "https://memoriesmakeredu.com/images/itinerary/dehradun-mussoorie/mindrolling-monestery.jpg",
                "https://memoriesmakeredu.com/images/itinerary/dehradun-mussoorie/tiger-fall.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Lal Tibba Scenic', 'Lunch At the Resturant', 'City Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'mindrolling Tour', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Tiger Fall', 'Lunch At the Resturant', 'City Tour', 'Dinner At the Resturant']
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="rishikesh"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/rishikesh/triveni-ghat.jpg",
              "https://memoriesmakeredu.com/images/itinerary/rishikesh/parmarth-niketan.jpg",
              "https://memoriesmakeredu.com/images/itinerary/rishikesh/rishikesh-rafting.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/rishikesh/lakshman-jhula.jpg",
                "https://memoriesmakeredu.com/images/itinerary/rishikesh/parmarth-niketan.jpg",
                "https://memoriesmakeredu.com/images/itinerary/rishikesh/triveni-ghat.jpg",
                "https://memoriesmakeredu.com/images/itinerary/rishikesh/rishikesh-rafting.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Lakshman Jhula', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Parmarth Niketan', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'City Tour', 'Lunch At the Resturant', 'Triveni Ghat', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Rafting', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant']
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="jaipur-jodhpur"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/hawa-mahal.jpg",
              "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/amber-palace.jpg",
              "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/nahargarh-fort.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/hawa-mahal.jpg",
                "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/amber-palace.jpg",
                "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/jal-mahal.jpg",
                "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/nahargarh-fort.jpg",
                "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/jaswant-thada.jpg",
                "https://memoriesmakeredu.com/images/itinerary/jaipur-jodhpur/jodhpur-city.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Hawa Mahal', 'Lunch At the Resturant', 'City Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Amber Palace', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Jal Mahal', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Nahargarh Fort', 'Lunch At the Resturant', 'City Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Jaswant Thada', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'City Tour', 'Lunch At the Resturant', 'Dinner At the Resturant']
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="adventure-camps"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/adventure-camps/rafting.jpg",
              "https://memoriesmakeredu.com/images/itinerary/adventure-camps/cliff-jumping.jpg",
              "https://memoriesmakeredu.com/images/itinerary/adventure-camps/paragliding.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/adventure-camps/rafting.jpg",
                "https://memoriesmakeredu.com/images/itinerary/adventure-camps/surfing.jpg",
                "https://memoriesmakeredu.com/images/itinerary/adventure-camps/paragliding-sunset.jpg",
                "https://memoriesmakeredu.com/images/itinerary/adventure-camps/cliff-jumping.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Rafting', 'Lunch At the Resturant', 'City Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Surfing', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Paragliding', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Cliff Jumping', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant']
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="agra"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/agra/taj-mahal2.jpg",
              "https://memoriesmakeredu.com/images/itinerary/agra/beauty-of-agra-fort.jpg",
              "https://memoriesmakeredu.com/images/itinerary/agra/jama-masjid-agra.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/agra/taj-mahal.jpg",
                "https://memoriesmakeredu.com/images/itinerary/agra/beauty-of-agra-fort.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Taj Mahal', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Agra Fort', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant']
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }

    else if(place=="walking-tours"){
        itineraryData = {
            carousel:[
              "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/switzerland-snow.jpg",
              "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/concorde-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/switzerland-hills.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/eiffil-tower-paris.jpg",
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/switzerland-hill.jpg",
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/switzerland-snow.jpg",
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/cologue-germany.jpg",
                "https://memoriesmakeredu.com/images/itinerary/switzerland-paris-germany/lubeck-germany.jpg"
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Eiffil Tower', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'City Tour', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Switzerland Snow', 'Lunch At the Resturant', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Cologue', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                ['Breakfast at the Hotel', 'Lubeck', 'Lunch At the Resturant', 'Evening Walking Tour', 'Dinner At the Resturant'],
                
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
        
    }
    
    else{
        itineraryData = {
            carousel:[
                "https://memoriesmakeredu.com/images/gallery/parisPhotos/paris-tour-15.jpg",
                "https://memoriesmakeredu.com/images/gallery/parisPhotos/paris-tour-15.jpg",
                "https://memoriesmakeredu.com/images/gallery/parisPhotos/paris-tour-15.jpg"
            ],
            
            tourDescription : "This tentative itinerary for "+place+" allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods. It is the city of fashion, Napoleon's tomb and 3rd-century Roman baths. In Paris, students can discover the monuments, culture once frequented by some of the world's best writers, incredible art collections – and food markets playing host to daily shoppers and local vendors selling everything from crêpe to Camembert cheese.",
            
            itineraryDaysImg:[
              "https://memoriesmakeredu.com/images/itinerary/paris/eiffil-tower-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris/eiffil-tower-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris/eiffil-tower-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris/eiffil-tower-paris.jpg",
              "https://memoriesmakeredu.com/images/itinerary/paris/eiffil-tower-paris.jpg",
            ],
            itineraryTodoList:[
                ['Breakfast at the Hotel', 'Eiffel Tower', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Eiffel Tower', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Eiffel Tower', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Eiffel Tower', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
                ['Breakfast at the Hotel', 'Eiffel Tower', 'Lunch At the Resturant', 'Dinner At the Resturant', 'Transfer to Hotel'],
            ],
            todoListDiscription:[
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
                "This tentative itinerary allows students to experience the European capital city and its respective charm. Often referred to as 'The City of Light,' Paris is an energetic, cosmopolitan capital city that somehow maintains a provincial ambience in some of its loveliest neighbourhoods.",
            ]

        }
     

    }

  return (
    <Container maxWidth="xxl">
        <div className="itinerarySection">
        <div className="tourName">
            <div className="placeName">
                <span>{place} - Tour</span>
            </div>
            <h3><span>Explore Paris with Experiential Learning Activities.</span></h3>
            <div className="tourDetails">
                <span className="days">{itineraryData.itineraryDaysImg.length} Days</span>
                <span className="package">Student Package</span>
            </div>
        </div>
        </div>

        <div className="itinerarayGalleryCarousel">
            <OwlCarousel className='owl-theme' {...carouselOptions}>
                
            {
                itineraryData.carousel.map((val)=>{
                    return(
                        <div className="item">                    
                            <div className='imgBox'>
                                <img src={val} alt="Educational Tour" />
                            </div>                    
                        </div>
                    )
                })
            }
            </OwlCarousel>
        </div>

        <div className="tourDescription">
            <h2 style={{marginTop:'20px'}}><b><span style={{color:'#fc4349'}}>Tour</span> <span style={{color:'#2c3e50'}}>Description</span></b></h2>
            {itineraryData.tourDescription}
        </div>

        <Grid container style={{marginTop:'50px'}}>
            <Grid item xl={2} sx={{display:{xs:'none', md:'block'}}}>
            <div className="daysNavigationBox">
            <div className="innerDiv">
                <p className="dayHeader"><IoCalendar/> DAY PLAN</p>
                  <ul className="tourDateNavigations" style={{border: 'none'}} id="nav-tab">
                    {
                        itineraryData.itineraryDaysImg.map((v, i)=>{
                            return(
                                <li>
                                    <a className="" id={`day${i+1}-tab`} href={`#day${i+1}`}>Day <span className="dayCount">{i+1}</span></a>
                                </li>
                            )
                        })
                    }
                  </ul>
            </div>
        </div>
            </Grid>
            <Grid item xl={7} xs={12}>
                    {itineraryData.itineraryDaysImg.map((imgVal, i)=>{
                        return(
                            <div className="" id={`day${i}`} name="day1">
                                <div className="innerDiv">
                                    <div className="dayHeaderBox">
                                        <div>
                                            <span className="dayOneHeaderTxt">Day {i+1} - <span className="place">{place}</span></span>
                                        </div>                                
                                    </div>
                                    <div className="dayContent">
                                        <Grid container spacing={3}>
                                            <Grid item xl={5}>
                                                <div className="eachDayVanueImgBox">
                                                <img src={imgVal} alt="" style={{width:'100%'}} />
                                                </div>
                                            </Grid>
                                            <Grid item xl={7}>
                                                <div className="todoList">
                                                    <div className="todoListHeader">
                                                        <span>Itinerary</span>
                                                    </div>
                                                    <ul>
                                                        {                                                
                                                            itineraryData.itineraryTodoList[i].map((val)=>{return(
                                                                <li><IoCheckmarkCircleSharp className='checkCircle'/>&nbsp;{val}</li>
                                                            )})                                                
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="todoList">
                                                    <h5><b><span style={{color:'#fc4349'}}>Description</span></b></h5>
                                                    <p style={{fontSize: '14px'}}>
                                                        {itineraryData.todoListDiscription[i]}
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>                                
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </Grid>
            <Grid item xl={3} xs={12}>
            <div className="inclusiveCard inclusiveCardOne">
                <div className="inclusiveHeader">
                    <span>Inclusions</span>
                </div>
                <div className="inclusiveContent" style={{display: 'flex', justifyContent: 'space-around'}}>
                    <ul>
                        <li><MdOutlineMapsHomeWork/> Accommodation</li>
                        <li>
                        <MdOutlineFlight/> Flights</li>
                        <li><FaIdCard/> Visa</li>
                        <li><GiHotMeal/> Meals</li>
                    </ul>
                    <ul>
                        <li><MdHiking/> Activities</li>
                        <li><FaBus/> Transportation</li>
                        <li><FaUserTie/> Tour Manager</li>                        
                        <li><RiShieldCrossFill/> Travel Insurance</li>
                    </ul>
                </div>
            </div>

            <div className="inclusiveCardTwoWrapper">
                <div className="inclusiveCardTwo inclusiveCard">
                    <div className="inclusiveHeader">
                        <span>Transportation</span>
                    </div>
                    <div className="inclusiveContent">
                        <ul style={{paddingLeft: '15px'}}>
                            <li><MdOutlineFlight/> Flights</li>
                            <li><FaBed/> Luxury Coach</li>
                            <li><MdOutlineTram/> Tram</li>
                            <li><MdTrain/> Metro</li>
                            
                        </ul>
                    </div>
                </div>

                <div className="inclusiveCardThree inclusiveCard">
                    <div className="inclusiveHeader">
                        <span>Meals</span>
                    </div>
                    <div className="inclusiveContent">
                        <ul style={{paddingLeft: '15px'}}>
                            <li><IoFastFoodOutline/> Breakfast</li>
                            <li><MdLunchDining/> Lunch</li>
                            <li><IoIosIceCream/> Evening Snacks</li>
                            <li><MdDinnerDining/> Dinner</li>
                        </ul>
                    </div>
                </div>  
            </div> 

            <div className="inclusiveCard inclusiveCardFour">
                <div className="inclusiveHeader">
                    <span>Highlights</span>
                </div>
                <div className="inclusiveContent" style={{display: 'flex', justifyContent: 'space-around'}}>
                    <ul>
                        <li><MdOutlineMapsHomeWork/> Accommodation</li>
                        <li><MdOutlineFlight/> Flights</li>
                        <li><FaIdCard/> Visa</li>
                        <li><GiHotMeal/> Meals</li>
                    </ul>
                    <ul>
                        <li><MdHiking/> Activities</li>
                        <li><FaBus/> Transportation</li>
                        <li><FaUserTie/> Tour Manager</li>                        
                        <li><RiShieldCrossFill/> Travel Insurance</li>
                    </ul>
                </div>
            </div>

            <div className="inclusiveCard inclusiveCardFive">
                <div className="inclusiveHeader">
                    <span>Exclusions</span>
                </div>
                <div className="inclusiveContent" style={{display: 'flex', justifyContent: 'space-around'}}>
                    <ul>
                        <li><MdOutlineMapsHomeWork/> Accommodation</li>
                        <li><MdOutlineFlight/> Flights</li>
                        <li><FaIdCard/> Visa</li>
                        <li><GiHotMeal/> Meals</li>
                    </ul>
                    <ul>
                        <li><MdHiking/> Activities</li>
                        <li><FaBus/> Transportation</li>
                        <li><FaUserTie/> Tour Manager</li>                        
                        <li><RiShieldCrossFill/> Travel Insurance</li>
                    </ul>
                </div>
            </div>
            </Grid>
        </Grid>
    </Container>   
  )
}

export default ItinerarySection;