import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const DubaiBlog = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner_dubai">
          <div className="headerWrapper">
            <div className="bannerBlogHeader_dubai">
              <span>Exploring the Marvels of Dubai</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">28 July 2023</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/dubai/main-dubai.jpg"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                A modern oasis in the Desert
              </h1>
              <p>
                Dubai, a city that rose from the sand of the Arabian Desert, has
                captivated the world with its grandeur, opulence, and ambition.
                Nestled along the Persian Gulf, this global metropolis has
                transformed itself from a humble fishing village to one of the
                most sought-after destinations for travelers and investors
                alike. In this blog, we will delve into the wonders of Dubai,
                exploring its iconic landmarks, cultural richness, and the
                fusion of tradition with modernity that makes it a unique oasis
                in the desert.
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>Dubai, A symbol of Progress:</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/dubai/dubai_1.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Dubai’s history can be traced back to ancient times when it
                    was a small fishing and trading village. Over the centuries,
                    it evolved into a bustling pot city, attracting traders from
                    various corners of the world. In the 20th century, the
                    discovery of oil transformed Dubai into a prosperous
                    Emirate, setting the stage for the city’s rapid development
                    and economic growth.
                    <br></br>
                    <br></br>
                    Dubai’s transformation into a modern marvel has been nothing
                    short of extraordinary. The visionary leadership and
                    forward-thinking policies of the ruling Al Maktoum family
                    has been instrumental in turning this sleeping town into a
                    bustling global town. Skyscrapers touch the skies,
                    architectural wonders redefine possibility, and cutting-edge
                    technologies drive the future here. The city is a living
                    testament to what determination and vision can achieve.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Iconic Landmarks:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    No discussion about Dubai is complete without mentioning its
                    awe-inspiring skyline. Dubai boasts an array of iconic
                    landmarks that are instantly recognizable worldwide. The
                    Burj Khalifa, the tallest building on the planet, pierces
                    the heaven with its 828 meters of architectural brilliance.
                    Visitors can ascend to the observation deck on the 125th
                    floor for breathtaking views of the city below.
                    <br></br>
                    <br></br>
                    The Palm Jumeirah, an artificial island shaped like a palm
                    tree, is a marvel of engineering and serves as home to
                    luxurious resorts, upscale residencies, and an array of
                    entertainment options. The Dubai Mall, the largest mall in
                    the world, offers an unparalleled shopping experience,
                    complete with an indoor aquarium and an ice-skating rink.
                    The Museum of the Future is an epitome of creativity which
                    is an exhibition space for innovative and futuristic
                    ideologies, services, and products. It has three main
                    elements which are green hill, building, and void. Its goal
                    is to promote technological development and innovation,
                    especially in the fields of robotics and artificial
                    intelligence.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/dubai/iconic_landmarks.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4 mt-4">
                    <strong>
                      <u>Cultural Heritage:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/dubai/cultural_heritage.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Amidst the glittering skyscrapers and modern architecture,
                    Dubai remains deeply connected to its cultural roots. The Al
                    Fahidi Historical Neighborhood, with its narrow alleys and
                    traditional wind towers, offers a glimpse into Dubai’s past.
                    The Dubai museum, located within the Al Fahidi Fort,
                    showcases the city’s heritage and transformation.
                    <br></br>
                    <br></br>
                    Visitors can immerse themselves in the traditional souks,
                    such as the Spice Souk and the Gold Souk, where merchants
                    have been trading for centuries. The vibrant colors, exotic
                    scents, and haggling echoes in these bustling markets create
                    an unforgettable experience.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Extravagant Entertainment:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    Dubai is synonymous with extravagance, and its entertainment
                    offerings are no exception. The city hosts a wide range of
                    thrilling experiences, from desert safaris where visitors
                    can experience dune bashing and camel riding, to indoor
                    skiing at Ski Dubai, where one can escape the desert heat
                    and hit the slopes.
                    <br></br>
                    <br></br>
                    For thrill-seekers, IMG Worlds of Adventure, one of the
                    world’s largest indoor theme parks, is a must-visit. It
                    features themed zones based on popular franchises and offers
                    heat-pounding rides and entertainment for all ages.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/dubai/extravagant_entertainment.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "80%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4 mt-4">
                    <strong>
                      <u>A Melting Pot of Cultures:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/dubai/melting_pot_culture.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Dubai is a true melting pot, attracting people from all over
                    the globe seeking opportunities, luxury, and a high-quality
                    lifestyle. Expatriates make up a significant portion of the
                    city’s population, creating a vibrate tapestry of cultures,
                    languages, and cuisines. From traditional Emirati dishes to
                    international delicacies, Dubai’s culinary scene is a food
                    lover’s paradise.
                    <br></br>
                    <br></br>
                    Dubai is a city that showcases the limitless possibilities
                    of human ambition. It embodies the fusion of tradition and
                    modernity, where ancient customs blend harmoniously with
                    cutting-edge technology and innovation. Whether you’re
                    captivated by skyscrapers that touch the heavens, intrigued
                    by the cultural heritage, or lured by the promise of
                    extravagance and entertainment, Dubai has something to offer
                    every visitor. As this modern oasis in the desert continues
                    to evolve and surprise, one thing remains certain: Dubai
                    will leave an inedible mark on the hearts of all who venture
                    there.
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <img
                    src="/images/places/dubai/skyscrapers.jpg"
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default DubaiBlog;
