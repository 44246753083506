import React from "react";
import Typed from "typed.js";
import { useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import { Button, Box } from "@mui/material";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

import { RiFacebookLine, RiTwitterFill, RiInstagramLine } from "react-icons/ri";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";
import { AiOutlineDownload } from "react-icons/ai";

const quoteCarousel = {
  responsiveClass: true,
  nav: false,
  dots: false,
  loop: true,
  autoplay: true,
  autoplayTimeout: 15000,
  items: 1,
  animateIn: "fadeIn",
  animateOut: "fadeOut",
};

const actions = [
  {
    icon: (
      <a
        style={{ color: "white", paddingTop: "5px" }}
        onClick={() => {
          const elem = document.createElement("textarea");
          elem.value =
            "https://memoriesmakeredu.com/PARIS_AND_BRUSSELS_10_DAYS.pdf";
          document.body.appendChild(elem);
          elem.select();
          document.execCommand("copy");
          document.body.removeChild(elem);
          alert("link copied");
        }}
      >
        <MdContentCopy />
      </a>
    ),
    name: "Copy",
  },
  {
    icon: (
      <a
        style={{ color: "white", verticalAlign: "middle", paddingTop: "5px" }}
        href="whatsapp://send?text=https://memoriesmakeredu.com/PARIS_AND_BRUSSELS_10_DAYS.pdf"
      >
        <FaWhatsapp />
      </a>
    ),
    name: "Whatsapp",
  },
  // { icon: <Link style={{color:'white', verticalAlign:'middle', paddingTop:'5px'}} to="/about"><RiInstagramLine /></Link>, name: 'Instagram' },
  {
    icon: (
      <a
        style={{
          color: "white",
          verticalAlign: "middle",
          textDecoration: "none",
          paddingTop: "5px",
        }}
        href="images/graphics/PARIS & BRUSSELS 10 DAYS.pdf"
        download="PARIS & BRUSSELS 10 DAYS"
      >
        <AiOutlineDownload />
      </a>
    ),
    name: "download",
  },
];

function HomeBanner() {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "STUDENTS, TRAVEL, LANGUAGE &amp; CULTURE.&nbsp;",
        "MEMORIES, BONDING, VALUE &amp; EXPERIENCE",
      ], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 3000,
      loop: true,
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <>
      <div className="homeBannerSection">
        <div className="homeBannerQuote">
          <div className="homeBannerQuote_carousel">
            <OwlCarousel {...quoteCarousel}>
              <div className="item">
                <span className="bannerQuoteHeader">We Connect</span>
              </div>
              <div className="item">
                <span className="bannerQuoteHeader">We Create</span>
              </div>
            </OwlCarousel>
          </div>
          <span className="bannerQuoteContent" ref={el}></span>
        </div>
        <img
          className="bannerImg"
          src="images/bg/banner.jpg"
          style={{ width: "100%" }}
          alt="educational tour"
        />
      </div>

      {/* <Box
        className="tourAdvertisingBox"
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <div className="tourheader">
          <span>Register Now</span>
          <span>for</span>
          <span>Educational Tours</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            textAlign: "center",
            paddingTop: "10px",
          }}
        >
          <div className="parisTourItineraryBtn">
            <SpeedDial
              ariaLabel="SpeedDial playground example"
              direction="up"
              icon={<button className="button-50">Rendez-vous France</button>}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                />
              ))}
            </SpeedDial>
          </div>
        </div>
      </Box> */}
    </>
  );
}
export default HomeBanner;
