import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const ParisBlog2 = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner">
          <div className="headerWrapper">
            <div className="bannerBlogHeader">
              <span>Eduvacation</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">1 Sept. 2022</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="images/places/travel.jpg"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <p>
                Nothing teaches you more than exploring the world and
                accumulating experiences. Travelling plays an important role in
                our education to make it balanced and innovative at the same
                time. The education of a man remains incomplete if he does not
                visit important places of the world. Reading books only provide
                half-knowledge but travelling with eyes wide open and mindfully
                give full knowledge. It impresses our mind with the vastness,
                grandeur, majesty and wonder of God's creation. People can learn
                from books, the Internet, school, but they can also learn from
                experiences. It is because of travelling that many people have
                learnt so much about the world and developed new and simple
                skills such as how to negotiate for cheaper prices at the
                market, how to cook amazing dishes, and different languages. The
                lessons that we learn from travelling help us immensely in our
                lives. They lead us to contribute to self-improvement and help
                us to become more desirable assets to companies and
                organisations. One of the biggest challenges that our young
                people face is having the courage to step outside their comfort
                zone and travelling helps them to have exposure to the outside
                world and people. And that’s why we say, “The best education you
                will ever get is through travelling.” To promote the fusion of
                Travel and Education, academic institutions like schools and
                colleges have started organising Edu Tours for their students.
                This has now become a part of their yearly curriculum. Now, we
                see many students embracing this opportunity of travelling
                abroad. This will help them become a more competent,
                knowledgeable, and confident person; and it will help them grow
                in different areas of life. Let’s collectively look at the
                bright side of conducting the Edu Tours in today’s era. The
                students learn to be independent. They learn about different
                cultures, history, nature, different languages and most
                importantly, people. The students become global citizens by
                travelling to different nations. They develop a zeal to learn
                new things and become an all-rounder. The students learn to care
                and build compassion for others. Most importantly, they learn
                about themselves. We at Memories Maker, always work around
                students and their goals when they dream about travelling to
                different places. We take care of their food, health, hygiene,
                activities, and whatnot. We always believe in making memories
                for the students. We urge all the students out there to open
                their wings and fly to different cities and countries.
              </p>
              <p>
                <span className="blogWritter">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ParisBlog2;
