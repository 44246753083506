import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const Shimla = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner_shimla">
          <div className="headerWrapper">
            <div className="bannerBlogHeader_goa">
              <span>Shimla Blog</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">28 Nov. 2023</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/shimla/shimla-main.jpg"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                Discovering Shimla: India's Enchanting Hill Station
              </h1>
              <p>
                Nestled in the picturesque state of Himachal Pradesh, Shimla
                stands as a testament to India's colonial past and natural
                beauty. This charming hill station, once the summer capital of
                British India, continues to captivate travellers with its
                stunning vistas, colonial architecture, and vibrant culture.
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>A Glimpse of History</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/shimla/02.webp"
                      alt="Image"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Shimla's history is deeply intertwined with the colonial
                    era. Originally a small village, it gained prominence when
                    the British discovered its pleasant climate and serene
                    surroundings. In the 19th century, it evolved into a hub for
                    the British Raj's summer retreats. The colonial influence is
                    palpable in the architecture of landmarks like the Viceregal
                    Lodge (now known as Rashtrapati Niwas), Gaiety Theatre, and
                    Christ Church.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Natural Splendor</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-5">
                    Beyond its historical significance, Shimla's natural beauty
                    is awe-inspiring. The surrounding hills, covered in pine,
                    oak, and rhododendron forests, offer breath taking views.
                    The Ridge, a spacious open space at the heart of the town,
                    presents panoramic vistas of the snow-capped Himalayas.
                    Visitors often indulge in leisurely strolls here, soaking in
                    the majestic scenery.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/shimla/03.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Exploring Shimla's Charms</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/shimla/04.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    The Mall Road, Shimla's bustling promenade, is a lively hub
                    for shopping and dining. From local handicrafts to
                    international brands, this street caters to diverse tastes.
                    The Gaiety Theatre, standing proudly since the late 1800s,
                    continues to host cultural events, adding a touch of
                    old-world charm.
                    <br></br>
                    <br></br>
                    Adventurers find their haven in the nearby areas of Kufri
                    and Mashobra, offering opportunities for trekking, skiing,
                    and horse riding amidst pristine landscapes.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Culinary Delights</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    Shimla's cuisine is a reflection of its diverse cultural
                    heritage. From the savory Himachali cuisine—featuring dishes
                    like Sidu, Chana Madra, and Babru—to the influence of
                    Tibetan flavors in momos and thukpa, food enthusiasts revel
                    in the variety of flavours and spices.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/shimla/05.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>The Shimla Spirit</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/shimla/06.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    What truly sets Shimla apart is its warm hospitality and the
                    cheerful spirit of its residents. Locals, known for their
                    friendliness, add a welcoming touch to the experience,
                    making visitors feel right at home.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Seasons in Shimla</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    Each season in Shimla offers a distinct experience. Summers
                    provide relief from the scorching heat prevalent in the
                    plains, while winters transform the town into a snow-laden
                    wonderland, attracting tourists seeking a snowy escape.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/shimla/07.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <img
                    src="/images/places/shimla/08.webp"
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <br></br>
                  <br></br>
                  <p>
                    Shimla's allure lies in its harmonious blend of history,
                    nature, and culture. Whether you're seeking a tranquil
                    retreat or an adventure-filled getaway, this hill station
                    has something for everyone. From its colonial heritage to
                    the majestic Himalayan landscapes, Shimla leaves an
                    indelible mark on every traveller fortunate enough to
                    explore its wonders.
                    <br></br>
                    <br></br>
                    <i>
                      A visit to Shimla is not just a trip; it's an immersive
                      journey into the heart of India's enchanting hill
                      stations, leaving visitors with memories to cherish for a
                      lifetime.
                    </i>
                  </p>
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Shimla;
