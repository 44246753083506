import React from 'react'
import OwlCarousel from 'react-owl-carousel';
const videoCarouselOptions = {
    items:3,
    margin:20,
    nav:true,
    dots:true
  }
function VideoComp() {
  return (
    <div className="videoBox webVideoBox">
          <OwlCarousel {...videoCarouselOptions}>
            <div className="item">
            <iframe width="100%" height="240" src="https://www.youtube-nocookie.com/embed/UfEiKK-iX70" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="item">
            <iframe width="100%" height="240" src="https://www.youtube-nocookie.com/embed/UfEiKK-iX70" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="item">
            <iframe width="100%" height="240" src="https://www.youtube-nocookie.com/embed/UfEiKK-iX70" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="item">
            <iframe width="100%" height="240" src="https://www.youtube-nocookie.com/embed/UfEiKK-iX70" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </OwlCarousel>        
        </div>
  )
}

export default VideoComp