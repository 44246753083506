import React from 'react';
import {Button, Box} from '@mui/material';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {Link} from 'react-router-dom';
import {RiGoogleLine, RiFacebookLine, RiTwitterFill, RiInstagramLine, RiWhatsappLine, RiPinterestFill} from "react-icons/ri";

function FooterBottomRow() {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
      }));

  return (
    <div style={{ backgroundColor:'white', padding:'5px 15px', zIndex:'999999', position:'relative'}}>
        
            <Grid container justifyContent='space-evenly'>
                <Grid item xs={12} md={4}>
                        <p className="termsConditionPara">
                            <Box sx={{textAlign:{xs:'center', md:'left'}}}>
                                <b><Link style={{color:'#2c3e50'}} to="/terms_and_conditions">Terms &amp; Conditions</Link> | <Link to="/privacy_policy" style={{color:'#2c3e50'}}>Privacy Policy</Link></b><br/>
                                <b style={{color:'rgba(0,0,0,.6)'}}>2023 &copy; Memories Maker. All Rights Reserved</b>
                            </Box>
                        </p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{display:{md:'block', xs:'none'}}}>
                        <img src="images/logo/main-logo-live.png" style={{height:'70px', display:'block', margin:'auto'}} alt="" />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{display:{md:'flex', xs:'none'}, justifyContent:'flex-end', alignItems:'center'}}>
                    <div style={{display:'flex', verticalAlign:'center'}}>
                    <Button sx={{fontSize:'20px', color:'white', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://www.facebook.com/memoriesmakertrip" style={{color:'#2c3e50', textDecoration:'none'}}><RiFacebookLine/></a></Button>
                    <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://twitter.com/memoriesmaker_" style={{color:'#2c3e50', textDecoration:'none'}}><RiTwitterFill/></a></Button>
                    <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="mailto:info@memoriesmakeredu.com" style={{color:'#2c3e50', textDecoration:'none'}}><RiGoogleLine/></a></Button>
                    <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://instagram.com/memoriesmaker2016" style={{color:'#2c3e50', textDecoration:'none'}}><RiInstagramLine/></a></Button>
                    <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://web.whatsapp.com/send?phone=+918397026046" style={{color:'#2c3e50', textDecoration:'none'}}><RiWhatsappLine/></a></Button>
                    <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://pin.it/3UEuXgp" style={{color:'#2c3e50', textDecoration:'none'}}><RiPinterestFill/></a></Button>
                    </div>
                </Grid>
            </Grid>
        
    </div>
  )
}

export default FooterBottomRow;