import { React } from "react";
import SectionHeader from "../SectionHeader";
import OwlCarousel from "react-owl-carousel";
import EducationalToursCard from "./EducationalToursCard";
import { Link } from "react-router-dom";

const intEducarouselOptions = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  loop: true,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
      stagePadding: 20,
    },
    425: {
      items: 1,
      stagePadding: 20,
    },
    600: {
      items: 3,
    },
    768: {
      items: 2,
      stagePadding: 80,
    },
    1024: {
      items: 3,
      stagePadding: 60,
    },
    1440: {
      items: 2,
      stagePadding: 10,
    },
    1920: {
      items: 5,
      stagePadding: 50,
    },
  },
};

const domEducarouselOptions = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  loop: true,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 2,
      stagePadding: 20,
    },
    425: {
      items: 2,
      stagePadding: 50,
    },
    600: {
      items: 3,
    },
    768: {
      items: 2,
      stagePadding: 80,
    },
    1024: {
      items: 3,
      stagePadding: 60,
    },
    1440: {
      items: 4,
      stagePadding: 50,
    },
    1920: {
      items: 5,
      stagePadding: 50,
    },
  },
};

function EducationalTours() {
  const intImages = [
    { src: "images/educational-tours/international/ss_1.png", url: "/paris" },
    {
      src: "images/educational-tours/international/ss_2.png",
      url: "/singapore",
    },
    { src: "images/educational-tours/international/ss_3.png", url: "/dubai" },
    { src: "images/educational-tours/international/ss_4.png", url: "/vietnam" },
  ];
  const domImages = [
    // {source:'images/educational-tours/domestic/frenchStudents.jpg', title:'French Std. Special'},
    {
      source: "images/educational-tours/domestic/amritsar.jpg",
      title: "Amritsar",
    },
    {
      source: "images/educational-tours/domestic/Dharamshala.jpg",
      title: "Dharamshala and Mcleod Ganj",
    },
    {
      source: "images/educational-tours/domestic/dehradun.jpg",
      title: "Dehradun-Mussoorie-Rishikesh",
    },
    // {source:'images/educational-tours/domestic/rishikesh.jpg', title:'Rishikesh'},
    {
      source: "images/educational-tours/domestic/jaipur.jpg",
      title: "Jaipur-Jodhpur-Jaisalmer",
    },
    { source: "images/educational-tours/domestic/manali.jpg", title: "Manali" },
    { source: "images/educational-tours/domestic/shimla.jpg", title: "Shimla" },
    { source: "images/educational-tours/domestic/agra.jpg", title: "Agra" },
  ];
  return (
    <>
      <div className="customContainer">
        <div className="eduHeaderBox">
          <div className="eduInnerBox mb-5">
            <span className="boldText">International </span>
            <span className="liteText">&nbsp;Excursion Program</span>
          </div>
        </div>

        <div
          className="row mt-3"
          style={{ background: "rgb(255, 238, 238)" }}
          id="intEducationalSection"
        >
          <OwlCarousel className="owl-theme" {...intEducarouselOptions}>
            {intImages.map((val, i) => (
              <div className="col-lg-6">
                <Link to={val.url}>
                  <img
                    className="eduTourImg"
                    src={val.src}
                    style={{ width: "47vw" }}
                  />
                </Link>
              </div>
            ))}
          </OwlCarousel>
        </div>

        <div
          style={{ marginTop: "40px", marginBottom: "40px" }}
          id="domEducationalSection"
        >
          <SectionHeader firstTxt="DOMESTIC" lastTxt="EXCURSION PROGRAM" />
        </div>
        <OwlCarousel
          className="owl-theme domEducationalSection"
          {...domEducarouselOptions}
        >
          {domImages.map((val, i) => {
            return (
              <EducationalToursCard
                days={true}
                image={val.source}
                title={val.title}
                liveTag={false}
                id={i}
              />
            );
          })}
        </OwlCarousel>
      </div>
    </>
  );
}

export default EducationalTours;
