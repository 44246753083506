import React from "react";
import Container from "@mui/material/Container";
import { Grid, Box } from "@mui/material";
import { MdCall } from "react-icons/md";
import { HiMail, HiLocationMarker } from "react-icons/hi";
import { BsShieldCheck } from "react-icons/bs";
import { ImHome3 } from "react-icons/im";
import { AiOutlineInstagram } from "react-icons/ai";
import {
  RiGoogleLine,
  RiFacebookLine,
  RiTwitterFill,
  RiInstagramLine,
  RiWhatsappLine,
  RiPinterestFill,
} from "react-icons/ri";
import FooterBottomRow from "./FooterBottomRow";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Clocks from "./Clocks";

const fonttheme = createTheme({
  typography: {
    fontSize: "14px",
  },
});

function Footer() {
  return (
    <>
      <div
        className="customContainer footercontainer"
        style={{
          filter: "contrast(1.2)",
          background: "linear-gradient(-45deg, #243B55, #141E30)",
        }}
      >
        <Box
          sx={{
            marginLeft: "-2.5%",
            marginRight: "-2.5%",
            marginBottom: { md: "100px", xs: "0" },
          }}
        >
          <img
            src="https://memoriesmakeredu.com/images/bg/travel-exchange-text.png"
            alt=""
            style={{ width: "100%" }}
          />
        </Box>

        {/* web view */}
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Container maxWidth="xl">
            <Grid container spacing={2} sx={{ marginBottom: "150px" }}>
              <Grid item xs={3}>
                <ul className="footerTabs">
                  <li className="footerTab">
                    <Link to="/">
                      <ImHome3 /> HOME
                    </Link>
                  </li>
                  <li className="footerTab">
                    <a href="https://g.page/r/CTYz_DLFa5H1EAE">
                      <HiLocationMarker /> LOCATION
                    </a>
                  </li>
                  <li className="footerTab">
                    <a href="mailto:contact@memoriesmakeredu.com">
                      <HiMail /> contact@memoriesmakeredu.com
                    </a>
                  </li>
                  <li className="footerTab">
                    <a href="tel:+919991225544">
                      <MdCall /> +91 99912 25544
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={3}>
                <ul className="footerTabs">
                  <li className="footerTab">
                    <Link to="/about">ABOUT US</Link>
                  </li>
                  <li className="footerTab">
                    <a href="/#prioritySection">OUR PRIORITY</a>
                  </li>
                  <li className="footerTab">
                    <a href="/#testimonialSection">FEEDBACK</a>
                  </li>
                  <li className="footerTab">
                    <Link to="/contact">CONTACT US</Link>
                  </li>
                  <li className="footerTab">
                    <Link to="/gallery">GALLERY</Link>
                  </li>
                  <li className="footerTab">
                    <a href="/contact">FAQ</a>
                  </li>
                  <li className="footerTab">
                    <Link to="/founder">MEET OUR FOUNDER</Link>
                  </li>
                  <li className="footerTab">
                    <Link to="/companyProfile">COMPANY PROFILE</Link>
                  </li>
                  <li className="footerTab">
                    <a href="javascript:void(0);">
                      OUR GST NUMBER: 06AAOCM2439G1ZD
                    </a>
                  </li>
                  <li className="footerTab">
                    <a href="javascript:void(0);">OUR PAN NUMBER: AACCG0527D</a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={3}>
                <ul className="footerTabs">
                  <li className="footerTab">
                    <a href="javascript:void(0);">WHAT'S ON BOARD ?</a>
                  </li>
                  <li className="footerTab">
                    <Link to="/lourve">Blog</Link>
                  </li>
                  <li className="footerTab">
                    <Link to="/news">News</Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={3}>
                <ul className="footerTabs">
                  <li className="footerTab">
                    <a href="javascript:void(0);">OUR TOURS</a>
                  </li>
                  <li className="footerTab">
                    <a href="/#liveVirtualSection">
                      LIVE VIRTUAL EDUCATIONAL TOURS
                    </a>
                  </li>
                  {/* <li className='footerTab'><a href="/#educationalSection">ADVENTURE CAMPS</a></li> */}
                  <li className="footerTab">
                    <a href="/#educationalSection">
                      INTERNATIONAL EDUCATIONAL TOURS
                    </a>
                  </li>
                  <li className="footerTab">
                    <a href="/#educationalSection">
                      DOMESTIC EDUCATIONAL TOURS
                    </a>
                  </li>
                  {/* <li className='footerTab'><a href="/#educationalSection">UNIVERSITY TOURS</a></li> */}
                </ul>
              </Grid>
            </Grid>
            <Clocks />
            <hr style={{ marginTop: "20px" }} />
            <Grid>
              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ verticalAlign: "middle", fontSize: "14px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <span style={{ color: "#fff" }}>WE ACCEPT</span>
                      <img
                        src="https://memoriesmakeredu.com/images/icons/payment-icons-sm.png"
                        style={{ marginLeft: "15px", width: "200px" }}
                      />
                      <img
                        src="https://memoriesmakeredu.com/images/icons/upi-payments.png"
                        style={{ width: "55px", marginLeft: "10px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <BsShieldCheck
                        style={{ fontSize: "30px", color: "white" }}
                      />
                      <div style={{ marginLeft: "15px", color: "white" }}>
                        <span>SSL Secure payment</span>
                        <br />
                        <span
                          style={{
                            color: "#bbb",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          Your encryption is protected by 256-bit SSL encryption
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <hr />
          </Container>
        </Box>

        <Box sx={{ display: { md: "none", xs: "block" } }}>
          <div>
            <ul className="footer-address mobFooter-address mb-0">
              <li style={{ width: "100%" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <img
                      src="https://memoriesmakeredu.com/images/logo/white-logo.png"
                      style={{ width: "150px", marginTop: "16px" }}
                      alt="Memories Maker (Logo)"
                    />
                  </div>

                  <div>
                    <div>
                      <span
                        style={{
                          color: "white",
                          fontSize: "9px",
                          fontStyle: "italic",
                          letterSpacing: "2px",
                        }}
                      >
                        Follow Us On
                      </span>
                    </div>
                    <div className="socialIcon">
                      <a
                        className="locationIcon"
                        href="https://instagram.com/memoriesmaker2016"
                      >
                        <strong>
                          <AiOutlineInstagram className="instaIcon" />
                        </strong>
                      </a>
                    </div>

                    <div className="socialIcon">
                      <a
                        className="contactIcon"
                        href="https://www.facebook.com/memoriesmakertrip"
                      >
                        <strong>
                          <RiFacebookLine className="fbIcon" />
                        </strong>
                      </a>
                    </div>

                    <div className="socialIcon">
                      <a
                        className="mailIcon"
                        href="https://twitter.com/memoriesmaker_"
                      >
                        <strong>
                          <RiTwitterFill className="twitIcon" />
                        </strong>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <hr
                style={{
                  backgroundColor: "rgba(255, 255, 255, .1)",
                  margin: "0",
                }}
              />

              <li style={{ width: "100%" }} className="pageTabsBox">
                <Accordion className="pageTabs">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandmoreIcon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <ThemeProvider theme={fonttheme}>
                      <Typography>COMPANY</Typography>
                    </ThemeProvider>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ThemeProvider theme={fonttheme}>
                      <Typography>
                        <ul className="phoneview_footerTabs">
                          <li>
                            <Link
                              to="/about"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              ABOUT US
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/#prioritySection"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              OUR PRIORITY
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              FEEDBACK
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              CONTACT US
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/gallery"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              GALLERY
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              FAQ
                            </Link>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              OUR GST NUMBER: 06AAOCM2439G1ZD
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              OUR PAN NUMBER: AACCG0527D
                            </a>
                          </li>
                        </ul>
                      </Typography>
                    </ThemeProvider>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="pageTabs">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandmoreIcon" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <ThemeProvider theme={fonttheme}>
                      <Typography>WHAT'S ON BOARD</Typography>
                    </ThemeProvider>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ThemeProvider theme={fonttheme}>
                      <Typography>
                        <ul className="phoneview_footerTabs">
                          <li>
                            <a
                              href="javascript:void(0);"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              WHAT'S ON BOARD?
                            </a>
                          </li>
                          <li>
                            <Link
                              to="/lourve"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              BLOG
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/news"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              NEWS
                            </Link>
                          </li>
                        </ul>
                      </Typography>
                    </ThemeProvider>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="pageTabs">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandmoreIcon" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <ThemeProvider theme={fonttheme}>
                      <Typography>OUR TOURS</Typography>
                    </ThemeProvider>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ThemeProvider theme={fonttheme}>
                      <Typography>
                        <ul className="phoneview_footerTabs">
                          <li>
                            <a
                              href="/#liveVirtualSection"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              LIVE VIRTUAL EDUCATIONAL TOURS
                            </a>
                          </li>
                          {/* <li><Link to="/about" style={{color:'white', textDecoration:'none'}}>ADVENTURE CAMPS</Link></li> */}
                          <li>
                            <a
                              href="/#intEducationalSection"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              INTERNATIONAL EDUCATIONAL TOURS
                            </a>
                          </li>
                          <li>
                            <a
                              href="/#educationalSection"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              DOMESTIC EDUCATIONAL TOURS
                            </a>
                          </li>
                          {/* <li><Link to="/about" style={{color:'white', textDecoration:'none'}}>UNIVERSITY TOURS</Link></li> */}
                        </ul>
                      </Typography>
                    </ThemeProvider>
                  </AccordionDetails>
                </Accordion>
              </li>
              <li>
                <Grid xs={12} md={3} className="mobFooterTabs footerTabs">
                  <img
                    src="https://memoriesmakeredu.com/images/icons/payment-icons-sm.png"
                    style={{
                      marginTop: "10px",
                      marginLeft: "auto",
                      display: "block",
                    }}
                  />
                </Grid>
              </li>
            </ul>
          </div>
        </Box>
      </div>

      <FooterBottomRow />
    </>
  );
}

export default Footer;
