import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const ParisBlog1 = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner">
          <div className="headerWrapper">
            <div className="bannerBlogHeader">
              <span>All the way to Paris Live Virtually!</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">20 Oct. 2021</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="images/places/blog-paris.webp"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <p>
                Ever wondered about the possibilities of travelling in 2020?
                Well, nobody did! But what nobody imagined, Memories Maker have
                done it. When most of us were affected by the Global Pandemic in
                2020, there was a team that was continuously working towards
                making 2020 memorable for kids. Memories Maker had come up with
                an idea of organizing a three-day Live tour to Paris. The theme
                to which was PARIS COMES HOME - India's first Live Virtual
                Educational Tour to Paris. The scheduled event on 10th, 11th and
                12th October had left the school students mesmerized with the
                beauty of Paris. Nitin Popli, the Founder added, "We believe
                2020 is tough for everyone but even tougher for kids where they
                missed out on going to school, meeting friends, playing in the
                playground and most of all, their vacations. Be it domestic or
                international! Our kids have suffered the most. We kept the
                struggle and misses in our mind and designed a mind-boggling
                itinerary for our kids to get entertained with a sac full of
                knowledge." The three-day event included Parc Astérix on the
                first day, Sacré-Cœur on the second day, and the Eiffel Tower on
                the last and the final day. The students were able to know about
                Park Asterix, stories about Asterix and Obelix, a glimpse of
                Halloween, Sacré-Cœur church, the recipe of mouth-watering
                macarons and crêpes, history of the river Seine, facts about the
                construction of the Eiffel Tower and much more. To test the
                knowledge of the students, Memories Maker also conducted a
                questionnaire at the end of Day 1 and Day 2. There were three
                lucky draws given at the end of Day 3 to add up to the
                excitement of the students. It happened for the first time in
                India that a company gave SAMSUNG TABLETS in a lucky draw in a
                Live Educational Tour. Last but not least, the students also
                received a participation certificate and score list of the
                questionnaire at the end of the three-day event. It feels right
                to see a change in the culture of education. Memories Maker has
                set a benchmark for everyone in the same domain by making this
                initiative. It is indeed a new way of learning while sitting at
                home. Memories Maker has started an era of Live Virtual
                Educational Tours in the Indian market which will leave an
                imprint on the students’ minds and hearts.
              </p>
              <p>
                <span className="blogWritter">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ParisBlog1;
