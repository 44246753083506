import React from 'react'
import './StdDashboardStyle.css'
import {Container} from '@mui/material';
import StudentPanelHeader from './StudentPanelHeader';
function StudentDashboard() {
  return (
    <>
    <StudentPanelHeader/>
      
    </>
  )
}



export default StudentDashboard