import {React, useState, useEffect} from 'react';
import {TextField, Grid, Box, Button, Container, Backdrop} from '@mui/material';
import axios from 'axios';
import {RiGoogleLine, RiFacebookLine, RiTwitterFill, RiInstagramLine, RiWhatsappLine, RiPinterestFill} from "react-icons/ri";
import {Link} from 'react-router-dom';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import OwlCarousel from 'react-owl-carousel';
import Typography from '@mui/material/Typography';
import VideoComp from './VideoComp';
import {FaChalkboardTeacher, FaSchool, FaUserTie} from 'react-icons/fa';
import {MdPhone, MdMail} from 'react-icons/md';
import { Player } from 'video-react';
import MenuItem from '@mui/material/MenuItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const tours = [
  {
    value: 'paris & brussels',
    label: 'Paris & Brussels',
  },
  {
    value: 'dubai',
    label: 'Dubai',
  }
];


const advertiseCarouselOptions = {
  items:1,
  margin:20,
  loop:true,
  autoplay:true,
  stagePadding:50,
  responsive:{
    425:{
      items:1
    },
    768:{
      items:4
    },
    900:{
      items:1
    }
  }
}

const registrationBannerCarouselOptions = {
  items:1,
  margin:20,
  loop:true,
  stagePadding:40,
  autoplay:true,
  dots:false,
  nav:false,
  responsive:{
    425:{
      items:1
    },
    768:{
      items:3
    }
  }
}



function RegistrationForm() {
  const [valDisplay, setValDisplay] = useState(true);
   const [edutour, setEdutour] = useState('');

const handleChange = (event) => {
    setEdutour(event.target.value);
  };

  setTimeout(() => {
    setValDisplay(false);
  }, 2000);

  const [open, setOpen] = useState(false);
  const [openfailureBox, setOpenfailureBox] = useState(false);
  const [valState, setValState] = useState({
    teacherName:'',
    schoolName:'',
    phoneNumber:'',
    designation:'',
    mailId:''
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const failureBoxhandleOpen = () => setOpenfailureBox(true);
  const failureBoxhandleClose = () => setOpenfailureBox(false);

  const setvalueFun = (e) => {
    let inpName = e.target.name;
    let inpValue = e.target.value;
    setValState((preValue)=>{
      return{
        ...preValue,
        [inpName]:inpValue
      }
      });
    
  }  

  // sending data to backend
  const submitHandlerFunction = (e) =>{
    e.preventDefault();
    axios.post(
      `https://memoriesmakeredu.com/api/index.php`, 
      valState
      )
      .then(response => {
        // alert(response.data);
        if(response.data==='success'){
          setValState({teacherName:'', schoolName:'', phoneNumber:'', designation:'', mailId:''});
          handleOpen(true);
        }else{
          failureBoxhandleOpen(true);
        }
    })
  }


  const animateButton = function(e){
    
  //reset animation
  e.target.classList.remove('animate');
  
  e.target.classList.add('animate');
  setTimeout(function(){
    e.target.classList.remove('animate');
  },700);
};



  return (
    <>
    <Fade in={valDisplay} className="loadingBox">
        <div>
        <img src="images/graphics/loadingplane.gif" alt="image" />
        </div>
    </Fade>
     {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal className="successAlertBox"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className='successRegistrationAlert'>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                <span className='header'>
                  <img src="images/graphics/successpopper.gif" style={{width:'100%', borderTopRightRadius:'10px', borderTopLeftRadius:'10px'}} alt="image"/>
                </span>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <span className="content">We will get back to you soon</span>
                <div><a href="">Download Itinerary</a></div>
            </Typography>
          </Box>
        </Fade>
      </Modal>

      <Modal className="successAlertBox"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openfailureBox}
        onClose={failureBoxhandleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openfailureBox}>
          <Box sx={style} className='failedRegistrationAlert'>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                <span className='header'>
                  
                    <img src="images/graphics/failure-icon.gif" alt="mail not sent" style={{width:'200px'}} />
                  
                </span>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <span className="content">Please Fill all the feilds!!</span>
            </Typography>
          </Box>
        </Fade>
      </Modal>

      <Container>
          <div className='discriptionBox'>
          {/* <h3 style={{ backgroundColor:'#2c3e50', color:'white', borderTopRightRadius:'10px', borderTopLeftRadius:'10px', margin:'-15px -15px 0px -15px', padding:'10px'}}>Students Edu Tour to Paris and Brussels</h3><br/>  */}
          <div style={{marginBottom:'10px'}}>
            
            {/* <div style={{marginLeft:'-30px', marginRight:'-30px', marginTop:'-30px', position:'relative'}} className="bannerGraphics">
              <img src="images/graphics/animated-travel.gif" width="100%" alt="" />
            </div> */}
            <img src="images/logo/main-logo-live.png" alt="logo" style={{display:'block', margin:'auto', width:'200px', marginBottom:'10px'}} />
            <div className="registrationMainHeader" style={{position:'relative'}}>
              <span style={{fontSize:'18px', fontWeight:'500'}}>Students Edu Tour<br/> <span style={{fontSize:'34px', fontWeight:'bold'}}>Paris & Brussels</span></span>
              <img src="images/graphics/4.9 RATING ON GOOGLE.png" alt="image" className="ratingImage" />
            </div>
            <OwlCarousel className='advertiseBannerCarousel' {...registrationBannerCarouselOptions}>
                      <div className="item">
                      <img src="images/gallery/parisPhotos/paris-tour-15.jpg" style={{width:'100%', maxHieght:'200px', borderRadius:'10px'}} />
                      </div>
                      <div className="item">
                      <img src="images/gallery/parisPhotos/paris-tour-04.jpg" style={{width:'100%', maxHieght:'200px', borderRadius:'10px'}} />
                      </div>
                      <div className="item">
                        <img src="images/gallery/parisPhotos/paris-tour-08.jpg" style={{width:'100%', maxHieght:'200px', borderRadius:'10px'}} />
                      </div>
                      <div className="item">
                      <img src="images/gallery/parisPhotos/paris-tour-09.jpg" style={{width:'100%', maxHieght:'200px', borderRadius:'10px'}} />
                      </div>
                      <div className="item">
                      <img src="images/gallery/parisPhotos/paris-tour-16.jpg" style={{width:'100%', maxHieght:'200px', borderRadius:'10px'}} />
                      </div>
                    </OwlCarousel> 
          </div>
              <h3 style={{color:'#fc4349'}}>DISCOVER FRANCE</h3>
              <p style={{textAlign:'justify'}}>
                Memories Maker has been working towards one global mission:<br/><br/><b style={{color:'#666'}}>Opening the World Through Education.</b><br/>We are ready to partner with you for our unmatched worldwide presence, our focus on affordability, and our commitment to providing experiences that teach critical thinking, problem solving, collaboration, and global competence. We are more than just a tour provider and our itineraries are more than just the places to go. Our niche is to create Experiential learning which has always been at the heart of our programs. These programs allow the students to spark connections socially which brings out curiosity, confidence, and self-awareness in them. Register now to go on a roller-coaster ride of travel, exchange, and experience.
              </p>
              <br/>
              <div>
                
                <img src="images/graphics/disneyland-paris-logo.png" alt="disneyland" style={{width:'150px', display:'block', marginLeft:'auto'}} />
              </div>
              
              <br/>
              {/* <Box sx={{display:'flex', justifyContent:{xs:'space-between', md:'flex-start'}}} className='advertiseTags'>
                          <div >Travel</div>
                          <div>Exchange</div>
                          <div>Experience</div>
                        </Box> */}
          </div>
        <div className='registrationBox'>
          
              <Grid container style={{marginBottom:'20px'}}>
                  <Grid item xl={5} xs={12}>
                    
                    <OwlCarousel className='advertiseCarousel' {...advertiseCarouselOptions}>
                      <div className="item">
                          <img src="images/graphics/micky-mouse.jpeg" alt="" />
                      </div>
                      <div className="item">
                          <img src="images/itinerary/paris/arc-de-triomphe-paris.jpg" alt="" />
                      </div>
                      <div className="item">
                          <img src="images/itinerary/paris/concorde-paris.jpg" alt="" />
                      </div>
                      <div className="item">
                          <img src="images/itinerary/paris/eiffil-tower-paris.jpg" alt="" />
                      </div>
                      <div className="item">
                          <img src="images/itinerary/paris/louvre-paris.jpg" alt="" />
                      </div>                      
                    </OwlCarousel> 
                    <Box sx={{fontSize:{xs:'28px', display:'flex', alignItems:'center', justifyContent:'space-between', md:'38px'}, backgroundColor:'#f4f4f4', borderRadius:'10px', overflow:'hidden', padding:'5px', fontWeight:{md:'bold'}}}>
                      <div>
                      <Box sx={{color:'#fc4349', display:{xs:'inline-block', sm:'block'}}}>Register&nbsp;</Box><br/>
                      <Box sx={{color:'#2c3e50', display:{xs:'inline-block', sm:'block'}}}>Your&nbsp;School</Box>
                      </div>
                      {/*<div style={{height:'100px', overflow:'hidden'}}>
                          
                          <img src="images/graphics/animatedplane.gif" width="380px" style={{marginTop:'-90px' ,marginLeft:'-70px'}} alt=""/>
                      </div>*/}
                    </Box><br/>
                    {/* <iframe width="100%" height="340" src="https://www.youtube-nocookie.com/embed/UfEiKK-iX70" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                  {/* <div><iframe src="https://giphy.com/embed/3gYZfzyxhtHdrSHBUm" width="100%" height="100%" style={{position:'absolute'}} frameBorder="0" className="giphy-embed" allowFullScreen></iframe></div><p><a href="https://giphy.com/gifs/Ryanair--travel-airport-ryanair-3gYZfzyxhtHdrSHBUm">via GIPHY</a></p> */}
                  
                  
                  </Grid>
                  <Grid item xl={7} xs={12} sx={{padding:{xs:'0', sm:'15px 15px 15px 15px'}}}>
                    <form onSubmit={submitHandlerFunction} id="registrationForm">
                      <TextField id="teacherName" name="teacherName" value={valState.teacherName} onChange={setvalueFun} label={<><FaChalkboardTeacher className="mailIcon"/> Teacher's Name</>} variant="outlined" style={{width:'100%', marginBottom:'15px'}} />
                      <TextField id="schoolName" value={valState.schoolName} onChange={setvalueFun} name='schoolName' label={<><FaSchool className="mailIcon"/> School Name</>} variant="outlined" style={{width:'100%', marginBottom:'15px'}} />
                      <TextField id="designation" name='designation' value={valState.designation} onChange={setvalueFun} label={<><FaUserTie/> Designation</>} variant="outlined" style={{width:'100%', marginBottom:'15px'}} />
                      <TextField id="phoneNumber" name="phoneNumber" value={valState.phoneNumber} onChange={setvalueFun} label={<><MdPhone className="mailIcon"/> Phone Number</>} variant="outlined" style={{width:'100%', marginBottom:'15px'}} />
                      <TextField id="mailId" name="mailId" value={valState.mailId} onChange={setvalueFun} label={<><MdMail className="mailIcon" /> <span>Email Id</span></>} variant="outlined" style={{width:'100%', marginBottom:'15px'}} />

                      <TextField style={{width:'100%', marginBottom:'20px'}} select label="Select Tour" value={edutour} onChange={handleChange}>
                          {tours.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                      {/*<Button type="submit" style={{backgroundColor:'#2c3e50', color:'white', display:'block', padding:'5px 15px', marginLeft:'auto'}}>Submit</Button>*/}
                      <button type="submit" class="bubbly-button" onClick={animateButton}>Submit</button>
                    </form>
                  </Grid>
              </Grid>
              <hr style={{backgroundColor:'#2c3e50', marginBottom:'10px'}} />
              <Box sx={{display:'flex', justifyContent:{xs:'center', sm:'space-between'}}}>
                <Box>
                   <Box sx={{display:'flex', verticalAlign:'center', justifyContent:'space-between'}}>
                      <Button sx={{fontSize:'20px', color:'white', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://www.facebook.com/memoriesmakertrip" style={{color:'#2c3e50', textDecoration:'none'}}><RiFacebookLine/></a></Button>
                      <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://twitter.com/memoriesmaker_" style={{color:'#2c3e50', textDecoration:'none'}}><RiTwitterFill/></a></Button>
                      <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="mailto:info@memoriesmakeredu.com" style={{color:'#2c3e50', textDecoration:'none'}}><RiGoogleLine/></a></Button>
                      <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://instagram.com/memoriesmaker2016" style={{color:'#2c3e50', textDecoration:'none'}}><RiInstagramLine/></a></Button>
                      <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://web.whatsapp.com/send?phone=+918397026046" style={{color:'#2c3e50', textDecoration:'none'}}><RiWhatsappLine/></a></Button>
                      <Button sx={{fontSize:'20px', minWidth:{md:'44px', xs:'40px'}, padding:'0', borderRadius:'50%', height:{md:'44px', xs:'40px', padding:'0'}}}><a href="https://pin.it/3UEuXgp" style={{color:'#2c3e50', textDecoration:'none'}}><RiPinterestFill/></a></Button>
                    </Box>
                </Box>
                <Box sx={{display:{xs:'none', sm:'block'}}}>
                  <Link to="/"><img src="images/logo/main-logo.png" style={{width:'100px'}} alt="logo" /></Link>
                </Box>
              </Box>
        </div>

        {/* <VideoComp/> */}

        <div className="videoBox mobVideoBox">
           
           <Player
            playsInline
            className="mmVideo"
            muted
            fluid={false}
            loop={true}
            autoPlay={true}
            poster="/images/graphics/france-map.jpg"
            src="images/graphics/mm-entry.MP4"
            />
            

            

            
            <iframe className='mmVideo' height="315" src="https://www.youtube.com/embed/WtNvPv6yBzI" title="Memories Maker in Paris" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

            
            <Player
            playsInline
            muted
            className="mmVideo"
            fluid={false}
            loop={true}
            autoPlay={true}
            poster="/images/graphics/france-map.jpg"
            src="images/graphics/mm-dance.MP4"
            />

            {/*<Player
            playsInline
            className="mmVideo_mobile"
            muted
            fluid={false}
            loop={true}
            autoPlay={true}
            poster="/images/graphics/france-map.jpg"
            src="images/graphics/mm-prayer.MP4"
            />*/}
            
          {/*
          <iframe className='ytAdVideo' height="315" src="images/graphics/mm-prayer.mp4" title="Memories Maker in Paris" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          <iframe className='ytAdVideo' height="315" src="images/graphics/mm-dance.mp4" title="Memories Maker in Paris" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>*/}
        </div>
      </Container>
    </>
  )
}

export default RegistrationForm;