import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Header from './Header';
import BannerSection from './BannerSection';
import Footer from './Footer';
import Container from '@mui/material/Container';
import { useState } from 'react';
import GalleryImgModal from './GalleryImgModal';


export default function MasonryImageList() {

const [clickedImg, setClickedImg] = useState(null);
const [currentIndex, setCurrentIndex] = useState(null);

const handleClick = (item, index) => {
  setCurrentIndex(index);
  setClickedImg(item.img);
};

const handleRotationRight = () => {
  const totalLength = itemData.length;
  if(currentIndex +1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = itemData[0].img;
      setClickedImg(newUrl);
      return;
  }
  const newIndex = currentIndex + 1;
  const newUrl = itemData.filter((item) => {
      return itemData.indexOf(item) === newIndex;
  });
  const newItem = newUrl[0].img;
  setClickedImg(newItem);
  setCurrentIndex(newIndex);

}
  return (
      <>
        <Header/>
        <BannerSection pageHeader="IMAGE GALLERY" />
        <Container maxWidth="xxl" sx={{paddingTop:'10px'}}>
        <Box>
      <ImageList variant="masonry" cols={4} gap={8}>
        {itemData.map((item, index) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              onClick={() => handleClick(item, index)}
              style={{cursor: 'pointer'}}
            />
          </ImageListItem>
        ))}
      </ImageList>
          {clickedImg && (
                <GalleryImgModal 
                clickedImg={clickedImg}
                handleRotationRight={handleRotationRight} 
                setClickedImg={setClickedImg}
                 />
              )}
    </Box>
        </Container>
        <Footer/>
      </>
  );
}

const itemData = [
  {
    img: 'images/gallery/dubai_tour_sep-oct_2022/dubai_tour_1480.webp',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/dubai_tour_sep-oct_2022/dubai_tour_17645482.webp',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/dubai_tour_sep-oct_2022/IMG_1526.webp',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/dubai_tour_sep-oct_2022/dubai_tour_1577.webp',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/dubai_tour_sep-oct_2022/dubai_tour_1764.webp',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/dubai_tour_sep-oct_2022/IMG_5680.webp',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/discover-dubai-tuli/discover_dubai_8401.jpg',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/discover-dubai-tuli/discover_dubai_8618.jpg',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/discover-dubai-tuli/discover_dubai_8637.jpg',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/discover-dubai-tuli/discover_dubai_8643.jpg',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/dubai_tour_sep-oct_2022/dubai_tour_5825.webp',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/discover-dubai-tuli/discover_dubai_8829-(3).jpg',
    title: 'Dubai',
  },
  {
    img: 'images/gallery/discover-dubai-tuli/discover_dubai_9074.jpg',
    title: 'Dubai',
  },
  
  // expo dubai tour
  {
    img: 'images/gallery/re-discoverculture-27112021.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/re-discoverculture-28112021.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/dubai-airport.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/dubai-passport.jpg',
    title: 'KitExpo Dubaichen',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-dubai-tour.png',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-group.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-meal.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-palm-island.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-tour-2.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-tour-3.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-tour-4.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-tour-5.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-tour-6.jpg',
    title: 'Expo Dubai',
  },
  {
    img: 'images/gallery/expo-dubai-tour/expo-tour-with-indian-flag.jpg',
    title: 'Expo Dubai',
  },
  
  // paris photos
  {
    img: 'images/gallery/parisPhotos/paris-tour-01.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-02.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-03.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-04.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-05.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-06.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-07.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-08.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-09.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-11.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-12.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-13.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-14.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-15.jpg',
    title: 'Paris',
  },
  {
    img: 'images/gallery/parisPhotos/paris-tour-16.jpg',
    title: 'Paris',
  },
];
