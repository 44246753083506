import React from 'react';

function GalleryCarouselCard(props) {
    return (
        <div className="item">
            <div className='galleryCarouselBox'>
                <div className='imgBox'>
                    <img src={props.image} style={{cursor:'pointer'}} alt="Educational Tour" />
                </div>
            </div>
        </div>
  )
}


export default GalleryCarouselCard;
