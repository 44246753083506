import React, { useRef } from 'react';
import {Container, Button} from '@mui/material';
import SectionHeader from './SectionHeader';
import Grid from '@mui/material/Grid';
import {GrMap} from 'react-icons/gr';
import {FiPhoneCall} from 'react-icons/fi';
import {BsMailbox} from 'react-icons/bs';
import {AiOutlineFieldTime} from 'react-icons/ai';
import { TextField } from '@mui/material';
import {BsFillPatchQuestionFill} from 'react-icons/bs';
import {IoIosCheckmarkCircleOutline} from 'react-icons/io';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import emailjs from '@emailjs/browser';

function ContactSection() {
  const form = useRef();

  const sendMail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_r7db4aq', 'template_81fdj5k', form.current, 'sTrJFb8cxlqacNk3H')
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
    e.target.reset();
    }
  
  return (
<>
<Container style={{backgroundColor:'white', paddingBottom:'30px'}}>
        <SectionHeader firstTxt="GET IN TOUCH" />
        <Grid container spacing={2} style={{textAlign:'center'}} className="contactInfo">
          <Grid item xl={7} xs={12}>
            <Grid container>
              <Grid item xl={6} xs={12} className="info" style={{borderRight:'1px solid rgba(0,0,0,.05)', borderBottom:'1px solid rgba(0,0,0,.05)'}}>
                  <div>
                  <GrMap className='contactSocial_icon'/>
                  <h4>Address</h4>
                  <span>E-187 Jindal Global City Sonipat Haryana 131001, India</span>
                  </div>
              </Grid>
              <Grid item xl={6} xs={12} className="info" style={{borderBottom:'1px solid rgba(0,0,0,.05)'}}>
                    <div>
                    <FiPhoneCall className='contactSocial_icon'/>
                    <h4>Call Us</h4>
                    <span>+91 99912 25544</span><br/><br/>
                    <h4>For Dubai :</h4>
                    <span>+971 5060 95844</span><br/><br/>
                    <h4>Emergency Number</h4>
                    <span>+91 9518 970000</span>
                    </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xl={6} xs={12} className="info" style={{borderRight:'1px solid rgba(0,0,0,.05)'}}>
                    <div>
                    <BsMailbox className='contactSocial_icon'/>
                    <h4>Mail Us</h4>
                    <span>contact@memoriesmakeredu.com</span><br/>
                    </div>
              </Grid>
              <Grid item xl={6} xs={12} className="info">
                    <div>
                    <AiOutlineFieldTime className='contactSocial_icon'/>
                    <h4>Working Hours</h4>
                    <span>Mon - Fri: 9AM to 5PM</span><br/>
                    <span>Sat - Sun: 9AM to 1PM</span>
                    </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={5} xs={12}>
              <form ref={form} onSubmit={sendMail}>
              <TextField name='name' id="username" label="Your Name" style={{width:'100%', marginBottom:'15px'}} variant="outlined" />
              <TextField name='email' id="usermail" label="Your Email" style={{width:'100%', marginBottom:'15px'}} variant="outlined" />
              <TextField id="usernumber" label="Your Phone Number" style={{width:'100%', marginBottom:'15px'}} variant="outlined" />
              <TextField id="usersubject" label="Subject" style={{width:'100%', marginBottom:'15px'}} variant="outlined" />
              <textarea name="message" id="message" rows="10" placeholder='Your Message' style={{width:'98.5%', display:'block'}}></textarea>
              <Button type='submit'>Submit</Button>
              </form>
          </Grid>
      </Grid>
      
    </Container>
    <Container>
      <Grid className='faq'>
          <SectionHeader firstTxt="Frequently Asked Questions" />
          {/* <ul>
            <li data-aos="fade-up">
            <a data-toggle="collapse" class="collapsed" href="#accordion-list-1"><BsFillPatchQuestionFill className='faqListIcon' style={{display:'inline-block'}} /> Do you plan itinerary and packages for school students only or it is open for public as well?<ion-icon name="chevron-down-outline" class=" icon-show"></ion-icon></a>
              <div id="accordion-list-1" class="collapse" data-parent=".accordion-list">
                <p>
                  Our core-center is to plan trips and packages for students since we are an Edu Travel Company. However, we are open for the public as well. You can go through our itineraries mentioned here (hyperlink of itinerary page). You can also call us on (both numbers) or write to us at (email id) to get your quotation.
                </p>
              </div>
            </li>
          </ul> */}
          <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; Do you plan itinerary and packages for school students only or it is open for public as well?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          Our core-center is to plan trips and packages for students since we are an Edu Travel Company. However, we are open for the public as well. You can go through our itineraries mentioned here (hyperlink of itinerary page). You can also call us on (both numbers) or write to us at (email id) to get your quotation.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; Does It cost money to get a quote?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          No, it generally doesn’t cost anything to get a quote. However, if you need a specific hotel or flight, then we do request an intent-to-travel deposit to begin the process. This deposit is applied to the reservations that are made on your behalf.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; Can you tailor-make the itinerary according to customers’ budget?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          Absolutely yes, we can customize your itinerary as per your needs and interests.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; What safety measures are taken when it comes to students edu trips?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          No, it generally doesn’t cost anything to get a quote. However, if you need a specific hotel or flight, then we do request an intent-to-travel deposit to begin the process. This deposit is applied to the reservations that are made on your behalf.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; Does It cost money to get a quote?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          No, it generally doesn’t cost anything to get a quote. However, if you need a specific hotel or flight, then we do request an intent-to-travel deposit to begin the process. This deposit is applied to the reservations that are made on your behalf.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; What safety measures are taken when it comes to students edu trips?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          At Memories Maker, we take safety of each participant on the tours very seriously. Just as we work rigorously to provide once-in-a lifetime experiences to our students, we are also committed to ensure that our travelers return their home safely. Below are some of the safety rules that we follow while on a trip:
          <ol type="a" className="safteyRules">
            <li><IoIosCheckmarkCircleOutline/>&nbsp; Road Safety</li>
            <li><IoIosCheckmarkCircleOutline/>&nbsp;Water Safety</li>
            <li><IoIosCheckmarkCircleOutline/>&nbsp;Travel’s privacy</li>
            <li><IoIosCheckmarkCircleOutline/>&nbsp;Maintaining low key</li>
            <li><IoIosCheckmarkCircleOutline/>&nbsp;Copies of Important Documents</li>
            <li><IoIosCheckmarkCircleOutline/>&nbsp;Vigilant Communication, among others</li>
          </ol>

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; What Is a minimum number required to organize school trips with you?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          A minimum of 7-10 students are required to organize a school trip.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; If I Come Across Any Problem During My Trip, How Would You Help Me?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          If you come across any difficulty or face any problem, then you can reach out to our emergency contact number. We are available here 24x7 to help you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />} 
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; What services do you offer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          We provide an array of services which include Students Exchange Program, Edu Tours, Live Virtual Educational Tours, Quizzes, Workshops, and Field Trips.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; Do I need medical/travel insurance before travelling to any country?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          Yes. It is advisable that you buy a comprehensive medical / travel insurance in advance before travelling to any country.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; How can I book my Tour with you?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          If you are interested in booking any of our brochure tours, you may send us an email giving us your details.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; How do you settle the payment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          We generally need an advance amount to make necessary arrangements for the tour. Payments can be paid through bank transfer, credit card, cheques, UPI, or Paytm. You can also refer to our payment and refund policies under Terms and Conditions.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; How will I receive my travel documents?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          The travel documents will be emailed on your email id. Please ensure to update correct email id with us while submitting your details.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className='faqAccordian'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          
        >
        <Typography className='faqListHeader'><BsFillPatchQuestionFill className='faqListIcon' />&nbsp; Where do I receive my Airline e-tickets?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='faqListAnswer'>
          E-ticketing is a new way of booking tickets online. You will receive them directly on your email id. Please ensure to update correct email id with us while submitting your details.
          </Typography>
        </AccordionDetails>
      </Accordion>
      </Grid>
    </Container>
</>
  )
}

export default ContactSection