import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const Ahmedabad = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner_ahmedabad">
          <div className="headerWrapper">
            <div className="bannerBlogHeader_ahmedabad">
              <span>Architectural Arena Ahmedabad</span>
            </div>
            <div className="bannerSubHeader_ahmedabad">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">01 Aug. 2023</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/ahmedabad/ahmedabad-main.jpg"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                A journey of learning and discovery
              </h1>
              <p>
                An educational tour is a vital aspect of a student’s academic
                journey, as it not only provides a much needed break from the
                routine but also opens up a world of knowledge and experiences.
                In recent years, educational tours have gained popularity as a
                means to enhance learning experiences outside the classroom. The
                opportunity to step out of the traditional learning environment
                and explore new places can be incredibly enriching for the
                students, fostering a deeper understanding of history, culture,
                architecture, and society. Ahmedabad, the vibrant city in the
                western state of Gujarat, India, offers a unique blend of
                history, culture, and innovation. Embarking on an educational
                tour to Ahmedabad promises to be an enriching experience that
                will leave a lasting impact on students’ minds. Let’s delve into
                the fascinating aspects of this city and explore the educational
                treasures it holds.
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>Historical Heritage:</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/ahmedabad/Historical_Heritage.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Ahmedabad boasts a rich historical legacy, which makes it an
                    ideal destination for history enthusiasts. The city is
                    dotted with awe-inspiring monuments, temples, and mosques
                    that reflect its diverse history and architectural
                    brilliance. The Sabarmati Ashram, once the residence of
                    Mahatma Gandhi, offers a glimpse into the life and teaching
                    of the Father of the Nation. Students can immerse themselves
                    in the philosophies of non-violence and simplicity that
                    shaped India’s struggle for independence. Visits to the
                    Sarkhej Roza, Jama Masjid, and Sidi Saiyyed Mosque will
                    showcase the splendid architecture and cultural amalgamation
                    of the region.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Art and Culture:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    The city’s vibrant culture and artistic heritage are on
                    display at various museums and art galleries. The Calico
                    Museum of Textiles, one of the finest in its category, will
                    introduce students to the exquisite world of Indian
                    textiles. At the Shreyas Folk Museum, they can explore the
                    traditional folk arts and crafts, gaining insights into the
                    diverse cultural traditions that form the fabric of India.
                    The Sanskar Kendra Museum, designed by the renowned
                    architect Le Corbusier, showcases the city’s modern
                    architectural marvels.
                    <br></br>
                    <br></br>
                    For the architecture enthusiasts, Ahmedabad offers a
                    delightful blend of old-world charm and modern design. The
                    city is renowned for its exquisite stepwells like Adalaj and
                    Dada Harir Vav, which showcase the intricate craftsmanship
                    of bygone eras. The students can marvel at the Bhadra Fort,
                    a majestic structure that once served as the royal abode of
                    Ahmedabad’s rulers, and delve into the stories behind its
                    walls.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/ahmedabad/Art_and_Culture.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4 mt-4">
                    <strong>
                      <u>Scientific and Technological Advancements:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/ahmedabad/Scientific_and_Technological_Advancements.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Ahmedabad is not only steeped in history but also a hub of
                    scientific and technological advancements, making it an
                    attractive destination for students pursuing STEM (Science,
                    Technology, Engineering, and Mathematics) education. The
                    Indian Space Research Organisation (ISRO) has its Space
                    Application Centre in the city, where students can witness
                    India’s space exploration efforts up close. Additionally,
                    the Gujarat Science City offers interactive exhibits and
                    workshops that cater to a wider range of scientific
                    interests. The visit inspires budding scientists and
                    instilled a sense of pride in Indian’s technological
                    advancements.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Entrepreneurship and Innovation:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    Ahmedabad is not only a city of cultural and historical
                    significance but also a hub of industrial progress. Students
                    can gain insights into the entrepreneurial spirit of the
                    city by visiting industrial zones and interacting with
                    successful business owners. Learning about the textile
                    industry, for which Ahmedabad is famous, can be an
                    eye-opening experience, highlighting the importance of
                    economic development and trade.
                    <br></br>
                    <br></br>
                    With its thriving entrepreneurial ecosystem, Ahmedabad is an
                    inspiration for aspiring business minds. The city’s renowned
                    educational institutions, including the Indian Institute of
                    Management Ahmedabad (IIMA) and the National Institute of
                    Design (NID), have played a significant role in fostering
                    innovation and creativity. Students can interact with
                    successful entrepreneurs, attend startup events, and
                    visiting co-working spaces to understand the dynamics of
                    entrepreneurship and innovation.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/ahmedabad/Entrepreneurship_and_Innovation.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "90%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4 mt-4">
                    <strong>
                      <u>Culinary Delights:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/ahmedabad/Culinary_Delights.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    No educational tour is complete without savoring the local
                    cuisine. Ahmedabad’s culinary scene is a delight for
                    foodies, offering a diverse range of mouthwatering
                    vegetarian dishes. From the traditional Gujarati thali to
                    delectable street food like dhokla, fafda, and khaman,
                    students can tantalize their taste buds while also learning
                    about the cultural significance of these dishes.
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <img
                    src="/images/places/ahmedabad/kankaria_lake.jpg"
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <br></br>
                  <br></br>
                  <p>
                    Ahmedabad has been a crucial of social change and
                    community-driven initiatives. Educational tours can include
                    visits to various NGOs and social organizations that are
                    working towards uplifting the underprivileged sections of
                    society. Engaging with such initiatives can instill empathy,
                    compassion, and a sense of responsibility among students,
                    encouraging them to contribute positively to society.
                    <br></br>
                    <br></br>
                    An educational tour to Ahmedabad is an immersive journey
                    that amalgamates history, culture, architecture, and social
                    awareness. It offers an opportunity for students to break
                    away from routine and engage in experiential learning,
                    broadening their horizons and igniting their curiosity. The
                    city’s rich heritage, coupled with its modern advancements,
                    creates an ideal learning environment that fosters personal
                    growth and appreciation for diverse cultures. Moreover, the
                    vibrant culture, warm hospitality, and culinary delights
                    will leave lasting memories and create a sense of
                    appreciation for the diverse tapestry that India weaves. An
                    experience in Ahmedabad is sure to inspire young minds and
                    fuel their passion for knowledge and exploration. So, pack
                    your bags and get ready for an unforgettable educational
                    adventure with Memories Maker in Ahmedabad!
                  </p>
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Ahmedabad;
