const GalleryCarouselImgModal = ({clickedImg, handleRotationRight, setClickedImg}) => {
    const handleClick = (e) => {
        if(e.target.classList.contains('dismiss')){
            setClickedImg(null);
        }
    }

    return<>
        <div className="oveRLay dismiss" onClick={handleClick}>
            <img src={clickedImg} alt='bigger picture'/>
        </div>
    </>
};

export default GalleryCarouselImgModal;