import React from "react";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { Button } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

const Dharamshala = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pageBanner">
          <div className="headerWrapper">
            <div className="dharambannerBlogHeader">
              <span>Dharamshala and McLeod Ganj</span>
            </div>
            <div className="bannerSubHeader">
              <span>M</span>
              <span>E</span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>E</span>
              <span>S</span>
              <span></span>
              <span>M</span>
              <span>A</span>
              <span>K</span>
              <span>E</span>
              <span>R</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="blogUploadedDate">01 July 2023</div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <MdContentCopy />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaLinkedinIn />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <FaWhatsapp />
                </Button>
                <Button
                  sx={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    minWidth: "auto",
                  }}
                >
                  <CgFacebook />
                </Button>
              </div>
            </div>
            <img
              src="/images/places/dharamshala/McLeod_Ganj.jpg"
              alt="blogImage"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="blogPara pt-4">
              <h1 style={{ textAlign: "center" }}>
                A Blend of Serenity and Spirituality
              </h1>
              <p>
                Nestled in the majestic Dhauladhar range of the Himalayas,
                Dharamshala and McLeod Ganj are twin gems that captivate the
                hearts of travelers with their breathtaking landscapes, rich
                culture, and spiritual essence. Located in the Kangra district
                of Himachal Pradesh, India, these two destinations offer a
                unique blend of natural beauty, Tibetan heritage, and
                tranquility, making them a must-visit for wanderers seeking
                solace in the lap of nature.
                <br></br>
                <br></br>
                <h3 className="dharamBlogHead">
                  <strong>
                    <u>Discovering Dharamshala:</u>
                  </strong>
                </h3>
                <br></br>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/dharamshala/dharamshala_market.png"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    Dharamshala, the larger of the two towns, is divided into
                    two distinct parts – Upper and Lower Dharamshala. The Lower
                    Dharamshala, known as Kotwali Bazaar, is a bustling
                    commercial area with markets, restaurants, and local
                    attractions. It is the primary transit point for most
                    visitors coming to this region.
                    <br></br>
                    <br></br>
                    However, the real charm lies in Upper Dharamshala, often
                    referred as McLeod Ganj, the residence of his Holiness the
                    14th Dalai Lama and the spiritual hub of Tibetan Buddhism in
                    exile. The picturesque landscapes, the vibrant blend of
                    cultures, and the serenity of its monasteries make McLeod
                    Ganj a soul-stirring experience.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Spirituality and Monastic Life:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    The Tsuglagkhang Complex is the heart of McLeod Ganj and
                    holds a special place in the hearts of Tibetan Buddhists.
                    The Namgyal Monastery, located within the complex, is the
                    personal monastery of the Dalai Lama. Visitors can attend
                    Buddhist teachings and prayers here, immersing themselves in
                    the peaceful ambiance and gaining insights into Tibetan
                    Buddhism.
                    <br></br>
                    <br></br>
                    Another essential religious site is the Bhagsunath Temple,
                    which is dedicated to Lord Shiva. Surrounded by natural
                    beauty, this ancient temple draws both pilgrims and tourists
                    seeking blessings and tranquility.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/dharamshala/gyuto-monastery.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "80%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Trekking and Nature’s Bounty:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/dharamshala/trek.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    For nature enthusiasts and adventure seekers, Dharamshala
                    and McLeod Ganj offer several captivating treks and trails.
                    Triund Trek, a moderate trek that begins from McLeod Ganj,
                    takes you through lush rhododendron and oak forests,
                    rewarding you with panoramic views of the Dhauladhar range
                    from the Triund Hill.
                    <br></br>
                    <br></br>
                    The is another trekking option, taking you through pristine
                    meadows, dense forests, and picturesque villages. The alpine
                    lake at the end of the trail provides a stunning sight and a
                    sense of accomplishment.
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mt-5 mb-4">
                    <strong>
                      <u>Tibetan Culture and Handicrafts:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-8 mb-3">
                    Immersing oneself in the Tibetan culture is an enriching
                    experience in McLeod Ganj. The streets are lined with
                    colorful Tibetan flags, prayer wheels, and stalls selling
                    various handicrafts, paintings, and jewelry crafted by
                    Tibetan refugees.
                    <br></br>
                    <br></br>
                    Visitors can also indulge in mouthwatering Tibetan cuisine,
                    including momos, thukpa, and butter tea, in the numerous
                    cafes and restaurants that dot the town.
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/images/places/dharamshala/Tibetan.jpg"
                      alt="monastery"
                      style={{ width: "100%", height: "80%" }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                  <h3 className="dharamBlogHead mb-4">
                    <strong>
                      <u>Blend of Indo Tibetan Architecture:</u>
                    </strong>
                  </h3>
                  <br></br>
                  <div className="col-md-4 mb-3">
                    <img
                      src="/images/places/dharamshala/indo-tibetan.jpg"
                      alt="dharamshalaImage"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="col-md-8 paraBlog">
                    The architecture of the region is a fusion of Indo-Tibetan
                    Influences. The St. John in the Wilderness church, a
                    neo-gothic structure surrounded by deodar trees, is an
                    architectural marvel worth exploring.
                    <br></br>
                    <br></br>
                    Dharamshala and McLeod Ganj are more than just tourist
                    destinations; they are places that touch the soul. Whether
                    you seek adventure, spirituality, or a quiet escape from the
                    chaos of daily life, these twin towns have something to
                    offer everyone. From majestic mountains to serene
                    monasteries, from vibrant culture to warm hospitality, a
                    trip to Dharamshala and McLeod Ganj is a journey that will
                    leave you with lasting memories and a renewed sense of
                    peace.
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="col-md-12">
                  <h3 className="dharamBlogHead">
                    How does Memories Maker make a difference in the field of
                    Educational Tour?
                  </h3>
                  <br></br>
                  <br></br>
                  <img
                    src="/images/places/dharamshala/student_group.jpg"
                    alt="dharamshalaImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <br></br>
                  <br></br>
                  <p>
                    Our main motive is to enrich education with travel and
                    provide the young generation with the best experience of
                    life.
                    <br></br>
                    <br></br>
                    Our Educational tours are different than traditional tours
                    with a highlight of some common features listed below:
                    <br></br>
                    <br></br>
                    <strong>
                      1 - <u>Curriculum-based learning:</u>
                    </strong>{" "}
                    Our tours have a structured curriculum or learning
                    objectives aligned with educational standards. We aim to
                    provide students with valuable educational experiences that
                    complement classroom learning.
                    <br></br>
                    <br></br>
                    <strong>
                      2 - <u>Expert guides:</u>
                    </strong>{" "}
                    The travelers meet knowledgeable guides or instructors on
                    their journey who specialize in the subject matter of the
                    tour. Our guides provide in-depth explanations, answer
                    questions, and facilitate discussions to enhance the
                    educational experience.
                    <br></br>
                    <br></br>
                    <strong>
                      3 - <u>Immersive Experiences:</u>
                    </strong>{" "}
                    Our Educational tours focus on hands-on experiential
                    learning. They include visits to historical sites, museums,
                    scientific institutions or cultural landmarks, allowing
                    participants to engage directly with the subject matter and
                    gain a deeper understanding.
                    <br></br>
                    <br></br>
                    <strong>
                      4 - <u>Customization and flexibility:</u>
                    </strong>{" "}
                    We offer customization option which allows schools or
                    clients to tailor the itinerary to their specific learning
                    objectives, comfort, budget, and schedule. This flexibility
                    helps to ensure that the tour aligns with the participants’
                    needs and curriculum.
                    <br></br>
                    <br></br>
                    <strong>
                      5 - <u>Safety and supervision:</u>
                    </strong>{" "}
                    Our Educational tours prioritize the safety and well-being
                    of participants. We have safety protocols in place,
                    appropriate supervision ratios, and we ensure a secure and
                    organized experience.
                    <br></br>
                    <br></br>
                    <strong>
                      6 - <u>Mouthwatering delicacies:</u>
                    </strong>{" "}
                    The meals provided on our Educational Tours are full of
                    variety, nutritious, healthy, and interesting. We often
                    offer the local delicacies of different destinations to our
                    travelers to amalgamate with the rich culture of different
                    states or countries.
                    <br></br>
                    <br></br>
                    <strong>
                      7 -{" "}
                      <u>Secure, accessible, and comfortable transportation:</u>
                    </strong>{" "}
                    We provide safe and comfortable transportation in A/C Volvo
                    coaches or Mercedes coaches with well trained and
                    experienced chauffer. We do proper quality check and run
                    throughs before assigning a transport to a group.
                  </p>
                </div>
              </p>
              <p>
                <span className="blogWritter mt-4">
                  ~ <span style={{ color: "#fc4349" }}>Pratiksha Rana</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Dharamshala;
