import React from 'react';
import Header from './Header';
import BannerSection from './BannerSection';
import NewsSection from './NewsSection';
import Footer from './Footer';
import {Grid} from '@mui/material';
import Container from '@mui/material/Container';



const cardValues = [
    {
        newstitle:'Culinary Art',
        date:'November 30, 2021',
        img:'images/news/temp-tour-with-tuli.jpg',
        newscontent:'Executive chef Ganshan explaining culinary art to students of Tuli college of Hotel Management The two day international conference "Taking Indian Culinary Art to Global Destination"'
    },
    {
    newstitle:'Culinary Art',
    date:'April 20, 2022',
    img:'images/news/temp-culinaryArtNews.jpg',
    newscontent:'Executive chef Ganshan explaining culinary art to students of Tuli college of Hotel Management The two day international conference "Taking Indian Culinary Art to Global Destination"'
},
{
    newstitle:'Paris Virtual Tour',
    date:'December 1, 2021',
    img:'images/news/temp-parisVirtualTour.jpg',
    newscontent:'Executive chef Ganshan explaining culinary art to students of Tuli college of Hotel Management The two day international conference "Taking Indian Culinary Art to Global Destination"'
},
{
    newstitle:'Re-Discover Culture',
    date:'November 30, 2021',
    img:'images/news/temp-re-discoverCulture.png',
    newscontent:'Executive chef Ganshan explaining culinary art to students of Tuli college of Hotel Management The two day international conference "Taking Indian Culinary Art to Global Destination"'
},
{
    newstitle:'Educational Tour to Amritsar',
    date:'6 Jan 2023',
    img:'images/news/Amritsar_news.jpeg',
    newscontent:'Executive chef Ganshan explaining culinary art to students of Tuli college of Hotel Management The two day international conference "Taking Indian Culinary Art to Global Destination"'
}
];

function News() {
  return (
    <>



        




        <Header/>
        <BannerSection pageHeader="News" />
        <Container maxWidth='xl' style={{paddingTop:'30px'}}>
        <Grid container>
        {
            cardValues.map((val)=>{
                return <Grid style={{display:'flex', justifyContent:'center'}} item xl={3}><NewsSection newstitle={val.newstitle} date={val.date} img={val.img} newscontent={val.newscontent} /></Grid>
            })
        }
        </Grid>
        </Container>
        <Footer/>
    </>

  )
}

export default News