import {React} from 'react';
import {Link} from 'react-router-dom';


function EducationalToursCard(props) {
    
  return (
      <div className="item" >
                <div className='intEduTourBox'>
                
                    <div className='imgBox'>
                        {
                            (props.liveTag)?<div className="liveTag">Live</div>:null
                        }
                        
                        <img src={props.image} alt="Educational Tour" />
                        <div className='titleBox'>
                            
                                <span style={{maxWidth:'70%'}}>{props.title}</span>
                                {/* {(props.days)?<span className="subTitle"><span style={{backgroundColor:'#fc4349', color:'white', borderRadius:'3px', padding:'0 3px'}}>5D</span> <span style={{backgroundColor:'#2c3e50', color:'white', borderRadius:'3px', padding:'0 3px'}}>4N</span></span>:null} */}
                            
                        </div>
                    </div>
                                   
                </div>
            </div>
  )
}



export default EducationalToursCard;
