import React from "react";
import Header from "../../../Header";
import BannerSection from "../../../BannerSection";
import Footer from "../../../Footer";

const Singapore = () => {
  return (
    <>
      <Header />
      <BannerSection pageHeader="Our Singapore Tour" />

      <div className="container">
        <h1
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontFamily: "-moz-initial",
          }}
        >
          Singapore, Malaysia
        </h1>
        <div className="row">
          <div className="col-md-9">
            <div className="container main">
              <div className="col-md-12 ms-5 mainImg">
                <div className="row">
                  <img
                    src="images/educational-tours/international/Singapore_tour/IMG_5680.webp"
                    alt="Paris Tour"
                    style={{ width: "100%" }}
                  />
                  <h3 className="mt-3" style={{ textAlign: "center" }}>
                    Educational Tour to Singapore
                  </h3>
                  <p className="mt-3">
                    Embark on a transformative journey with our Educational Tour
                    to Singapore, where rich history, vibrant culture, and
                    cutting-edge technology converge. This meticulously crafted
                    tour offers students a unique blend of academic enrichment,
                    cultural exploration, and real-world learning experiences.
                    <br></br>
                    <br></br>
                    <b>Tour Highlights:</b>
                    <br></br>
                    <br></br>
                    <b>1. Historical Exploration - </b>
                    Visit to the National Museum of Singapore. Guided tour
                    through the Colonial District, exploring iconic landmarks
                    like Raffles Hotel and the Singapore River.
                    <br></br>
                    <br></br>
                    <b>2. Academic Insight - </b>
                    Engaging seminars on Singapore's economic development and
                    global significance. Guest lectures from local experts in
                    technology and innovation.
                    <br></br>
                    <br></br>
                    <b>3. Cultural Immersion - </b>A trip to Chinatown, Little
                    India, and Kampong Glam for a multicultural experience.
                    Traditional dance and music performances showcasing
                    Singapore's diverse heritage.
                    <br></br>
                    <br></br>
                    <b>4. Science and Technology Day - </b>
                    Visit to the Singapore Science Centre for interactive
                    exhibits and workshops. Exploration of the city's
                    technological innovations at the Tech Hub.
                    <br></br>
                    <br></br>
                    <b>5. Nature and Sustainability - </b>
                    Tour of Gardens by the Bay and the Cloud Forest. Discussion
                    on Singapore's commitment to sustainability and
                    environmental conservation. Industry visits and field trips
                    to leading industries, providing insights into Singapore's
                    business landscape. Networking opportunities with
                    professionals in various fields.
                    <br></br>
                    <br></br>
                    <b>6. Reflection and Farewell - </b>
                    Group reflection session on the educational and cultural
                    experiences. Farewell dinner to celebrate newfound knowledge
                    and friendships.
                    <br></br>
                    <br></br>
                    <b>Inclusions:</b>
                    <br></br>
                    <br></br>1 - Accommodation in centrally located star/ brand
                    hotels.
                    <br></br>2 - Daily breakfast and all meals.
                    <br></br>3 - Private transportation for all scheduled
                    activities.
                    <br></br>4 - Knowledgeable tour guides and facilitators.
                    <br></br>5 - Water bottles during travel.
                    <br></br>
                    <br></br>
                    <b>Why Choose Our Educational Tour ?</b>
                    <br></br>
                    <br></br>
                    <b>1. Educational Excellence:</b> Tailored seminars and
                    workshops to enhance academic learning.
                    <br></br>
                    <br></br>
                    <b>2. Cultural Immersion:</b> Dive deep into Singapore's
                    rich cultural tapestry.
                    <br></br>
                    <br></br>
                    <b>3. Real-world Experiences:</b> Industry visits and
                    interactions with professionals.
                    <br></br>
                    <br></br>
                    <b>4. Safety and Comfort:</b> Expertly curated itinerary and
                    24/7 tour director land support.
                    <br></br>
                    <br></br>
                    <b>Join Us in Unlocking Knowledge and Experiences!</b>
                    <br></br>
                    <br></br>
                    Don't miss the chance to broaden your horizons and gain
                    valuable insights on this unforgettable Educational Tour to
                    Singapore.
                    <br></br>
                  </p>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="container">
              <div className="sidebarItem">
                <div className="sideImg">
                  <img src="images/educational-tours/international/Singapore_tour/IMG-20190619-WA0227.jpg" />
                </div>
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Where </span>
                    <span className="liteText">&nbsp;Was Our Trip</span>
                  </div>
                </div>
                <img
                  src="images/educational-tours/international/Singapore_tour/singapore_malaysia.png"
                  style={{ width: "60%", marginLeft: "75px" }}
                />
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    fontFamily: "monospace",
                  }}
                >
                  Singapore, Malaysia
                </p>
              </div>
              <div className="sidebarItem">
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Instagram</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzvyX26S89u/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic1.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzuzrFJSKuU/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic2.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/CzyP57kSsVq/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic3.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="https://www.instagram.com/reel/Czuw0EkyN4-/"
                      target="blank"
                    >
                      <img
                        src="images/recent_tour/insta_pic4.png"
                        style={{ width: "100%", height: "90%" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="sideHeaderBox">
                  <div className="sideInnerBox">
                    <span className="boldText">Experience</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Singapore_tour/IMG-20190615-WA0509.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Singapore_tour/IMG-20190615-WA0378.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Singapore_tour/IMG-20190615-WA0323.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Singapore_tour/IMG-20190615-WA0524.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img
                      src="images/educational-tours/international/Singapore_tour/IMG-20190615-WA0405.jpg"
                      style={{ width: "100%", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Singapore;
