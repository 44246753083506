import React from 'react';
import Header from './Header';
import ItinerarySection from './ItinerarySection'
import BannerSection from './BannerSection';
import Footer from './Footer';

function ItineraryParis() {
  return (
    <>
        <Header/>
        <BannerSection pageHeader="ITINERARY"/>
        <ItinerarySection tour="PARIS"/>
        <Footer/>
    </>    
  )
}

export default ItineraryParis;